import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { UserInfo } from '@/types/user';
import { pictureImgDomain } from '@/utils/tool';
interface BaseState {
    isLogin: boolean;
    token: string;
    userInfo: UserInfo;
    showLoginPopup: boolean;
    userAvatar: string;
}

const initialState: BaseState = {
    isLogin: !!localStorage.getItem('access'),
    token: localStorage.getItem('access') || '',
    userInfo: localStorage.getItem('curUserInfo')
        ? JSON.parse(localStorage.getItem('curUserInfo') as string)
        : {
              balance: 0,
              currency: '',
              groupId: '',
              groupName: '',
              headPortrait: '',
              id: '',
              memberAccount: '',
              memberType: 0,
              needModifyAcct: false,
              phoneNumber: '',
              proxyMode: '',
              realName: '',
              siteCode: '',
              token: '',
              unActiveBalance: 0,
              vipIconStyle: 0,
              vipLevel: 0,
              lastRechargeTimeLocal: '',
              todayGolden: 0
          },
    showLoginPopup: false,
    userAvatar:
        localStorage.getItem('userAvatar') || `${pictureImgDomain}web/orange/avatar/default.png`
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoginStatus: (state, action) => {
            state.token = action.payload;
            state.isLogin = true;
            localStorage.setItem('access', action.payload);
        },
        setLogout: state => {
            state.token = '';
            state.isLogin = false;
            localStorage.removeItem('access');
            localStorage.removeItem('curUserInfo');
        },
        setUserInfo: (state, action) => {
            localStorage.setItem('curUserInfo', JSON.stringify(action.payload));
            state.userInfo = action.payload;
        },
        setLoginPopupDisplay: (state, action: PayloadAction<boolean>) => {
            state.showLoginPopup = action.payload;
        },
        setUserAvatar: (state, action) => {
            localStorage.setItem('userAvatar', action.payload);
            state.userAvatar = action.payload;
        }
    }
});

export const { setLoginStatus, setLogout, setUserInfo, setLoginPopupDisplay, setUserAvatar } =
    userSlice.actions;

export default userSlice.reducer;
