import style from '../style/accountWeb.module.css';
import Button from '@mui/material/Button';
import Refresh from '../img/refresh.svg';
import DepositIcon from '../img/deposit1.svg';
import { useState } from 'react';
import { RootState } from '@/app/store';
import { useSelector, useDispatch } from 'react-redux';
import { setBasePopup, setLoginDialogType } from '@/app/slice/baseSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRefreshBalanceMutation } from '@/app/services/user';
import { useGetCollectionAmountMutation } from '@/app/services/game-center';
import { setUserInfo } from '@/app/slice/userSlice';
import { setMessage } from '@/app/slice/baseSlice';
import { dateAreaGet } from '@/utils/timeFormat';
function AccountWeb() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isRotate, setIsRotate] = useState(false);
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const [mutateAsync] = useRefreshBalanceMutation();
    const [getCollectionAmount] = useGetCollectionAmountMutation();
    const baseTitle = useSelector((state: RootState) => state.base.siteInfo.configs.baseTitle);
    const setRefresh = async () => {
        if (isRotate) return;
        setIsRotate(true);
        const result = await getCollectionAmount({});
        if ('data' in result) {
            const balanceResult = await mutateAsync({});
            if ('data' in balanceResult) {
                setIsRotate(false);
                const params = {
                    ...userInfo,
                    balance: balanceResult.data.balance
                };
                dispatch(setUserInfo(params));
                dispatch(setMessage({ type: 'success', message: t('personal_refreshBalance') }));
            } else {
                setIsRotate(false);
            }
        } else {
            setIsRotate(false);
        }
    };

    const openLogin = () => {
        dispatch(setBasePopup('login'));
    };

    const openSignup = () => {
        dispatch(setBasePopup('login'));
        dispatch(setLoginDialogType('register'));
    };

    return (
        <div className={style.account}>
            <div className={style.accountWrapper}>
                {isLogin ? (
                    <>
                        <div className={style.name}>{userInfo.memberAccount}</div>
                        <div className={style.addTime}>
                            {t('personal_addIn')}{' '}
                            <span>{dateAreaGet(userInfo.registerTimeLocal)}</span>
                        </div>
                        <div className={style.wallet}>
                            <div className={style.myWallet}>
                                {t('recordModal_wallet')}
                                <img
                                    onClick={setRefresh}
                                    className={`${isRotate ? style.rotate : ''}`}
                                    src={Refresh}
                                    alt="refresh"
                                />
                            </div>
                            <div className={style.amount}>
                                <span className={style.bigAmount}>{userInfo.balance}</span>
                                <span className={style.unit}>{userInfo.currency}</span>
                            </div>
                            {/* <div className={style.aboutAmount}>
                                <img src={About} alt="about" />
                                <span>{userInfo.balance} CNY</span>
                            </div> */}
                        </div>
                        <div className={style.list}>
                            <Button
                                variant="contained"
                                className={style.btn}
                                onClick={() => navigate('/deposit')}
                            >
                                <img src={DepositIcon} alt="deposit" />
                                <span>{t('personal_deposit')}</span>
                            </Button>
                            <Button
                                variant="contained"
                                className={style.btnWithDraw}
                                onClick={() => navigate('/withdraw')}
                            >
                                <img src={DepositIcon} className={style.DepositIcon} />
                                <span>{t('personal_withDraw')}</span>
                            </Button>
                        </div>
                        {/* <ul className={style.list}>
                            <li onClick={() => navigate('/deposit')}>
                                <img src={Deposit} alt="deposit" />
                                <span>{t('personal_deposit')}</span>
                            </li>
                            <li onClick={() => navigate('/withdraw')}>
                                <img src={Withdraw} alt="withdraw" />
                                <span>{t('personal_withDraw')}</span>
                            </li>
                        </ul> */}
                    </>
                ) : (
                    <>
                        <div className={style.title}>
                            <div>Hello</div>
                            <div>welcome to {baseTitle}</div>
                        </div>
                        <div className={style.subTitle}>
                            After you log in, you&apos;ll get more suggestions and recommendations
                            for the game
                        </div>
                        <div className={style.button}>
                            <div onClick={openLogin} className={style.loginButton}>
                                Log In
                            </div>
                            <div onClick={openSignup} className={style.registerButton}>
                                Register
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default AccountWeb;
