import ErrorImage from './img/404.svg';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import style from './errorPage.module.css';
import { useTranslation } from 'react-i18next';

function ErrorPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <>
            <div className={style.errorPage}>
                <div className={style.error}>
                    <img src={ErrorImage} />
                    <Button variant="contained" className={style.btn} onClick={() => navigate('/')}>
                        {t('global_goHome')}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default ErrorPage;
