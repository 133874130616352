/* eslint @typescript-eslint/no-explicit-any: 0 */
import { Tabs, Tab, ThemeProvider, createTheme } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import style from './baseTabs.module.css';
import selectedImage from '@/assets/img/tabs_selected.svg';

type Options = {
    name: number | string;
    value: '' | any;
};

type Props = {
    className?: string;
    value: number | string | boolean;
    options: Options[];
    onChange: (value: any) => void;
};

const theme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundImage: `url(${selectedImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '32px auto',
                    height: '4px',
                    backgroundColor: 'transparent',
                    bottom: '6px'
                }
            }
        }
    }
});

function BaseTabs({ value, options, onChange, className }: Props) {
    const tabRefs = useRef<(HTMLElement | null)[]>([]); // 使用HTMLElement以兼容多种元素类型
    useEffect(() => {
        const currentTabElement = tabRefs.current.find(
            (tab, index) => tab && options[index].value === value
        );
        if (currentTabElement) {
            currentTabElement.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    }, [value, options]);
    return (
        <ThemeProvider theme={theme}>
            <Tabs
                className={className}
                value={value}
                onChange={(e, value) => onChange(value)}
                aria-label="simple tabs example"
            >
                {options.length > 0 &&
                    options.map((option, index) => (
                        <Tab
                            disableRipple
                            key={option.value}
                            label={option.name}
                            value={option.value}
                            className={style.tabBtn}
                            ref={el => (tabRefs.current[index] = el)}
                        />
                    ))}
            </Tabs>
        </ThemeProvider>
    );
}

export default BaseTabs;
