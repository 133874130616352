import style from '../style/login.module.css';

import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function ShowPasswordButton({
    isShow,
    change,
    top = -2
}: {
    isShow: boolean;
    change: () => void;
    top?: number;
}) {
    return (
        <>
            <div className={style.showPwdBtn} onClick={() => change()} style={{ top: top }}>
                <IconButton aria-label="delete" size="small">
                    {isShow ? (
                        <VisibilityOffIcon fontSize="inherit" />
                    ) : (
                        <VisibilityIcon fontSize="inherit" />
                    )}
                </IconButton>
            </div>
        </>
    );
}

export default ShowPasswordButton;
