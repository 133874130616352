import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const clientUtcOffset = new Date().getTimezoneOffset() * -1;

export function serverTimeToClientTime(serverDateTime: string): string {
    const serverTime = dayjs(serverDateTime).utcOffset(0);
    const clientTime = serverTime.utcOffset(clientUtcOffset);
    return clientTime.format('YYYY-MM-DD HH:mm:ss');
}

export function clientTimeToServerTime(clientDateTime: string): string {
    const clientTime = dayjs(clientDateTime).utcOffset(clientUtcOffset);
    const serverTime = clientTime.utcOffset(480);
    return serverTime.format('YYYY-MM-DD HH:mm:ss');
}

// 返回北京时间 时区转北京时间
export const dateAreaShow = (time: string) => {
    const timezone = localStorage.getItem('timeZone');
    const timerHours = Number(timezone) < 0 ? -(Number(timezone) - 8) : 8 - Number(timezone);
    const targetTime = dayjs(time).add(timerHours, 'hours').format('YYYY-MM-DD HH:mm:ss');
    return targetTime;
};

// 返回时区时间 北京时间转时区
export const dateAreaGet = (time: string, isDate?: boolean) => {
    const curTimeZong = localStorage.getItem('timeZone');
    if (curTimeZong !== null) {
        const zone = Number(curTimeZong) < 0 ? -(Number(curTimeZong) - 8) : 8 - Number(curTimeZong);
        const targetTime = dayjs(time).subtract(zone, 'hours').format('YYYY-MM-DD HH:mm:ss');
        return isDate ? dayjs(targetTime).format('YYYY-MM-DD') : targetTime;
    }
};

export function handleDateRange(id: number) {
    // 假设 Intl.DateTimeFormat() 获取到当前时区（如 Asia/Tokyo）
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log('当前时区:', currentTimeZone);
    // 获取当前时区的时间
    const currentLocalTime = dayjs().tz(currentTimeZone);
    // 目标时区
    const curTimeZong = Number(localStorage.getItem('timeZone')) || 8;
    // 目标时区开始时间
    let startDate = currentLocalTime.utcOffset(curTimeZong).format('YYYY-MM-DD 00:00:00');
    const endDate = currentLocalTime.utcOffset(curTimeZong).format('YYYY-MM-DD 23:59:59');

    const formattedEndDate = dayjs(endDate, 'YYYY-MM-DD HH:mm:ss');

    switch (id) {
        case 0:
            break;
        case 1:
            startDate = formattedEndDate.subtract(7, 'day').format('YYYY-MM-DD 00:00:00');
            break;
        case 2:
            startDate = formattedEndDate.subtract(15, 'day').format('YYYY-MM-DD 00:00:00');
            break;
        case 3:
            startDate = formattedEndDate.subtract(30, 'day').format('YYYY-MM-DD 00:00:00');
            break;
    }
    const timeRange = {
        startTime: dateAreaShow(startDate), // 巴西时间转换为北京时间
        endTime: dateAreaShow(endDate) // 巴西时间转换为北京时间
    };

    return timeRange;
}
