import { useState } from 'react';

export function usePressState() {
    const [isPressed, setIsPressed] = useState<Record<number | string, boolean>>({}); // 添加类型声明

    const handleMouseDown = (index: number | string) => {
        // 添加类型声明
        setIsPressed(prev => ({ ...prev, [index]: true }));
    };

    const handleMouseUp = (index: number | string) => {
        // 添加类型声明
        setIsPressed(prev => ({ ...prev, [index]: false }));
    };

    return [isPressed, handleMouseDown, handleMouseUp] as const; // 用as const 保持元组类型，而不是转化为数组
}
