import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/store';

import Banner from './components/Banner';
import Games from './components/Games';
import style from './style/home.module.css';
import marqueeAvatar from './img/marqueeAvatar.png';
import marqueeBg from './img/marqueeBg.svg';
import marqueeMobileBg from './img/marqueeMobileBg.svg';
import AccountWeb from './components/AccountWeb';
import useMediaQuery from '@mui/material/useMediaQuery';
// import emitter from '@/utils/events';
import ScrollText from './components/ScrollText';
import { useNavigate } from 'react-router-dom';
import {
    setBasePopup,
    setBannerList,
    setMarqueeList,
    setPopList,
    setSideList,
    setPopShowRouletteBooster,
    setGlobalActivityList,
    setPopShowHomePop
} from '@/app/slice/baseSlice';
import { useGlobalActivityListMutation } from '@/app/services/activity';
import { useSiteNoticeListMutation } from '@/app/services/site';
import { MarqueeItem, BannerItem, FloatItem, PopItem } from '@/pages/home/types';
import { popShowInstallAppFn } from '@/utils/tool';

function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:750px)');
    const [siteNoticeListMutation] = useSiteNoticeListMutation();
    const [activityListMutateAsync] = useGlobalActivityListMutation();
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const marqueeList = useSelector((state: RootState) => state.base.marqueeList);
    const defaultCurrency = useSelector((state: RootState) => state.base.siteInfo.defaultCurrency);
    const _onClick = () => {
        if (!isLogin) return dispatch(setBasePopup('login'));
        navigate('/siteNotice', { state: { list: marqueeList } });
    };

    const getSiteNoticeList = async () => {
        try {
            const result = await siteNoticeListMutation({});
            if ('data' in result) {
                const marquee = result.data.find(
                    (item: MarqueeItem) => item.noticeType === 1
                )?.noticeList;
                const popList = result.data.find(
                    (item: PopItem) => item.noticeType === 2
                )?.noticeList;
                const banner = result.data.find(
                    (item: BannerItem) => item.noticeType === 3
                )?.noticeList;
                const floatList = result.data.find(
                    (item: FloatItem) => item.noticeType === 4
                )?.noticeList;
                if (marquee) dispatch(setMarqueeList(marquee));
                if (banner) dispatch(setBannerList(banner));
                if (floatList) dispatch(setSideList(floatList));
                if (popList) dispatch(setPopList(popList));
                return result.data.some((o: PopItem) => o.noticeType === 2);
            } else {
                console.log(result.error);
                return false;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getGlobalActivityList = async (currentLogin: boolean) => {
        const params = currentLogin ? {} : { currency: defaultCurrency };
        try {
            const result = await activityListMutateAsync(params);
            if ('data' in result) {
                dispatch(setGlobalActivityList(result.data));
                return result.data;
            } else {
                console.log(result.error);
                return false;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchActivityPopData = async () => {
        try {
            // 用 Promise.all 并行执行两个异步操作
            const [siteNoticeResult, globalActivityResult] = await Promise.all([
                getSiteNoticeList(),
                getGlobalActivityList(isLogin)
            ]);

            const now = new Date().getTime();
            const expirationTime = 24 * 60 * 60 * 1000; // 24小时
            // const expirationTime = 20 * 1000;

            // 助力轮盘是否显示
            const localRouletteBooster = JSON.parse(
                localStorage.getItem('localRouletteBooster') || '{}'
            );
            // 弹窗公告是否显示
            const localPopShowHomePop = JSON.parse(
                localStorage.getItem('localPopShowHomePop') || '{}'
            );
            // 助力轮盘过期时间
            const rouletteBoosterExpirationTime =
                !localRouletteBooster.value ||
                now - localRouletteBooster.timestamp > expirationTime;
            // 弹窗公告过期时间
            const popShowHomePopExpirationTime =
                !localPopShowHomePop.value || now - localPopShowHomePop.timestamp > expirationTime;

            // 首先判断后台有没有配置轮盘助力活动
            let rouletteBooster;
            if (globalActivityResult) {
                // @ts-ignore
                const result = globalActivityResult as { exclusiveActivityType: number }[];
                rouletteBooster = result.some(x => x.exclusiveActivityType == 7);
            }

            // 配置了轮盘助力活动 并且 没显示过
            if (rouletteBooster && rouletteBoosterExpirationTime) {
                dispatch(setPopShowRouletteBooster(true));
            }

            // 没有配置轮盘助力活动 并且 配置了 弹窗公告活动 并且 没显示过
            if (!rouletteBooster && siteNoticeResult && popShowHomePopExpirationTime) {
                dispatch(setPopShowHomePop(true));
            }

            // 没有配置轮盘助力活动 也没有配置弹窗公告活动 显示安装app 弹框
            const noRouletteBooster = !rouletteBooster;
            const haveRouletteBooster =
                localRouletteBooster.value && !rouletteBoosterExpirationTime;
            if (
                (noRouletteBooster || haveRouletteBooster) &&
                localPopShowHomePop.value &&
                !popShowHomePopExpirationTime
            ) {
                popShowInstallAppFn();
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchActivityPopData();
    }, [isLogin]);

    return (
        <div className={style.home}>
            <Helmet>
                <title>PG</title>
            </Helmet>
            <div className={style.marquee} onClick={_onClick}>
                <img src={marqueeAvatar} className={style.marqueeAvatar} />
                <div className={style.marqueeItem}>
                    <ScrollText
                        key={JSON.stringify(marqueeList)}
                        list={
                            matches
                                ? marqueeList.map(x => x.title)
                                : marqueeList.map(x => x.content)
                        }
                    />
                    <img src={matches ? marqueeMobileBg : marqueeBg} className={style.marqueeBg} />
                </div>
            </div>
            <div className={style.bannerLayout}>
                <Banner />
                {!matches && <AccountWeb />}
            </div>
            <Games />
        </div>
    );
}

export default Home;
