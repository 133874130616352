import api from '../../app/api';
import API_PATH from '../../app/services/apiPathConfig';

const AccountSecurityApi = api.injectEndpoints({
    endpoints: builder => ({
        userBindInfo: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/queryInfo`,
                method: 'POST',
                body: data
            })
        }),
        updateWithdrawPassword: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/modifyWithdrawPassword`,
                method: 'POST',
                body: data
            })
        }),
        setWithdrawPassword: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/setWithdrawPassword`,
                method: 'POST',
                body: data
            })
        }),
        updateLoginPassword: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/modifyLoginPassword`,
                method: 'POST',
                body: data
            })
        }),
        updateUserInfo: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/update`,
                method: 'POST',
                body: data
            })
        }),
        loginInfoRecent: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/queryLoginInfoRecent`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const {
    useUserBindInfoMutation,
    useSetWithdrawPasswordMutation,
    useUpdateWithdrawPasswordMutation,
    useUpdateLoginPasswordMutation,
    useUpdateUserInfoMutation,
    useLoginInfoRecentMutation
} = AccountSecurityApi;

export default AccountSecurityApi;
