import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useState, useEffect, useCallback } from 'react';
import { RechargeDiscountActivityProps, rechargeDiscountInitData } from '@/types/globalActivity';

// -1 vip返现 0转盘 1签到 2红包雨 3邀请好友赚钱 4登录送彩金 5优惠码活动 6首充优惠 7轮盘助力
export const useGlobalActiveList = () => {
    const globalActivityList = useSelector((state: RootState) => state.base.globalActivityList);
    const [rechargeDiscountActivity, setRechargeDiscountActivity] =
        useState<RechargeDiscountActivityProps>(rechargeDiscountInitData);
    const [rechargeDiscountActivityTime, setRechargeDiscountActivityTime] = useState(0);
    const [showDiscountTime, setShowDiscountTime] = useState({
        hours: '00',
        minutes: '00',
        second: '00',
        rechargeCardAmount: 0,
        calcType: 0
    });

    const getShowDiscountTime = useCallback(
        (seconds: number, rechargeCardAmount: number, calcType: number) => {
            const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
            const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
            const second = String(seconds % 60).padStart(2, '0');

            setShowDiscountTime({
                hours,
                minutes,
                second,
                rechargeCardAmount,
                calcType
            });
        },
        []
    );

    useEffect(() => {
        const discountActivity = globalActivityList?.find(
            o => (o as RechargeDiscountActivityProps).exclusiveActivityType === 6
        ) as RechargeDiscountActivityProps | undefined;

        if (discountActivity) {
            setRechargeDiscountActivity(discountActivity);
            if (discountActivity.overtime > 0) {
                setRechargeDiscountActivityTime(discountActivity.overtime);
                getShowDiscountTime(
                    discountActivity.overtime,
                    discountActivity.rechargeCardAmount,
                    discountActivity.calcType
                );

                const intervalId = setInterval(() => {
                    setRechargeDiscountActivityTime(time => {
                        const newTime = time - 1;
                        getShowDiscountTime(
                            newTime,
                            discountActivity.rechargeCardAmount,
                            discountActivity.calcType
                        );
                        return newTime;
                    });
                }, 1000);

                return () => clearInterval(intervalId);
            } else {
                setRechargeDiscountActivityTime(0);
            }
        } else {
            setRechargeDiscountActivityTime(0);
        }
    }, [globalActivityList, getShowDiscountTime]);

    return { rechargeDiscountActivity, rechargeDiscountActivityTime, showDiscountTime };
};
