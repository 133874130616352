import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/store';
import { useNavigate } from 'react-router-dom';
import style from './footer.module.css';
import Bvi from './img/bvi.png';
import Mga from './img/mga.png';
import Mibos from './img/mibos.png';
import Pagcor from './img/pagcor.png';
import Ukgc from './img/ukgc.png';
import Evo from './img/evo.png';
import Yb from './img/yb.png';
import Telegram from './img/telegram.svg';
import Mail from './img/mail.svg';
import Skype from './img/skype.svg';
import TPay from './img/TPay.svg';
import PixPay from './img/pixPay.svg';
import ban18 from './img/18_ban.svg';
import { useFooterLinkMutation } from '@/app/services/site';
import { useTranslation } from 'react-i18next';
import { setAboutUs } from '@/app/slice/baseSlice';
const sponsorList = [
    {
        img: Mibos,
        title: 'footer_mibos'
    },
    {
        img: Mga,
        title: 'footer_mga'
    },
    {
        img: Bvi,
        title: 'footer_bvi'
    },
    {
        img: Ukgc,
        title: 'footer_ukgc'
    },
    {
        img: Pagcor,
        title: 'footer_pagcor'
    },
    {
        img: Evo,
        title: 'footer_pagcor'
    },
    {
        img: Yb,
        title: 'footer_pagcor'
    },
    {
        img: ban18,
        title: 'footer_pagcor'
    }
];

interface footerLinkObj {
    content: string;
    icon: string;
    pageCode: string;
    title: string;
}

interface DataProps {
    name: string;
    pageType: number;
    list: footerLinkObj[];
}

interface ListType {
    content: string;
    icon: string;
    pageCode: string;
    title: string;
    detail: {
        content: string;
        title: string;
    }[];
    path: '';
}

function Footer() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState<DataProps[]>([]);
    const [mutateAsync] = useFooterLinkMutation();
    const baseTitle = useSelector((state: RootState) => state.base.siteInfo.configs.baseTitle);
    const getFooterLink = async () => {
        const result = await mutateAsync({});

        if ('data' in result) {
            const homeInfo = result.data.find((o: DataProps) => o.pageType === 1).list || [];
            const helpCenter =
                result.data
                    .find((item: DataProps) => item.pageType === 2)
                    ?.list?.find((items: ListType) => items.pageCode === 'FAQ')?.detail || [];

            dispatch(
                setAboutUs({
                    homeInfo,
                    helpCenter
                })
            );

            setList([
                ...result.data,
                ...[{ name: t('footer_onlineCustomerService'), pageType: 3, list: [] }]
            ]);
        }
    };

    const _onClick = (pageType: number) => {
        if (pageType == 3) {
            navigate('/serviceView');
        } else if (pageType === 1) {
            navigate('/aboutUs');
        } else if (pageType === 2) {
            navigate('/helpCenter');
        }
    };

    useEffect(() => {
        getFooterLink();
    }, []);

    return (
        <footer className={style.footer}>
            <div className={style.footerSponsorTitle}>{t('footer_gameProviders')}</div>
            <div className={style.footerList}>
                <div className={style.footerSponsor}>
                    {sponsorList.map((item, index) => {
                        return (
                            <div key={index} className={style.sponsorIcon}>
                                <img src={item.img} />
                                {/* <span>{t(item.title)}</span> */}
                            </div>
                        );
                    })}
                </div>
                <ul className={style.footerLink}>
                    {list.map(listItem => {
                        return (
                            <li
                                key={listItem.pageType}
                                className={`${style.footerLi}`}
                                onClick={() => {
                                    _onClick(listItem.pageType);
                                }}
                            >
                                {listItem.name}
                            </li>
                        );
                    })}
                </ul>
            </div>

            <div className={style.row}>
                <div className={style.cooperation}>
                    <span>{t('footer_businessCooperation')}</span>
                    <div className={style.iconBar}>
                        <img src={Telegram} />
                        <img src={Mail} />
                        <img src={Skype} />
                    </div>
                </div>
                <div className={style.payment}>
                    <span>{t('footer_payment')}</span>
                    <div className={style.iconBar}>
                        <img src={TPay} />
                        <img src={PixPay} />
                    </div>
                </div>
            </div>
            <div className={style.footerStory}>
                {baseTitle} {t('footer_echoLegalLicenses')}
                <div>{t('footer_copyrightNotice')}</div>
                <span className={style.version}>version: v1.1.83</span>
            </div>
        </footer>
    );
}
export default Footer;
