import BasePopup from '@/components/basePopup/BasePopup';
import style from './bindPopup.module.css';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { useUpdateUserInfoMutation } from '../api';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { setMessage } from '@/app/slice/baseSlice';
import { useTranslation } from 'react-i18next';
import { useEmailCaptcha } from '@/hooks/useEmailCaptcha';
import CircularProgress from '@mui/material/CircularProgress';
import { useVerifyRealEmailMutation } from '@/app/services/user';
import SendPhoneMessage from '@/components/sendPhoneMessage/SendPhoneMessage';
import flagUrl from '@/components/userDialog/img/flag.png';

type FormType = {
    messageCode: string;
    validationCode: string;
    bindTarget: string;
};

function BindPopup(props: { onClose: () => void; type: string; refreshData?: () => void }) {
    const { t } = useTranslation();
    const [mutation, { isLoading: subLoading }] = useVerifyRealEmailMutation();
    const [updateUserInfo, { isLoading: phoneSubLoading }] = useUpdateUserInfoMutation();
    const { onClose, type, refreshData } = props;
    const dispatch = useDispatch();
    const defaultCurrency = useSelector((state: RootState) => state.base.siteInfo.defaultCurrency);
    const currencys = useSelector(
        (state: RootState) =>
            state.base.siteInfo.currencys?.find(x => x.currency == defaultCurrency)?.areas || []
    );
    const [curCountryCode, setCurCountryCode] = useState(currencys[0].areaCode);
    const [phoneValidateCode, setPhoneValidateCode] = useState('0');
    const { fetchData, seconds, isLoading } = useEmailCaptcha();

    const handleChange = (event: SelectChangeEvent) => {
        const {
            target: { value }
        } = event;
        setCurCountryCode(value);
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues
    } = useForm<FormType>();

    const setCode = (value: string) => {
        setPhoneValidateCode(value);
    };

    const handleVerificationCode = async () => {
        if (seconds) return false;
        const { bindTarget } = getValues();

        if (type === 'email') {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

            if (!emailRegex.test(bindTarget))
                return dispatch(
                    setMessage({
                        type: 'error',
                        message: t('login_emailIncorrect')
                    })
                );
            fetchData(bindTarget);
        }
    };

    const doSubmit = handleSubmit(async param => {
        if (subLoading || phoneSubLoading) return;
        try {
            if (type == 'phoneNumber') {
                await updateUserInfo({
                    phoneNumber: param.bindTarget,
                    areaNumber: curCountryCode,
                    phoneValidateCode: phoneValidateCode
                }).unwrap();

                dispatch(
                    setMessage({
                        type: 'success',
                        message: t('accountSecurity_updateSuccess')
                    })
                );
                refreshData && refreshData();
                onClose();
            } else {
                await mutation({
                    email: param.bindTarget,
                    validateCode: param.validationCode
                }).unwrap();

                dispatch(
                    setMessage({
                        type: 'success',
                        message: t('accountSecurity_updateSuccess')
                    })
                );
                refreshData && refreshData();
                onClose();
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            dispatch(
                setMessage({
                    type: 'error',
                    message: e?.message
                })
            );
        }
    });

    return (
        <>
            <BasePopup
                close={onClose}
                title={
                    type === 'phoneNumber'
                        ? t('accountSecurity_bindPhoneNumber')
                        : t('accountSecurity_bindEmail')
                }
                width="345px"
            >
                <>
                    <form onSubmit={doSubmit}>
                        <div className={style.formGroup}>
                            {type === 'email' && <p>{t('accountSecurity_bindEmail')}</p>}
                            <div
                                className={`${style.inputBar} ${
                                    type === 'phoneNumber' ? style.registerPhone : ''
                                }`}
                            >
                                {type === 'phoneNumber' && (
                                    <Select
                                        className={style.select}
                                        displayEmpty
                                        value={curCountryCode}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {currencys?.map(item => (
                                            <MenuItem key={item.iconIndex} value={item.areaCode}>
                                                <span
                                                    className={style.countryIcon}
                                                    style={{
                                                        background: `url(${flagUrl})`,
                                                        width: 22,
                                                        height: 22,
                                                        backgroundPositionX:
                                                            (20 - (item.iconIndex % 20) + 1) * 22,
                                                        backgroundPositionY: -(
                                                            ~~(item.iconIndex / 20) * 22
                                                        ),
                                                        backgroundSize: '440px 132px'
                                                    }}
                                                ></span>
                                                <span
                                                    className={`${style.countryName} countryName`}
                                                >
                                                    {item.areaCode}
                                                </span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}

                                <input
                                    {...register('bindTarget', {
                                        required: 'required'
                                    })}
                                    className={`${style.input} ${
                                        errors.bindTarget ? 'error' : ''
                                    } ${type === 'phoneNumber' ? style.ml8 : ''}`}
                                    placeholder={
                                        type === 'phoneNumber'
                                            ? t('accountSecurity_phoneNumber')
                                            : t('accountSecurity_email')
                                    }
                                    maxLength={type === 'phoneNumber' ? 11 : 30}
                                />
                            </div>
                            {type == 'phoneNumber' && (
                                <SendPhoneMessage
                                    sendType={3}
                                    getPhoneNumber={() => getValues().bindTarget}
                                    areaNumber={curCountryCode}
                                    setCode={setCode}
                                ></SendPhoneMessage>
                            )}
                            {type === 'email' && (
                                <div className={style.inputBar}>
                                    <p>{t('global_captcha')}</p>
                                    <input
                                        {...register('validationCode', {
                                            required: 'required'
                                        })}
                                        className={`${style.input} ${
                                            errors.validationCode ? 'error' : ''
                                        }`}
                                        placeholder={t('global_enterCaptcha')}
                                    />
                                    <Button
                                        className={seconds > 0 ? style.reCodeBtn : style.getCodeBtn}
                                        variant="contained"
                                        onClick={handleVerificationCode}
                                    >
                                        {seconds > 0
                                            ? `${t('global_readquirir')} (${seconds} s)`
                                            : t('global_getCaptcha')}
                                        {isLoading && (
                                            <CircularProgress
                                                size={16}
                                                style={{
                                                    color: '#fff',
                                                    marginLeft: '8px'
                                                }}
                                            />
                                        )}
                                    </Button>
                                </div>
                            )}
                            <div className={style.divider}></div>
                            <Button type="submit" fullWidth className={style.submit}>
                                {t('global_submit')}
                                {(subLoading || phoneSubLoading) && (
                                    <CircularProgress
                                        size={16}
                                        style={{ color: '#fff', marginLeft: '5px' }}
                                    />
                                )}
                            </Button>
                        </div>
                    </form>
                </>
            </BasePopup>
        </>
    );
}

export default BindPopup;
