import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import style from '../style/registerSuccessful.module.css';
import SuccRewordTitle from '../img/succRewordTitle.png';
import SuccessCenter from '../img/successCenter.png';
import { useGlobalActiveList } from '@/hooks/useGlobalActiveList';
import { setBasePopup, setLoginDialogType } from '@/app/slice/baseSlice';
import { currencySymbol } from '@/utils/tool';
import { RootState } from '@/app/store';
import CloseIcon from '@/assets/img/close.svg';
function RegisterSuccessfulPc() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { rechargeDiscountActivity, showDiscountTime } = useGlobalActiveList();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const onGoPage = (str: string) => {
        navigate(str === '/' ? '/' : '/deposit');
        dispatch(setBasePopup(''));
        dispatch(setLoginDialogType('login'));
    };
    const activityInfo = useSelector((state: RootState) =>
        state.base.globalActivityList?.find(x => x.exclusiveActivityType == 8)
    );

    return (
        <>
            <div className={style.registerSuccessfulMask}></div>

            <div className={style.registerSuccessful}>
                <div className={style.registerSuccessfulTop}>
                    <img src={SuccRewordTitle} />
                    <span>{t('register_successful_info')}</span>
                </div>
                <div className={style.registerSuccessfulBox}>
                    {activityInfo && (
                        <div className={style.registerSuccessfulTopAmount}>
                            <div className={style.registerSuccessfulTopAmountBox}>
                                <span>
                                    R$<strong>{activityInfo?.registerAmount?.toFixed(2)}</strong>
                                </span>
                                <div className={style.amountBoxLine}></div>
                            </div>
                            <h3>{t('system_give')}</h3>
                            <p>{t('system_deposited')}</p>
                        </div>
                    )}
                </div>
                <div className={style.successBottom}>
                    <div className={style.successCenter}>
                        <img src={SuccessCenter} />
                    </div>
                    <div className={style.successCenterRight}>
                        <div className={style.successCenterNum}>
                            <span>
                                {rechargeDiscountActivity.rechargeCardAmount}
                                {''}
                            </span>
                            <span className={style.fuHao}>
                                {rechargeDiscountActivity.calcType === 0
                                    ? `${currencySymbol[userInfo.currency]}`
                                    : '%'}
                            </span>
                        </div>
                        <span className={style.successBottomInfo}>
                            {' '}
                            {t('deposit_recharge_discount').toUpperCase()}
                        </span>

                        <div className={style.discountCenterTimeBottom}>
                            <span className={style.centerTime}>{showDiscountTime.hours}</span>
                            <span className={style.centerFuHao}>:</span>
                            <span className={style.centerTime}>{showDiscountTime.minutes}</span>
                            <span className={style.centerFuHao}>:</span>
                            <span className={style.centerTime}>{showDiscountTime.second}</span>
                        </div>
                    </div>
                </div>
                <div className={style.successBtnWrap} onClick={() => onGoPage('/deposit')}>
                    <div className={style.successBtn}>
                        <span>{t('deposit_deposit')}</span>
                    </div>
                </div>

                <div className={style.successGoHome} onClick={() => onGoPage('/')}>
                    <img src={CloseIcon} />
                </div>
            </div>
        </>
    );
}

export default RegisterSuccessfulPc;
