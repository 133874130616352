export interface RechargeDiscountActivityProps {
    rechargeCardAmount: number;
    calcType: number;
    overtime: number;
    taskList?: unknown[];
    id?: string;
    exclusiveActivityType: number;
}

export interface RouletteBoosterProps {
    exclusiveActivityType: number;
    taskList: [];
}

export type ActivityTypeProps = RechargeDiscountActivityProps | RouletteBoosterProps;

export const rechargeDiscountInitData = {
    exclusiveActivityType: 6,
    overtime: 0,
    rechargeCardAmount: 0,
    calcType: 0
};
