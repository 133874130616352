import { createSlice } from '@reduxjs/toolkit';
import { analyticsBackFn } from '@/utils/tool';

interface BaseState {
    moneyRainShow: boolean;
    rechargeSuccessMsg: string;
    rouletteHelpMessage: { rewardAmount: number; memberAccount: string };
}

const initialState: BaseState = {
    moneyRainShow: false,
    rechargeSuccessMsg: '',
    rouletteHelpMessage: {
        rewardAmount: 0,
        memberAccount: ''
    }
};

export const socketMessageSlice = createSlice({
    name: 'socketMessage',
    initialState,
    reducers: {
        // 接受socket 消息
        receivedMessage: (state, action) => {
            const { type, msg } = action.payload;
            switch (type.toString()) {
                // case '13':
                //     state.moneyRainShow = true;
                //     break;
                case '1': // 充值成功
                    state.rechargeSuccessMsg = msg;
                    break;
                case '14':
                    state.rouletteHelpMessage = JSON.parse(msg);
                    break;
                case '16': // fb埋点首存
                    analyticsBackFn('AddToCart');
                    break;
                default:
                    break;
            }
        },

        setRouletteHelpMessage: (state, action) => {
            state.rouletteHelpMessage = action.payload;
        },

        setMoneyRainShow: (state, action) => {
            state.moneyRainShow = action.payload;
        }
    }
});

export const { receivedMessage, setMoneyRainShow, setRouletteHelpMessage } =
    socketMessageSlice.actions;

export default socketMessageSlice.reducer;
