import { ReactElement, useEffect, useState } from 'react';
import style from './basePopup.module.css';
// import { useLockBodyScroll } from '@/hooks/lockScroll';
import { motion, AnimatePresence } from 'framer-motion';
import CloseIcon from '@/assets/img/close.svg';
function BasePopup({
    showTitle = true,
    showClose = true,
    title,
    children,
    close,
    width,
    height,
    padding,
    curTop,
    noNeedBottom
}: {
    children: ReactElement | string;
    showTitle?: boolean;
    showClose?: boolean;
    title?: ReactElement | string;
    close?: () => void;
    maxWidth?: string;
    width?: string;
    height?: string;
    padding?: string | number;
    curTop?: string;
    noNeedBottom?: boolean;
}) {
    const [isShow, setIsShow] = useState(true);
    // useLockBodyScroll();
    const handleClose = () => {
        setIsShow(false);
        if (close) {
            setTimeout(() => {
                close();
            }, 300);
        }
    };

    useEffect(() => {
        const element =
            (document.querySelector('.transLayout') as HTMLElement) ||
            (document.querySelector('.transWebLayout') as HTMLElement);
        if (element) {
            element.style.animation = 'none';
        }
    }, []);

    return (
        <div className={`${style.basePopup}  ${isShow ? style.mesk : ''}`} onClick={handleClose}>
            <div className={style.basePopupWrap} style={{ top: curTop ? curTop : '50%' }}>
                <AnimatePresence>
                    {isShow && (
                        <motion.div
                            key={isShow ? 'show' : 'hide'}
                            className={style.popupBox}
                            initial={{ opacity: 0, scale: 0.3 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.3 }}
                            transition={{
                                duration: 0.3,
                                ease: [0, 0.71, 0.2, 1.01]
                            }}
                        >
                            <div
                                className={style.box}
                                onClick={e => e.stopPropagation()}
                                style={{
                                    width: `${width}`,
                                    height: `${height}`,
                                    padding: `${padding}`
                                }}
                            >
                                <div
                                    className={`${style.titleWrap} ${
                                        !showTitle
                                            ? `${style.absoluteTitleWrap} ${style.noTitle}`
                                            : ''
                                    } ${noNeedBottom ? '' : style.titleWrapBottom}`}
                                >
                                    {showTitle && <div className={style.title}>{title}</div>}
                                    {showClose && close && (
                                        <img
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            className={style.close}
                                            onClick={handleClose}
                                        />
                                    )}
                                </div>
                                {children}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}

export default BasePopup;
