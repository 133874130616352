import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '@/app/store';
import emitter from '@/utils/events';
import { useNavigate } from 'react-router-dom';
import { setBasePopup } from '@/app/slice/baseSlice';

interface jumpProps {
    urlType: string;
    jumpUrlObj?: {
        isNeedLogin: boolean;
        type: string | number;
    };
    jumpUrl?: string;
}

export default function useJump() {
    const navigate = useNavigate();
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const dispatch = useDispatch();
    const location = useLocation();
    const jumpOutLink = (url: string) => {
        url && window.open(url);
    };
    const jumpTarget = ({ urlType, jumpUrl }: jumpProps) => {
        console.log(urlType, 111);
        console.log(jumpUrl, 222);
        if (+urlType == 1 && jumpUrl) return jumpOutLink(jumpUrl);

        if (+urlType == 2 && jumpUrl) {
            const jumpUrlObj = JSON.parse(jumpUrl);
            if (jumpUrlObj && jumpUrlObj.isNeedLogin && !isLogin)
                return dispatch(setBasePopup('login'));
            switch (jumpUrlObj?.type) {
                case 1:
                    // 活动详情
                    break;
                case 2:
                    // 活动列表
                    navigate('/eventCenter?type=promotional');
                    break;
                case 3:
                    navigate('/eventCenter?type=exclusive');
                    // 任务中心
                    break;
                case 4:
                    //充值页面
                    navigate('/deposit');
                    break;
                case 5:
                    // 签到活动
                    break;
                case 6:
                    break;
                case 7:
                    // 红包雨活动
                    location.pathname === '/' ? emitter.emit('openRainTypeFun') : navigate('/');
                    break;
                case 8:
                    // 转盘活动
                    break;
                case 9:
                    // vip返现
                    navigate('/eventCenter?type=daily');
                    break;
                case 10:
                    // 宝箱活动
                    navigate('/inviteFriends');
                    break;
                case 11:
                    // 三级分销
                    navigate('/agencyPromotion');
                    break;
                case 12:
                    // 全民代理
                    navigate('/agencyWholePromotion');
                    break;
                case 13:
                    // 轮盘助力
                    navigate('/turnTable');
                    break;
                case 14:
                    // vip详情页
                    navigate('/vipRules');
                    break;
            }
        }
    };

    return { jumpTarget, jumpOutLink };
}
