import { createSlice } from '@reduxjs/toolkit';
import newsApi from './api';

interface newsDetail {
    content: string;
    createDate: string;
    createTime: number;
    expiredTime: number;
    expiredTimeDate: string;
    fundsType: number;
    id: number;
    memberAccount: string;
    memberCreateTime: string;
    memberId: number;
    money: number;
    readedTime: string;
    receive: number; // 0未領 1已領 2過期
    status: number; // 状态 0未读 1已读 2 取消 ,3删除
    title: string;
    type: number; // 类型-- 0 系统 1 订单消息 2活动消息 3 彩金
}

interface newsUnread {
    unReadActivityCount: number;
    unReadOrderCount: number;
    unReadSystemCount: number;
    unReadTotalCount: number;
    unReceiveTotalCount: number;
}

interface newsStatus {
    idList: Array<number>; // id如果为空的话 就是一键处理会员的消息状态,id不为空 就按id处理
    status: number; // 1表示读取消息 3表示删除消息
    type: number; // 消息类型 0 系统 1 订单消息 2活动消息 3 彩金,不传是全部类型消息的处理
}

interface newsPrize {
    idList: Array<number>; // 	idList list数组一键处理会员领取彩金
}

interface NewsProps {
    newsData: {
        data: newsDetail[];
        pageMoney: number;
        total: number;
        totalData: {};
    };
    newsUnread: newsUnread | [];
    newsStatus: newsStatus | [];
    newsPrize: newsPrize | [];
    pageSize: number;
    pageNumber: number;
    isFetchingList: boolean;
}

const initialState: NewsProps = {
    newsData: {
        data: [],
        pageMoney: 0,
        total: 0,
        totalData: {}
    },
    newsUnread: [],
    newsStatus: [],
    newsPrize: [],
    pageSize: 10,
    pageNumber: 1,
    isFetchingList: false
};

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNewsList: (state, action) => {
            state.newsData = action.payload;
        },
        setNewsUnread: (state, action) => {
            state.newsUnread = action.payload;
        },
        setNewsStatus: (state, action) => {
            state.newsStatus = action.payload;
        },
        setNewsPrize: (state, action) => {
            state.newsPrize = action.payload;
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(newsApi.endpoints.news.matchFulfilled, (state, action) => {
            state.newsData = action.payload;
            state.isFetchingList = false;
        });
        builder.addMatcher(newsApi.endpoints.news.matchPending, state => {
            state.newsData = { data: [], pageMoney: 0, total: 0, totalData: {} };
            state.isFetchingList = true;
        });
        builder.addMatcher(newsApi.endpoints.news.matchRejected, state => {
            state.isFetchingList = false;
        });
    }
});

export const {
    setNewsList,
    setNewsUnread,
    setNewsStatus,
    setNewsPrize,
    setPageNumber,
    setPageSize
} = newsSlice.actions;

export default newsSlice.reducer;
