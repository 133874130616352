import api from '../api';
import API_PATH from './apiPathConfig';

const siteApi = api.injectEndpoints({
    endpoints: builder => ({
        siteNoticeList: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.site}/f/siteNotice/queryList`,
                method: 'POST',
                body: paymentData
            })
        }),
        siteUploadImg: builder.mutation({
            query: paymentData => {
                const formData = new FormData();
                for (var key in paymentData) {
                    formData.append(key, paymentData[key]);
                }

                return {
                    url: `${API_PATH.site}/f/common/uploadImageFile`,
                    method: 'POST',
                    body: formData
                };
            }
        }),
        queryWithdrawChannel: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.site}/f/withdrawOrder/queryBankWithChannels`,
                method: 'POST',
                body: paymentData
            })
        }),
        withdrawSubmit: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.site}/f/withdrawOrder/withdraw`,
                method: 'POST',
                body: paymentData
            })
        }),
        footerLink: builder.mutation({
            query: data => ({
                url: `${API_PATH.site}/f/sitePageConfig/queryList`,
                method: 'POST',
                body: data
            })
        }),
        suggestionSubmit: builder.mutation({
            query: data => ({
                url: `${API_PATH.site}/f/siteSuggestions/add`,
                method: 'POST',
                body: data
            })
        }),
        querySuggestionRecord: builder.mutation({
            query: data => ({
                url: `${API_PATH.site}/f/siteSuggestions/queryPage`,
                method: 'POST',
                body: data
            })
        }),
        sendEmailMsg: builder.mutation({
            query: data => ({
                url: `${API_PATH.site}/f/email/sendMsg`,
                method: 'POST',
                body: data
            })
        }),
        sendPhoneMsg: builder.mutation({
            query: data => ({
                url: `${API_PATH.site}/f/phone/sendMsg`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const {
    useSiteNoticeListMutation,
    useSiteUploadImgMutation,
    useQueryWithdrawChannelMutation,
    useWithdrawSubmitMutation,
    useFooterLinkMutation,
    useSuggestionSubmitMutation,
    useQuerySuggestionRecordMutation,
    useSendEmailMsgMutation,
    useSendPhoneMsgMutation
} = siteApi;
export default siteApi;
