import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './baseDiscountTime.module.css';

interface DiscountCenterTimeProps {
    showDiscountTime: { hours: string; minutes: string; second: string };
}

const DiscountCenterTime: React.FC<DiscountCenterTimeProps> = React.memo(({ showDiscountTime }) => {
    const { t } = useTranslation();
    return (
        <div className={style.discountCenterTime}>
            <div className={style.discountCenterTimeTop}>
                <span>{t('deposit_expires')}</span>
            </div>
            <div className={style.discountCenterTimeBottom}>
                <span className={style.centerTime}>{showDiscountTime.hours}</span>
                <span className={style.centerFuHao}>:</span>
                <span className={style.centerTime}>{showDiscountTime.minutes}</span>
                <span className={style.centerFuHao}>:</span>
                <span className={style.centerTime}>{showDiscountTime.second}</span>
            </div>
        </div>
    );
});

// eslint-disable-next-line react/display-name
DiscountCenterTime.displayName = 'DiscountCenterTime';

export default DiscountCenterTime;
