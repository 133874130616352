import React, { useEffect, useRef } from 'react';
import styles from '../style/scrollText.module.css';

interface ScrollTextProps {
    list: string[];
}

const ScrollText: React.FC<ScrollTextProps> = ({ list }) => {
    const contentRef = useRef<HTMLDivElement>(null);

    const initDom = () => {
        const texts = contentRef.current?.children;
        if (texts && texts.length > 1) {
            const firstChild = texts[0] as HTMLElement;
            const span = firstChild.querySelector('span') as HTMLElement;
            if (span) {
                scrollLeft(firstChild, span, 0);
            }
        }
    };

    const scrollLeft = (p: HTMLElement, span: HTMLElement, step: number) => {
        const maxScroll = span.clientWidth - p.clientWidth + 5;
        if (step >= maxScroll - 5) {
            setTimeout(() => initiateCircle(p, span), 3000);
            return;
        }
        requestAnimationFrame(() => {
            span.style.transform = `translateX(-${step}px)`;
            scrollLeft(p, span, step + 0.8);
        });
    };

    const initiateCircle = (p: HTMLElement, span: HTMLElement) => {
        const texts = contentRef.current;
        if (texts && texts.contains(p)) {
            const itemHeight = p.clientHeight;
            resetPosition(p, span, itemHeight);
        }
    };

    const resetPosition = (p: HTMLElement, span: HTMLElement, itemHeight: number) => {
        const texts = contentRef.current;
        setTimeout(() => {
            if (texts?.contains(p)) {
                texts.removeChild(p);
                texts.appendChild(p);
                span.style.transform = 'translateX(0px)';
                translateY(texts, itemHeight);
            }
        }, 500);
    };

    const translateY = (texts: HTMLDivElement, itemHeight: number) => {
        texts.style.transition = 'none';
        texts.style.transform = `translateY(${itemHeight}px)`;

        setTimeout(() => {
            texts.style.transition = 'transform 1s linear';
            texts.style.transform = 'translateY(0px)';
            restartScroll(texts);
        }, 200);
    };

    const restartScroll = (texts: HTMLDivElement) => {
        setTimeout(() => {
            const firstChild = texts.children[0] as HTMLElement;
            const span = firstChild.querySelector('span') as HTMLElement;
            if (span) {
                scrollLeft(firstChild, span, 0);
            }
        }, 2000);
    };

    useEffect(() => {
        initDom();
    }, []);

    return (
        <div ref={contentRef} className={styles.scrollText}>
            {list.map((content, index) => (
                <p key={index} className={styles.text}>
                    <span>
                        {index + 1}. {content}
                    </span>
                </p>
            ))}
        </div>
    );
};

export default ScrollText;
