import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import md5 from 'md5';
import ShowPasswordButton from './ShowPasswordButton';
import style from '../style/login.module.css';
import Button from '@mui/material/Button';
import LockIcon from '../img/lock.svg';
import Safety from '../img/safety.svg';
import InviteIcon from '../img/invite.svg';
import flagUrl from '../img/flag.png';
import { setMessage, setGlobalActivityList, setBasePopup } from '@/app/slice/baseSlice';
import { setUserInfo, setLoginStatus, setUserAvatar } from '@/app/slice/userSlice';
import { useGlobalActivityListMutation } from '@/app/services/activity';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/store';
// import CheckBox from './CheckBox';
// import PrivacyDialog from './PrivacyDialog';
import { useRegisterMutation, useGetVerifyCodeMutation } from '@/app/services/user';
import { RegValues, LoginProps } from '../types';
import Turnstile from '@/components/turnstile/Turnstile';
import { pictureImgDomain, analyticsBackFn } from '@/utils/tool';
// import SendPhoneMessage from '@/components/sendPhoneMessage/SendPhoneMessage';
function Register({ onClick }: LoginProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [mutateAsync] = useRegisterMutation();
    const [getVerifyCode] = useGetVerifyCodeMutation();
    const [activityListMutateAsync] = useGlobalActivityListMutation();
    const regMemberVerifyCode = useSelector(
        (state: RootState) => state.base.siteInfo.configs.regMemberVerifyCode
    );
    const regInviteCode = useSelector(
        (state: RootState) => state.base.siteInfo.configs.regInviteCode
    );
    const inviteCode = useSelector((state: RootState) => state.base.inviteCode);
    const channelId = useSelector((state: RootState) => state.base.channelId);
    const defaultCurrency = useSelector((state: RootState) => state.base.siteInfo.defaultCurrency);
    const currencys = useSelector(
        (state: RootState) =>
            state.base.siteInfo.currencys?.find(x => x.currency == defaultCurrency)?.areas || []
    );
    const [machineToken, setMachineToken] = useState<string>('');
    const [curCountryCode, setCurCountryCode] = useState(currencys[0].areaCode);
    const [showPassword, setShowPassword] = useState(true);
    // const [checkStatus, setCheckStatus] = useState(true);
    const [registerLoading, setRegisterLoading] = useState(false);
    // const [phoneValidateCode, setPhoneValidateCode] = useState('0');
    // const [popup, setPopup] = useState('');
    const [verifyCode, setVerifyCode] = useState({
        img: '',
        token: ''
    });
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid }
    } = useForm<RegValues>({
        mode: 'onChange', // 确保 isValid 随输入变化
        reValidateMode: 'onChange'
    });

    // const popupDialog = (value: string) => {
    //     setPopup(value);
    // };

    // const handleheckStatus = () => {
    //     setCheckStatus(value => !value);
    // };

    const handleChange = (event: SelectChangeEvent) => {
        const { value } = event.target;
        setCurCountryCode(value);
    };

    // const setCode = (value: React.SetStateAction<string>) => {
    //     setPhoneValidateCode(value);
    // };

    const handleRegister = handleSubmit(async params => {
        if (regMemberVerifyCode == 3 && !machineToken) return;
        if (registerLoading) return;
        // if (!checkStatus)
        //     return dispatch(setMessage({ type: 'success', message: t('login_agreeAccount') }));
        setRegisterLoading(true);
        const subParams = {
            ...params,
            areaNumber: curCountryCode,
            password: md5(params.password),
            registerMethod: 6,
            inviteCode: inviteCode,
            promoId: channelId ? channelId : undefined
            // phoneValidateCode: phoneValidateCode
        };
        if (regMemberVerifyCode === 1) subParams.imgValidateCodeToken = verifyCode.token;
        if (regMemberVerifyCode === 3) subParams.imgValidateCode = machineToken;
        const result = await mutateAsync(subParams);
        if ('data' in result && result.data != undefined) {
            analyticsBackFn('register');
            dispatch(setUserAvatar(`${pictureImgDomain}web/orange/avatar/default.png`));
            dispatch(setLoginStatus(result.data.responseVO.token));
            dispatch(setMessage({ type: 'success', message: t('login_registrationSuccess') }));
            dispatch(setUserInfo(result.data.responseVO));
            localStorage.setItem(
                'lI',
                JSON.stringify({
                    mA: params.phoneNumber,
                    pd: params.password
                })
            );
            const activityList = await activityListMutateAsync(params);
            if ('data' in activityList) {
                dispatch(setGlobalActivityList(activityList.data));
                const list = activityList.data as { exclusiveActivityType: number }[];
                const flag = list.some(
                    x => x.exclusiveActivityType === 6 || x.exclusiveActivityType === 8
                );

                if (flag) {
                    onClick();
                } else {
                    dispatch(setBasePopup(''));
                    setRegisterLoading(false);
                }
            }
        } else {
            setRegisterLoading(false);
            _getVerifyCode();
        }
    });

    const handleShowPassword = () => {
        setShowPassword(value => !value);
    };

    const _getVerifyCode = async () => {
        const result = await getVerifyCode({});
        if ('data' in result) {
            setVerifyCode(result.data);
        }
    };
    // 谷歌人机验证
    const handleVerify = (token: string) => {
        setMachineToken(token);
    };

    useEffect(() => {
        if (regMemberVerifyCode === 1) _getVerifyCode();
        if (inviteCode) setValue('registerInviteCode', inviteCode);
    }, [regMemberVerifyCode, inviteCode, setValue]);

    return (
        <>
            <div className={style.loginWrapper}>
                <form onSubmit={handleRegister}>
                    <div className={`${style.inputBar} ${style.registerPhone}`}>
                        <Select
                            className={style.select}
                            displayEmpty
                            value={curCountryCode}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            inputProps={{ 'aria-label': 'Without label' }}
                            disabled={currencys.length === 1}
                        >
                            {currencys?.map(item => (
                                <MenuItem key={item.iconIndex} value={item.areaCode}>
                                    <span
                                        className={style.countryIcon}
                                        style={{
                                            background: `url(${flagUrl})`,
                                            width: 22,
                                            height: 22,
                                            backgroundPositionX:
                                                (20 - (item.iconIndex % 20) + 1) * 22,
                                            backgroundPositionY: -(~~(item.iconIndex / 20) * 22),
                                            backgroundSize: '440px 132px'
                                        }}
                                    ></span>
                                    <span className={`${style.countryName} countryName`}>
                                        {item.areaCode}
                                    </span>
                                </MenuItem>
                            ))}
                        </Select>
                        <input
                            {...register('phoneNumber', {
                                required: t('login_mobileEmpty'),
                                pattern: {
                                    value: /^(1[1-9]|2[1-9]|3[1-5]|4[1-9]|5[1-5]|6[1-9]|7[1-9]|8[1-9]|9[1-9])9\d{8}$/,
                                    message: t('login_phoneRequired')
                                }
                            })}
                            maxLength={11}
                            autoComplete="phoneNumber"
                            className={`${style.input} ${errors.phoneNumber ? 'error' : ''} ${
                                style.countryInput
                            }`}
                            placeholder={t('login_inputMobile')}
                            onInput={event => {
                                const input = event.target as HTMLInputElement;
                                input.value = input.value.replace(/[^0-9]/g, '');
                            }}
                        />
                        {errors.phoneNumber && (
                            <p className={`${style.error} ${style.errorPhoneNumber}`}>
                                {errors.phoneNumber.message}
                            </p>
                        )}
                    </div>
                    {/* <SendPhoneMessage
                        sendType={0}
                        getPhoneNumber={() => getValues().phoneNumber}
                        areaNumber={curCountryCode}
                        setCode={setCode}
                    ></SendPhoneMessage> */}
                    <div className={style.inputBar}>
                        <img src={LockIcon} className={style.inputIcon} alt="LockIcon" />
                        <input
                            {...register('password', {
                                required: t('login_passwordBlank'),
                                pattern: {
                                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]{6,16}$/,
                                    message: t('login_passwordRequired')
                                }
                            })}
                            maxLength={16}
                            autoComplete="current-password"
                            className={`${style.input} ${errors.password ? 'error' : ''}`}
                            placeholder={t('login_enterPassword')}
                            type={!showPassword ? 'text' : 'password'} // 逻辑修正
                        />
                        <ShowPasswordButton isShow={showPassword} change={handleShowPassword} />
                        {errors.password && (
                            <p className={style.error}>{errors.password.message}</p>
                        )}
                    </div>

                    {regMemberVerifyCode === 1 && (
                        <div className={style.inputBar}>
                            <img src={Safety} className={style.inputIcon} alt="Safety" />
                            <input
                                {...register('imgValidateCode', {
                                    required: t('login_VerificationCannot'),
                                    pattern: {
                                        value: /^\d{4}$/,
                                        message: t('login_captchaRequired')
                                    }
                                })}
                                autoComplete="imgValidateCode"
                                className={`${style.input} ${
                                    errors.imgValidateCode ? 'error' : ''
                                }`}
                                maxLength={4}
                                placeholder={t('login_inputGraphics')}
                            />
                            {errors.imgValidateCode && (
                                <p className={style.error}>{errors.imgValidateCode.message}</p>
                            )}
                            <img
                                src={verifyCode.img}
                                className={style.verifyCodeImg}
                                onClick={_getVerifyCode}
                            />
                        </div>
                    )}

                    {regInviteCode === 1 && (
                        <div className={style.inputBar}>
                            <img src={InviteIcon} className={style.inputIcon} alt="InviteIcon" />
                            <input
                                {...register('registerInviteCode', { required: true })}
                                autoComplete="registerInviteCode"
                                className={`${style.input} ${
                                    errors.registerInviteCode ? 'error' : ''
                                }`}
                                placeholder={t('login_enterCode')}
                                disabled={inviteCode !== ''}
                            />
                        </div>
                    )}

                    {regMemberVerifyCode == 3 && <Turnstile onVerify={handleVerify} />}
                    <div className={`${style.loginBtnGroup} ${style.regBtnGroup}`}>
                        <Button
                            className={style.submit}
                            fullWidth
                            variant="contained"
                            type="submit"
                            style={{
                                opacity: isValid ? 1 : 0.5
                            }}
                        >
                            {t('login_register')}
                            {registerLoading && (
                                <CircularProgress
                                    size={16}
                                    style={{ color: '#fff', marginLeft: '10px' }}
                                />
                            )}
                        </Button>
                    </div>

                    {/* <div className={style.footer}>
                        <div className={style.checkboxGroup}>
                            <CheckBox isCheck={checkStatus} change={handleheckStatus} />
                            <p className={style.checkboxRules}>
                                {t('login_haveReached')}
                                <span className={style.link} onClick={() => popupDialog('privacy')}>
                                    {t('login_openingAgreement')}
                                </span>
                            </p>
                        </div>
                    </div> */}
                </form>
            </div>
            {/* {popup === 'privacy' && (
                <PrivacyDialog handleheckStatus={handleheckStatus} setPopup={setPopup} />
            )} */}
        </>
    );
}

export default Register;
