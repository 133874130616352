import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RootState } from '@/app/store';
import { IMAGE_TYPE, utils_getImage } from '@/utils/image';
import PlaceholderImg from '@/assets/img/placeholderImg.svg';
import WeiHu from '@/assets/img/weiHu.png';
import XiaoRen from '@/assets/img/xiaoRen.png';
import { setMessage, setBasePopup } from '@/app/slice/baseSlice';
import Start from '@/pages/home/img/start.svg';
import style from './subGameWrap.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface GameSubList {
    hot: number;
    channelCode: string;
    channelName: string;
    desc: string;
    gameCode: string;
    gameLine?: string;
    gameType: string;
    imageUpdate: number;
    isFav: number;
    name: string;
    screenStyle?: number;
    status: number;
    gameName?: string;
}
interface SubGameWrapProps {
    subGameList: GameSubList[]; // 游戏列表
    gameType?: string; // 游戏类型
    needFav?: boolean; // 是否需要展示收藏
    showRowFour?: boolean; // 是否需要一行展示4个 默认一行展示3个
    cardListClassName?: string;
    onClick?: (newGameList: GameSubList[]) => void; // 移除收藏函数
}

function SubGameWrap({
    subGameList,
    gameType,
    // needFav,
    showRowFour,
    cardListClassName
}: // onClick
SubGameWrapProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const mobileDevice = useMediaQuery('(max-width:750px)');
    const handleStartGame = async (gameInfo: GameSubList) => {
        if (!isLogin) return dispatch(setBasePopup('login'));
        if (gameInfo.status !== 0)
            return dispatch(
                setMessage({
                    type: 'error',
                    message: t('home_gamePreparing')
                })
            );
        const curGameType = gameInfo.gameType ? gameInfo.gameType : gameType;
        if (gameInfo.channelCode === 'million') {
            navigate('/game/rocket');
        } else {
            navigate(
                `/gameInfo?gameType=${curGameType}&channelCode=${gameInfo.channelCode}&gameCode=${gameInfo.gameCode}&name=${gameInfo.name}&gameLine=${gameInfo.gameLine}`
            );
        }
    };

    return (
        <ul
            className={`
                ${style.cardList}
                ${cardListClassName === 'homeGuess' ? style.homeGuess : ''}
                ${cardListClassName === 'gameAreaOneList' ? style.gameAreaOneList : ''}
                ${
                    cardListClassName === 'recentGamesOneList' ||
                    cardListClassName === 'collectGamesOneList'
                        ? style.gameAreaOneList
                        : ''
                }
                ${showRowFour ? style.cardListMore : ''}`}
        >
            {subGameList?.map((items, index) => {
                const imageUrl = utils_getImage({
                    imageType: IMAGE_TYPE.GAME,
                    gameType: items.gameType ? items.gameType : gameType,
                    channelCode: items.channelCode,
                    gameCode: items.gameCode
                });

                return (
                    <li key={index} className={style.card} onClick={() => handleStartGame(items)}>
                        <div className={style.cardScaleWrap}>
                            <div className={style.cardScale}>
                                <LazyLoadImage
                                    src={imageUrl}
                                    className={style.cardScaleImage}
                                    placeholderSrc={PlaceholderImg}
                                    onError={e => {
                                        (e.target as HTMLImageElement).src = PlaceholderImg;
                                    }}
                                />
                            </div>
                            {!mobileDevice && (
                                <img src={Start} alt="Start" className={style.startImage} />
                            )}

                            {items.status !== 0 && (
                                <div className={style.weiHuWrap}>
                                    <img src={WeiHu} alt="" />
                                </div>
                            )}

                            {items.status === 0 && (
                                <div className={style.xiaoRenWrap}>
                                    <img src={XiaoRen} alt="" />
                                    <span>{items.hot || 0}</span>
                                </div>
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

export default SubGameWrap;
