import { store } from '@/app/store';

type imageType = {
    imageType: string;
    gameType?: string;
    channelCode?: string;
    gameCode?: string;
    labelCode?: string;
    rechargeFundsType?: number;
};

/**
 * @constant IMAGE_TYPE 图片类型
 * @constant GAME_TYPE 游戏大类
 * @constant GAME_TYPE_ACTIVE 游戏大类选中状态
 * @constant GAME_CHANNEL 游戏场馆
 * @constant GAME_CHANNEL_SUB 游戏场馆导航图
 * @constant GAME_CHANNEL_COLLECTION 游戏场馆收藏图
 * @constant GAME 游戏封面
 * @constant GAME_LABEL 游戏标签
 * @constant GAME_LABEL_ACTIVE 游戏标签选中状态
 * @constant GAME_MENU 首页标签
 * @constant GAME_MENU_ACTIVE 首页标签选中状态
 * @constant SITE_GAME 站点游戏
 * @constant MENU_GAME 头部菜单栏导航二级菜单
 * */
export const IMAGE_TYPE = {
    GAME_TYPE: 'gameType', // 大类游戏
    GAME_TYPE_ACTIVE: 'gameType_hover', // 大类 hover
    GAME_CHANNEL: 'gameChannel', // 渠道游戏
    GAME_CHANNEL_SUB: 'gameChannel_sub', // 副游戏
    GAME_CHANNEL_COLLECTION: 'gameChannel_collection', // 收藏游戏
    GAME: 'game', // 游戏
    GAME_LABEL: 'gameLabel', // 标签游戏
    GAME_LABEL_ACTIVE: 'gameLabel_hover', // 标签游戏 hover
    GAME_MENU: 'gameMenu', // 游戏菜单
    GAME_MENU_ACTIVE: 'gameMenu_hover', // 内页游戏 hover
    SITE_GAME: 'site_game', // 站点游戏
    MENU_GAME: 'menu-game', // 菜单游戏
    GAME_BRAND_IMAGE: 'game-brand-image', // 品牌图片 二级页面
    GAME_TYPE_BACKGROUND: 'game-type-background', // 品牌背景图片 二级页面
    VENUE_CHARACTERS: 'venu_characters', // 场馆人物 二级页面
    FOOTER_LOGO: 'footer_logo', // 底部 场馆 logo
    FOOTER_SINGLE_CHANNEL: 'footer_single_channel', // 底部单个场馆
    RECORD_DEPOSIT: 'record_deposit', // 存款记录
    RECORD_WITHDRAW: 'record_withDraw', // 取款记录
    RECORD_TRANSFER: 'record_transfer', // 资金划转
    RECORD_RECHARGE: 'record_recharge', // 彩金
    RECORD_REBATE: 'record_rebate', // 返佣
    RECORD_OTHER: 'record_other', // 其他
    RECORD_GAME_CONVERT: 'record_gameConvert', // 游戏转换
    GAME_COVER: 'game_cover' // 游戏封面
};

/**
 * @function utils_getImage 获取游戏类图片地址
 * @params imageType 需要获取的图片类型
 * @params lang 语言
 * @params gameType 游戏大类
 * @params channelCode 场馆
 * @params gameCode 游戏 code
 * @params labelCode 标签 code
 * @return string 图片路径
 * */
export const utils_getImage = ({
    imageType,
    gameType,
    channelCode,
    gameCode,
    labelCode,
    rechargeFundsType
}: imageType) => {
    try {
        const state = store.getState().base;
        const locale = state.language.current.languageCode;
        const currency = state.siteInfo.defaultCurrency || '';
        const siteCode = state.siteInfo.siteCode || '';
        const device = state.isMobile ? 'WAP' : 'PC';
        const labelFileUploadImageUrlDomain = state.siteInfo.configs.fileUploadImageUrlDomain;
        const masterConfigs = state.siteInfo.masterConfigs;
        const sourceSuffix = '.png';
        const template = 'eGame';
        const gameCodeFormat = gameCode ? gameCode.replace(/\//g, '__') : gameCode;
        let source = '';

        // 根据图片类型拼接不同的图片路径
        switch (imageType) {
            case IMAGE_TYPE.GAME_TYPE:
                source = `${template}/${locale}/${device}/${gameType}`;
                break;
            case IMAGE_TYPE.GAME_TYPE_ACTIVE:
                source = `${template}/${locale}/${device}/hover-${gameType}`;
                break;
            case IMAGE_TYPE.GAME_CHANNEL:
                source = `${template}/${locale}/${device}/${gameType}/menu-${
                    gameCode ? gameCode : channelCode
                }`;
                break;
            case IMAGE_TYPE.GAME_CHANNEL_SUB:
                source = `${template}/${locale}/${device}/${gameType}/left-${channelCode}`;
                break;
            case IMAGE_TYPE.GAME_BRAND_IMAGE:
                source = `${template}/${locale}/${device}/icon/${channelCode}`;
                break;
            case IMAGE_TYPE.GAME_TYPE_BACKGROUND:
                source = `${template}/${locale}/WAP/${gameType}/background`;
                break;
            case IMAGE_TYPE.VENUE_CHARACTERS:
                source = `${template}/${locale}/WAP/${gameType}/figu-${channelCode}`;
                break;
            case IMAGE_TYPE.FOOTER_SINGLE_CHANNEL:
                source = `${template}/${locale}/${device}/${gameType}/${channelCode}`;
                break;
            case IMAGE_TYPE.GAME_CHANNEL_COLLECTION:
                source = `${template}/${locale}/${device}/${gameType}/collect-${channelCode}`;
                break;
            case IMAGE_TYPE.MENU_GAME:
                source = `${template}/${locale}/WAP/${gameType}/icon-${channelCode}`;
                break;
            case IMAGE_TYPE.GAME:
                source = `${template}/${locale}/${device}/${gameType}/${channelCode}/${gameCodeFormat}`;
                break;
            case IMAGE_TYPE.GAME_LABEL:
                source = `${siteCode}/${currency}/${device}/label/${labelCode}`;
                break;
            case IMAGE_TYPE.GAME_LABEL_ACTIVE:
                source = `${siteCode}/${currency}/${device}/label/hover-${labelCode}`;
                break;
            case IMAGE_TYPE.GAME_MENU:
                source = `${siteCode}/${currency}/${device}/menu/${labelCode}`;
                break;
            case IMAGE_TYPE.GAME_MENU_ACTIVE:
                source = `${siteCode}/${currency}/${device}/menu/hover-${labelCode}`;
                break;
            case IMAGE_TYPE.SITE_GAME:
                source = `${siteCode}/${currency}/${locale}/${device}/${labelCode}/${gameType}/${channelCode}/${gameCode}`;
                break;
            case IMAGE_TYPE.FOOTER_LOGO:
                // {模板}/{语言}/{客户端}/{游戏大类}/logo-场馆code.png
                source = `${template}/${locale}/${device}/icon/${channelCode}`;
                break;
            case IMAGE_TYPE.RECORD_DEPOSIT:
                source = `${template}/user/record/recharge/${rechargeFundsType}`;
                break;
            case IMAGE_TYPE.RECORD_WITHDRAW:
                source = `${template}/user/record/withdraw/${rechargeFundsType}`;
                break;
            case IMAGE_TYPE.RECORD_TRANSFER:
                source = `${template}/user/record/transfer/default`;
                break;
            case IMAGE_TYPE.RECORD_RECHARGE:
                source = `${template}/user/record/golden/default`;
                break;
            case IMAGE_TYPE.RECORD_REBATE:
                source = `${template}/user/record/rebate/default`;
                break;
            case IMAGE_TYPE.RECORD_OTHER:
                source = `${template}/user/record/other/default`;
                break;
            case IMAGE_TYPE.RECORD_GAME_CONVERT:
                source = `${template}/user/record/gameConvert/default`;
                break;
            case IMAGE_TYPE.GAME_COVER:
                source = `${template}/${locale}/${device}/${gameType}/${channelCode}/${gameCodeFormat}`;
                break;
        }

        // 游戏标签和首页导航采用不同的图片路径前缀
        let prefix = '';
        prefix =
            imageType.includes(IMAGE_TYPE.GAME_LABEL) || imageType.includes(IMAGE_TYPE.GAME_MENU)
                ? labelFileUploadImageUrlDomain
                : masterConfigs.fileUploadImageUrlDomain;
        return prefix + source + sourceSuffix;
    } catch (e) {
        // alert(e)
    }
};
