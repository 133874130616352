import { configureStore, Reducer } from '@reduxjs/toolkit';
import baseApi from '@/app/services/base';
import personalApi from '@/app/services/personal';
import userApi from '@/app/services/user';
import siteApi from '@/app/services/site';
import baseReducer from '@/app/slice/baseSlice';
import userReducer from '@/app/slice/userSlice';
import footerReducer from '@/app/slice/footerSlice';
import newsSliceReducer from '@/pages/news/newsSlice';
import api from './api';
import socketMessageSlice from './slice/socketMessageSlice';
import activitySlice from './slice/activitySlice';

type ReducersMap<T> = {
    [K in keyof T]: Reducer<T[K]>;
};

type AsyncInject = { [prop: string]: Reducer };

const staticReducers = {
    base: baseReducer,
    user: userReducer,
    footer: footerReducer,
    news: newsSliceReducer,
    socketMessageSlice: socketMessageSlice,
    activitySlice: activitySlice,
    [baseApi.reducerPath]: baseApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [personalApi.reducerPath]: personalApi.reducer,
    [siteApi.reducerPath]: siteApi.reducer
};

export const store = configureStore({
    reducer: staticReducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
    devTools: process.env.NODE_ENV !== 'production'
});

const customCombineReducers = <T>(reducers: ReducersMap<T>): Reducer<T> => {
    return (state, action) => {
        const nextState: Partial<T> = {};
        for (const key in reducers) {
            if (Object.prototype.hasOwnProperty.call(reducers, key)) {
                const reducerKey = key as keyof T;
                nextState[reducerKey] = reducers[reducerKey](state && state[reducerKey], action);
            }
        }
        return nextState as T;
    };
};

export const injectAsyncReducer = (key: string, asyncReducer: Reducer): void => {
    (staticReducers as AsyncInject)[key] = asyncReducer as Reducer;
    store.replaceReducer(customCombineReducers(staticReducers));
};

export const removeAsyncReducer = (key: string) => {
    if ((staticReducers as AsyncInject)[key]) {
        delete (staticReducers as AsyncInject)[key];
        store.replaceReducer(customCombineReducers(staticReducers));
    }
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
