import fetcher from './fetcher';
import { store } from '@/app/store';
import { setSiteInfo, setDevice } from '@/app/slice/baseSlice';
import { getQueryVariable } from '@/utils/tool';

import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: 'https://4c965dab8a6e97a7950bf20d8f178766@o4508047778840576.ingest.us.sentry.io/4508047782117376',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export async function prepareApi() {
    document.body.classList.remove('body-no-scroll');
    // localStorage.removeItem('homeGameMenu');
    const { data }: { data: { languageCurrency: []; defaultLanguage: string } } = await fetcher({
        url: '/site/f/siteConfig/query',
        headers: {
            Device: 'PC',
            'content-type': 'application/json'
        },
        method: 'POST',
        data: {
            promoId: getQueryVariable('cid') || undefined
        }
    });
    store.dispatch(setSiteInfo(data));
    store.dispatch(setDevice(isMobileDevice()));
}

function isMobileDevice(): boolean {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}
