import { store } from '@/app/store';
import { setPopShowInstallApp, setMessage } from '@/app/slice/baseSlice';
export type currencySymbolProps = {
    CNY: string;
    THB: string;
    USD: string;
    VND: string;
    BRL: string;
};

export const currencySymbol: currencySymbolProps = {
    CNY: '¥',
    THB: '฿',
    USD: '$',
    VND: '₫',
    BRL: 'R$'
};

export const getQueryVariable = (variable: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(variable);
};

export function getMobileOperatingSystem(): 'iOS' | 'Android' | 'unknown' {
    const userAgent = window.navigator.userAgent;

    // 判断是否为 iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iOS';
    }

    // 判断是否为 Android
    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // 如果都不是，则返回 unknown
    return 'unknown';
}

export const isPWA =
    // @ts-ignore
    window.navigator.standalone ||
    window.matchMedia('(display-mode: standalone)').matches ||
    window.matchMedia('(display-mode: fullscreen)').matches;

// @ts-ignore
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isIOSSafari = isIOS && isSafari;

export const isAndroid = /Android/i.test(navigator.userAgent);

export const pictureImgDomain = 'https://tposs.qiddfc-dqiod52d.com/';

export const popRechargeSuccess = (msg: string) => {
    store.dispatch(setMessage({ type: 'success', message: msg }));
};

export const popShowInstallAppFn = () => {
    const localPopShowInstallApp = localStorage.getItem('localPopShowInstallApp');
    const kayaPwaCloseNum = localStorage.getItem('kayaPwaCloseNum');

    // ios 判断
    if (isIOSSafari && localPopShowInstallApp !== 'true') {
        store.dispatch(setPopShowInstallApp(true));
    }

    // 安卓判断
    if (
        isAndroid &&
        window.deferredPrompt &&
        kayaPwaCloseNum !== '10001' &&
        localPopShowInstallApp !== 'true'
    ) {
        store.dispatch(setPopShowInstallApp(true));
    }
};

export const analyticsBackFn = (type: string) => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    console.log('fbPixelId:', window.analyticsParams.fbPixelId);
    // @ts-ignore
    if (window.analyticsParams.fbPixelId) {
        switch (type) {
            case 'register':
                // @ts-ignore
                // eslint-disable-next-line no-undef
                fbq('track', 'CompleteRegistration');
                console.log('CompleteRegistration: success');
                break;
            case 'AddToCart':
                // @ts-ignore
                // eslint-disable-next-line no-undef
                fbq('track', 'AddToCart');
                console.log('AddToCart: success');
                break;
            default:
                break;
        }
    }
};
