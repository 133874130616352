import React, { FC, memo } from 'react';
import { useRoutesMeta } from '@/hooks/routesMeta';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { RootState } from '@/app/store';
import style from './header.module.css';
import { pictureImgDomain } from '@/utils/tool';
type OwnProps = {
    onBackClick?: () => void;
    children?: React.ReactNode;
};

const MobileHeader: FC<OwnProps> = ({ onBackClick, children }) => {
    const meta = useRoutesMeta();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const secondRouter = useSelector((state: RootState) => state.base.secondRouter);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const gameTypeValue = searchParams.get('gameType');
    const channelCodeValue = searchParams.get('channelCode');
    const startTimeValue = searchParams.get('startTime');
    const endTimeValue = searchParams.get('endTime');
    const dateValue = searchParams.get('date');

    const handleGoBack = async () => {
        if (onBackClick) {
            onBackClick();
            return;
        }
        if (secondRouter) {
            navigate(-3);
        } else {
            if (location.pathname === '/betRecord') {
                navigate('/personalCenter');
            } else if (location.pathname === '/betRecord/singleBetRecord') {
                navigate(
                    `/betRecord?channelCode=${channelCodeValue}&gameType=${gameTypeValue}&startTime=${startTimeValue}&endTime=${endTimeValue}&date=${dateValue}`
                );
            } else {
                navigate(-1);
            }
        }
        localStorage.setItem('lastChannelCode', '');
    };

    return (
        <header className={style.container}>
            <div className={style.containerFull}></div>
            <div className={style.mobileMenuWrap}>
                <div className={style.mobileMenu} onClick={() => handleGoBack()}>
                    <img
                        className={style.backBtn}
                        src={`${pictureImgDomain}web/orange/leftReturn.svg`}
                    />
                    <p className={style.pageTitle}>{meta.title && t(meta.title)}</p>
                </div>
                {children}
            </div>
        </header>
    );
};

export default memo(MobileHeader);
