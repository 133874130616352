import { useEffect, useState } from 'react';
import { useSendPhoneMsgMutation } from '@/app/services/site';

export function usePhoneCaptcha() {
    const [mutation, { isLoading }] = useSendPhoneMsgMutation();
    const [seconds, setSeconds] = useState(0);

    const onStart = () => {
        setSeconds(60);
    };

    const fetchData = async (param: {
        areaNumber: string;
        phoneNumber: string;
        sendType: number;
        currency: string;
    }) => {
        try {
            const result = await mutation({
                areaNumber: param.areaNumber,
                phoneNumber: param.phoneNumber,
                sendType: param.sendType, //0-手机注册 1-手机登录 2-忘记密码 3-绑定手机
                currency: param.currency
            });
            if ('data' in result) {
                onStart();
            }
        } catch (error) {
            console.error('Failed to fetch funds type config: ', error);
        }
    };

    useEffect(() => {
        if (seconds > 0) {
            const timerId = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [seconds]);

    return { fetchData, seconds, isLoading };
}
