import api from '../api';
import API_PATH from './apiPathConfig';

const userApi = api.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/login`,
                method: 'POST',
                body: data
            })
        }),
        googleLogin: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/authRegisterLogin`,
                method: 'POST',
                body: data
            })
        }),
        googleBind: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/authBind`,
                method: 'POST',
                body: data
            })
        }),
        register: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/register`,
                method: 'POST',
                body: data
            })
        }),
        refreshBalance: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberWallet/queryBalance`,
                method: 'POST',
                body: data
            })
        }),
        checkAccount: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/checkAccount`,
                method: 'POST',
                body: data
            })
        }),
        createUser: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/register`,
                method: 'POST',
                body: data
            })
        }),
        getVerifyCode: builder.mutation({
            query: () => ({
                url: `${API_PATH.member}/f/common/getVerify`,
                method: 'GET'
            })
        }),
        getPersonalInfo: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/queryInfo`,
                method: 'POST',
                body: data
            })
        }),
        updatePersonalInfo: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/update`,
                method: 'POST',
                body: data
            })
        }),
        logoutSite: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/logout`,
                method: 'POST',
                body: data
            })
        }),
        memberFundsDetail: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberFundsDetail/queryPage`,
                method: 'POST',
                body: data
            })
        }),
        verifyRealEmail: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/verifyRealEmail`,
                method: 'POST',
                body: data
            })
        }),
        pwaBind: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberClient/bind`,
                method: 'POST',
                body: data
            })
        }),
        authBind: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/authBind`,
                method: 'POST',
                body: data
            })
        }),
        setPassword: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/modifyAcctAndPwdForRegister`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const {
    useLoginMutation,
    useGoogleLoginMutation,
    useGoogleBindMutation,
    useRefreshBalanceMutation,
    useCheckAccountMutation,
    useCreateUserMutation,
    useGetVerifyCodeMutation,
    useRegisterMutation,
    useGetPersonalInfoMutation,
    useUpdatePersonalInfoMutation,
    useLogoutSiteMutation,
    useMemberFundsDetailMutation,
    useVerifyRealEmailMutation,
    usePwaBindMutation,
    useAuthBindMutation,
    useSetPasswordMutation
} = userApi;
export default userApi;
