export const PROTECT_LINK = [
    '/game/rocket',
    '/eventCenter?type=exclusive',
    '/deposit',
    '/withdraw',
    '/personalCenter',
    '/recentGames',
    '/collectGames',
    '/vipRules',
    '/agencyPromotion'
];
