import api from '../../app/api';
import API_PATH from '../../app/services/apiPathConfig';

const newsApi = api.injectEndpoints({
    endpoints: builder => ({
        // 消息
        news: builder.mutation({
            query: data => ({
                url: `${API_PATH.site}/f/memberNotice/getPage`,
                method: 'POST',
                body: data
            })
        }),
        // 消息(PC)
        newsList: builder.mutation({
            query: (data: {
                endTime?: string;
                fieldValue?: string;
                pageNumber?: number;
                pageSize?: number;
                pageStart?: number;
                sortValue?: string;
                startTime?: string;
                status?: number;
                type?: number;
            }) => ({
                url: `${API_PATH.site}/f/memberNotice/getPageAndUnReadNotice`,
                method: 'POST',
                body: data
            })
        }),
        // 領取彩金
        newsPrize: builder.mutation({
            query: data => ({
                url: `${API_PATH.site}/f/memberNotice/addReceive`,
                method: 'POST',
                body: data
            })
        }),
        // 未讀訊息數
        newsUnread: builder.mutation({
            query: data => ({
                url: `${API_PATH.site}/f/memberNotice/getUnreadNoticeTotal`,
                method: 'POST',
                body: data
            })
        }),
        // 修改狀態
        newsStatus: builder.mutation({
            query: (data: { idList: string[]; status?: number; type?: number }) => ({
                url: `${API_PATH.site}/f/memberNotice/updateNoticeStatus`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const {
    useNewsMutation,
    useNewsListMutation,
    useNewsPrizeMutation,
    useNewsUnreadMutation,
    useNewsStatusMutation
} = newsApi;

export default newsApi;
