import { RootState } from '@/app/store';
import React, { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

interface TurnstileProps {
    onVerify: (token: string) => void;
}

const Turnstile: React.FC<TurnstileProps> = ({ onVerify }) => {
    const turnstileContainerId = 'turnstile-container';
    const isTurnstileInitialized = useRef(false);
    const siteCode = useSelector((state: RootState) => state.base.siteInfo.siteCode);

    useEffect(() => {
        if (!isTurnstileInitialized.current) {
            if (window.turnstile && document.getElementById(turnstileContainerId)) {
                window.turnstile.render(`#${turnstileContainerId}`, {
                    sitekey:
                        siteCode == 'jeroa'
                            ? '0x4AAAAAAAjBLBKi2m-LYWW_'
                            : '0x4AAAAAAAj_agguBHH11OG8',
                    callback: onVerify
                });
                isTurnstileInitialized.current = true;
            }
        }

        return () => {
            // if (isTurnstileInitialized.current && window.turnstile) {
            //   window.turnstile.remove(`#${turnstileContainerId}`);
            // }
        };
    }, [onVerify]);

    return <div id={turnstileContainerId} data-theme="dark" data-size="flexible"></div>;
};

export default memo(Turnstile);
