import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RegisterDepositIcon from '../img/registerDepositIcon.svg';
import RegisterDepositGuangIcon from '../img/registerDepositGuangIcon.png';
import DiscountCenterTime from '@/components/baseDiscountTime/BaseDiscountTime';
import style from '../style/depositDiscount.module.css';
import { useNavigate } from 'react-router-dom';
import { currencySymbol } from '@/utils/tool';
import { RootState } from '@/app/store';
interface DiscountCenterTimeProps {
    showDiscountTime: {
        hours: string;
        minutes: string;
        second: string;
        rechargeCardAmount: number;
        calcType: number;
    };
}

function DepositDiscount({ showDiscountTime }: DiscountCenterTimeProps) {
    const [translateX, setTranslateX] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    useEffect(() => {
        const interval = setInterval(() => {
            setTranslateX(prev => (prev === 0 ? -50 : 0));
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className={style.registerDepositDiscountH5}>
                <div className={style.discountLeft}>
                    <img src={RegisterDepositGuangIcon} className={style.leftGuangIcon} />
                </div>

                <div className={style.discountCenter}>
                    <div
                        className={style.discountCenterWrap}
                        style={{
                            transform: `translateX(${translateX}%)`,
                            transition: 'transform 0.5s'
                        }}
                    >
                        <span className={style.discountCenterTitle}>
                            {t('deposit_recharge_discount').toUpperCase()}
                        </span>
                        <DiscountCenterTime showDiscountTime={showDiscountTime} />
                    </div>
                </div>

                <div className={style.discountRight} onClick={() => navigate('/deposit')}>
                    <img src={RegisterDepositIcon} />
                    <span>{t('deposit_deposit')}</span>
                </div>
            </div>
            <div className={style.registerDepositDiscountPc}>
                <div className={style.discountLeft}>
                    <img src={RegisterDepositGuangIcon} className={style.leftGuangIcon} />
                </div>

                <div className={style.discountLeftMoney}>
                    <span>{showDiscountTime.rechargeCardAmount} </span>
                    <span>
                        {showDiscountTime.calcType === 0
                            ? `${currencySymbol[userInfo.currency]}`
                            : '%'}
                    </span>
                </div>

                <div className={style.discountCenter}>
                    <div className={style.discountCenterTitle}>
                        <span>{t('deposit_new_user').toUpperCase()}</span>
                        <span>{t('deposit_recharge_discount').toUpperCase()}</span>
                    </div>
                    <DiscountCenterTime showDiscountTime={showDiscountTime} />
                </div>

                <div className={style.discountRight} onClick={() => navigate('/deposit')}>
                    <img src={RegisterDepositIcon} />
                    <span>{t('deposit_deposit')}</span>
                </div>
            </div>
        </>
    );
}

export default DepositDiscount;
