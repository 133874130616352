import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from './store';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { store } from '@/app/store';
import { setMessage } from '@/app/slice/baseSlice';
import { setLogout } from '@/app/slice/userSlice';

type MyResponseType = {
    code: string;
    data: unknown;
};

// 自定义报错的path不走拦截器报错 走页面自定义方法报错
const CustomizeErrorPath1 = 'activity/f/activity/task';
const CustomizeErrorPath2 = 'member/f/memberBase/authRegisterLogin';

// 使用变量来标记是否已经处理过
let errorHandled = false;
let timerId: ReturnType<typeof setTimeout> | null = null;

const baseQuery = fetchBaseQuery({
    baseUrl: 'https://pg.app',
    prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const token = state.user.token;
        const siteCode = state.base.siteInfo.siteCode || '';
        const language = state.base.language.current?.languageCode;
        const currency = state.base.siteInfo.defaultCurrency || '';
        const userCurrency = state.user.userInfo.currency;
        const isMobile = state.base.isMobile;

        if (token) {
            headers.set('FrontAuthorization', token);
        }

        if (userCurrency) {
            headers.set('Currency', userCurrency);
        } else {
            headers.set('Currency', currency);
        }

        headers.set('Accept-Language', language);
        headers.set('SiteCode', siteCode);
        headers.set('Device', isMobile ? 'WAP' : 'PC');

        return headers;
    }
});

// @ts-ignore
const baseQueryWithInterceptor: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error) {
        const { code, msg } = result.error.data as { code: string; msg: string };

        if (
            result.meta &&
            (result.meta.request.url.indexOf(CustomizeErrorPath1) > -1 ||
                result.meta.request.url.indexOf(CustomizeErrorPath2) > -1)
        ) {
            return result.error;
        } else {
            if ((code === '03-100' || code === '01-100') && !errorHandled) {
                store.dispatch(setLogout());
                store.dispatch(setMessage({ type: 'error', message: msg }));
                errorHandled = true; // 设置标志位，表示已经处理过这个错误
                // 清除之前的定时器（如果存在），避免多个定时器并行
                if (timerId) clearTimeout(timerId);
                // 设置新的定时器，并在 2 秒后重置 errorHandled
                timerId = setTimeout(() => {
                    errorHandled = false;
                    timerId = null; // 重置 timerId
                }, 2000);
                return false;
            } else if (code === '05-992') {
                throw result.error;
            } else if (code === '04-001') {
                store.dispatch(setMessage({ type: 'error', message: msg }));
                throw msg;
            } else {
                if (!errorHandled) {
                    store.dispatch(setMessage({ type: 'error', message: msg }));
                }
                throw msg;
            }
        }
    }
    return result.data as MyResponseType;
};

const api = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseQueryWithInterceptor,
    endpoints: () => ({})
});

export default api;
