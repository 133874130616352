import api from '../api';
import API_PATH from './apiPathConfig';

const baseApi = api.injectEndpoints({
    endpoints: builder => ({
        getBaseInfo: builder.mutation({
            query(body) {
                return {
                    url: `${API_PATH.site}/f/siteConfig/query`,
                    method: 'POST',
                    headers: {
                        Device: 'PC'
                    },
                    body
                };
            }
        }),
        getDict: builder.mutation({
            query(body) {
                return {
                    url: `/api/master/f/dict/getDict`,
                    method: 'POST',
                    body
                };
            }
        }),

        getFundsTypeConfig: builder.mutation({
            query() {
                return {
                    url: `/api/master/f/fundsTypeConfig/queryAll`,
                    method: 'GET'
                };
            }
        })
    })
});

export const { useGetBaseInfoMutation, useGetDictMutation, useGetFundsTypeConfigMutation } =
    baseApi;
export default baseApi;
