import 'symbol-observable';
import '@/style/reset.css';
import '@/style/globals.css';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { store } from '@/app/store';
import { router } from '@/router/router';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { baseTheme } from '@/style/themes';
import { version } from '../package.json';
import { prepareApi } from '@/utils/prepare';
import '@/utils/rem';
import emitter from '@/utils/events';
console.info(
    `version: %c${version}`,
    'color:white;background:#ff9f19;padding: 2px 0.5em; border-radius: 10px'
);

async function init() {
    localStorage.removeItem('localPopShowInstallApp');
    await prepareApi();
    await import('@/utils/i18n');
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        // <React.StrictMode></React.StrictMode>
        <HelmetProvider>
            <Provider store={store}>
                <ThemeProvider theme={baseTheme}>
                    <RouterProvider router={router} />
                </ThemeProvider>
            </Provider>
        </HelmetProvider>
    );
}

init();

window.deferredPrompt = null;
// eslint-disable-next-line no-undef
window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
    e.preventDefault();
    window.deferredPrompt = e;
});

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then(async registration => {
                if (!registration.pushManager) {
                    console.log("Your browser doesn't support push notification.");
                    return;
                }

                // 等待 Service Worker 安装并激活
                await navigator.serviceWorker.ready;

                console.log(
                    'App is being served from cache by a service worker.\n' +
                        'For more details, visit https://goo.gl/AFskqB'
                );
                emitter.emit('serviceWorkerReady');

                // Check if there's a new service worker
                registration.addEventListener('updatefound', () => {
                    const newWorker = registration.installing;

                    if (newWorker) {
                        newWorker.addEventListener('statechange', () => {
                            // 当新的 Service Worker 已经安装但尚未激活时，显示提示
                            if (
                                newWorker.state === 'installed' &&
                                navigator.serviceWorker.controller
                            ) {
                                emitter.emit('pwaVersionShow');
                            }
                        });
                    }
                });
            })
            .catch(error => {
                console.log('Service Worker registration failed:', error);
            });
    });
}
