import type { RootState } from '@/app/store';
import { useSelector, useDispatch } from 'react-redux';
import { closeMessage } from '@/app/slice/baseSlice';
import style from './baseMessage.module.css';
import { memo, useCallback, useEffect, useState } from 'react';
import BaseMessageItem from './BaseMessageItem';

type MessageInfo = {
    trigger: number;
    message: string;
    type: string;
};

function Message() {
    const dispatch = useDispatch();
    const [count, setCount] = useState(0);
    const { isOpen, message, type, trigger } = useSelector(
        (state: RootState) => state.base.message
    );
    const [messages, setmessages] = useState<MessageInfo[]>([]);

    const itemClose = useCallback((id: number) => {
        setCount(count => count - 1);
        setmessages(prevMessages => prevMessages.filter(x => x.trigger !== id));
    }, []);

    useEffect(() => {
        if (isOpen) {
            if (count >= 5) return;
            setCount(count => count + 1);
            setmessages(prevMessages => [{ message, type, trigger }, ...prevMessages]);
            setTimeout(() => itemClose(trigger), 3500);
        }
    }, [trigger, isOpen, message, type, itemClose]);

    useEffect(() => {
        !messages.length && dispatch(closeMessage());
    }, [messages]);

    return (
        <ul className={style.notice}>
            {messages.map(x => (
                <li className={style.noticeItem} key={x.trigger}>
                    <BaseMessageItem message={x.message} type={x.type} />
                </li>
            ))}
        </ul>
    );
}

export default memo(Message);
