import NoData from '@/assets/img/noData/noData.svg';
import NoInfo from '@/assets/img/noData/noInfo.svg';
import NoSearch from '@/assets/img/noData/noSearch.svg';
import NoNetwork from '@/assets/img/noData/noNetwork.svg';
import { useTranslation } from 'react-i18next';
import style from './nodata.module.css';

function NoDataImg({ type = 'noData' }: { type?: 'noData' | 'noSearch' | 'noNetWork' | 'noInfo' }) {
    const { t } = useTranslation();

    const curImg = {
        noData: NoData,
        noSearch: NoSearch,
        noNetWork: NoNetwork,
        noInfo: NoInfo
    };

    return (
        <>
            <div className={`noDataArea ${style.noDataArea}`}>
                <img src={curImg[type]} alt="" />
                <p>{t('global_noData')}</p>
            </div>
        </>
    );
}

export default NoDataImg;
