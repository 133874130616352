import { receivedMessage } from '@/app/slice/socketMessageSlice';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

const useWebSocket = (url: string, shouldConnect: boolean) => {
    const ws = useRef<WebSocket | null>(null);
    const heartBeatInterval = useRef<ReturnType<typeof setInterval> | null>(null);

    const dispatch = useDispatch();

    const connect = () => {
        ws.current = new WebSocket(url);

        ws.current.onopen = () => {
            console.log('Connected to WebSocket');
            startHeartBeat();
        };

        ws.current.onmessage = (event: MessageEvent) => {
            const message = JSON.parse(event.data);
            dispatch(receivedMessage(message));
            // console.log('Received:', message);
            resetHeartBeat();
        };

        ws.current.onclose = event => {
            console.log('WebSocket closed', event);
            stopHeartBeat();
        };

        ws.current.onerror = error => {
            console.error('WebSocket error:', error);
        };
    };

    const startHeartBeat = () => {
        heartBeatInterval.current = setInterval(() => {
            if (ws.current?.readyState === WebSocket.OPEN) {
                ws.current.send('ping');
                // console.log('heartBeat');
            }
        }, 5000);
    };

    const resetHeartBeat = () => {
        if (heartBeatInterval.current) {
            clearInterval(heartBeatInterval.current);
            startHeartBeat();
        }
    };

    const stopHeartBeat = () => {
        if (heartBeatInterval.current) {
            clearInterval(heartBeatInterval.current);
        }
        heartBeatInterval.current = null;
    };

    useEffect(() => {
        if (shouldConnect) {
            connect();
        } else if (ws.current) {
            stopHeartBeat();
            ws.current.close();
            ws.current = null;
        }

        return () => {
            stopHeartBeat();
            ws.current?.close();
            ws.current = null;
        };
    }, [url, shouldConnect]);

    return ws.current;
};

export default useWebSocket;
