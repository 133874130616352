interface Fetcher<TData> {
    path?: string;
    url?: string;
    method?: string;
    headers?: {
        [popsName: string]: string;
    };
    data?: TData;
    timeout?: number;
}

const fetcher = <TResponse, TData>(
    { path = 'https://pg.app/api', url, method, headers, data, timeout }: Fetcher<TData>,
    option: { body?: string | null } = {}
): Promise<TResponse> => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (timeout) {
        setTimeout(() => {
            controller.abort();
        }, timeout * 1000);
    }

    if (method === 'GET' && data) {
        url += `?${new URLSearchParams(data).toString()}`;
    } else {
        option.body = data ? JSON.stringify(data) : null;
    }

    return fetch((path + url) as string, {
        method: method || 'GET',
        headers: headers || {
            'content-type': 'application/json'
        },
        signal: signal,
        ...option
    })
        .then(response => response.json())
        .then(data => data as TResponse);
};

export default fetcher;
