import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from '../style/login.module.css';
import Google from '../img/google.svg';
import { setMessage } from '@/app/slice/baseSlice';
import { setUserInfo, setLoginStatus } from '@/app/slice/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/store';
import { useGoogleLoginMutation } from '@/app/services/user';
import { LoginProps } from '../types';
import { auth, provide } from '@/utils/firebase';
import { signInWithPopup } from 'firebase/auth';
import MessageDialog from '@/components/messageDialog/MessageDialog';
import BindAccount from '@/pages/accountSecurity/bindPopup/BindAccount';

type Popup = '' | 'bindAccount';

type loginDataType = {
    code?: string;
    msg?: string;
    responseVO: {
        token: string;
        phoneNumber: string;
    };
};

type loginType = {
    data: loginDataType;
};

function GoogleLogin({ onClick, handleOpen }: LoginProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [loginGoogleAsync] = useGoogleLoginMutation();
    const inviteCode = useSelector((state: RootState) => state.base.inviteCode);
    const channelId = useSelector((state: RootState) => state.base.channelId);
    const [popup, setPopup] = useState<Popup>('');
    const [code, setCode] = useState('');
    const [bindEmail, setBindEmail] = useState(false);

    const loginGoogle = async () => {
        const googleResult = await signInWithPopup(auth, provide);
        const result = await loginGoogleAsync({
            // @ts-ignore 跳过这行检查
            code: googleResult.user.accessToken,
            thirdChannelType: 0,
            inviteCode: inviteCode ? inviteCode : undefined,
            promoId: channelId ? channelId : undefined
        });
        if ('data' in result) {
            if (result.data.code) {
                if (result.data.code == '04-004') {
                    setCode(result.data.msg);
                    setPopup('bindAccount');
                } else {
                    dispatch(setMessage({ type: 'error', message: result.data.msg }));
                }
            } else {
                login(result);
            }
        }
    };

    // @ts-ignore 跳过这行检查
    const login = (result?: loginType) => {
        // @ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { token } = result?.data?.responseVO;
        dispatch(setLoginStatus(token));
        dispatch(setUserInfo(result?.data?.responseVO));
        dispatch(setMessage({ type: 'success', message: t('global_signSuceesfully') }));
        if (!result?.data?.responseVO?.phoneNumber) {
            // setPhoneNumberPop(true);
            // @ts-ignore
            handleOpen && handleOpen(result?.data);
        } else {
            onClick();
        }
    };

    const onClose = () => {
        setPopup('');
    };

    const handleLogoutConfirm = async () => {
        setBindEmail(true);
    };

    const phoneClose = () => {
        setPopup('');
        setBindEmail(false);
        onClick();
    };

    return (
        <div>
            {/* {phoneNumberPop && <BindPopup onClose={() => phoneClose()} type={'phoneNumber'} />} */}
            <div className={style.googleLogin}>
                <div className={style.googleLoginBox} onClick={() => loginGoogle()}>
                    <img className={style.googleLoginBoxImg} src={Google} />
                </div>
            </div>
            {popup == 'bindAccount' && (
                <MessageDialog
                    type={'confirm'}
                    handleCancle={onClose}
                    handleConfirm={handleLogoutConfirm}
                    title={t('agency_whole_receive_title')}
                    message={t('account_bind_google')}
                    showCancleBtn={true}
                    confirmBtnText={t('agency_whole_receive_confirm')}
                    cancleBtnText={t('global_cancel')}
                />
            )}
            {bindEmail && <BindAccount onClose={() => phoneClose()} code={code} />}
        </div>
    );
}

export default GoogleLogin;
