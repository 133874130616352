import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import md5 from 'md5';
import ShowPasswordButton from './ShowPasswordButton';
import style from '../style/login.module.css';
import Button from '@mui/material/Button';
import LockIcon from '../img/lock.svg';
import Safety from '../img/safety.svg';
import flagUrl from '../img/flag.png';
import { setMessage, setBasePopup } from '@/app/slice/baseSlice';
import { setUserInfo, setLoginStatus, setUserAvatar } from '@/app/slice/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/store';
import { useLoginMutation, useGetVerifyCodeMutation } from '@/app/services/user';
import { loginValues, LoginProps } from '../types';
import { useNavigate } from 'react-router-dom';
import BindPopup from '@/pages/accountSecurity/bindPopup/BindPopup';
import Turnstile from '@/components/turnstile/Turnstile';
import { pictureImgDomain } from '@/utils/tool';
function Login({ onClick }: LoginProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginLoginMemberVerifyCode = useSelector(
        (state: RootState) => state.base.siteInfo.configs.loginLoginMemberVerifyCode
    );

    const forgetPasswordJumpType = useSelector(
        (state: RootState) => state.base.siteInfo.configs.forgetPasswordJumpType
    );
    const defaultCurrency = useSelector((state: RootState) => state.base.siteInfo.defaultCurrency);
    const currencys = useSelector(
        (state: RootState) =>
            state.base.siteInfo.currencys?.find(x => x.currency == defaultCurrency)?.areas || []
    );
    const regMemberVerifyCode = useSelector(
        (state: RootState) => state.base.siteInfo.configs.regMemberVerifyCode
    );
    const [machineToken, setMachineToken] = useState<string>('');
    const [curCountryCode, setCurCountryCode] = useState(currencys[0].areaCode);
    const [showPassword, setShowPassword] = useState(true);
    const [verifyCode, setVerifyCode] = useState({
        img: '',
        token: ''
    });
    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        // getValues,
        formState: { errors, isValid }
    } = useForm<loginValues>({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    const [mutateAsync, { isLoading }] = useLoginMutation();
    const [getVerifyCode, { isLoading: codeLoading }] = useGetVerifyCodeMutation();
    const [emailPop, setEmailPop] = useState(false);

    const handleLogin = handleSubmit(async params => {
        if (isLoading) return;
        const savePassword = params.password;
        params.loginMethod = 4;
        params.areaNumber = curCountryCode;
        params.password = md5(params.password);
        if (loginLoginMemberVerifyCode === 1) params.imgValidateCodeToken = verifyCode.token;
        if (regMemberVerifyCode === 3) params.imgValidateCode = machineToken;
        const result = await mutateAsync(params);
        if ('data' in result) {
            const { token, headPortrait } = result.data;
            const defaultAvatar = `${pictureImgDomain}web/orange/avatar/default.png`;
            dispatch(
                setUserAvatar(
                    headPortrait && headPortrait.includes('http') ? headPortrait : defaultAvatar
                )
            );
            dispatch(setLoginStatus(token));
            dispatch(setUserInfo(result.data));
            dispatch(setMessage({ type: 'success', message: t('global_signSuceesfully') }));
            localStorage.setItem(
                'lI',
                JSON.stringify({
                    mA: params.phoneNumber,
                    pd: savePassword
                })
            );
            onClick();
        } else {
            _getVerifyCode();
        }
    });

    const handleShowPassword = () => {
        setShowPassword(value => !value);
    };

    const _getVerifyCode = async () => {
        const result = await getVerifyCode({});
        if ('data' in result) {
            setVerifyCode(result.data);
        }
    };

    const _forgetPasswordJumpType = () => {
        dispatch(setBasePopup(''));
        if (forgetPasswordJumpType === 0) return navigate('/serviceView');

        if (forgetPasswordJumpType === 2) return setEmailPop(true);
    };

    const handleChange = (event: SelectChangeEvent) => {
        const { value } = event.target;
        setCurCountryCode(value);
    };

    // 谷歌人机验证
    const handleVerify = (token: string) => {
        setMachineToken(token);
    };

    useEffect(() => {
        if (loginLoginMemberVerifyCode === 1) _getVerifyCode();
        const loginInfo = JSON.parse(localStorage.getItem('lI') || '{}');
        if (Object.keys(loginInfo).length > 0) {
            setValue('phoneNumber', loginInfo.mA);
            setValue('password', loginInfo.pd);
            // 手动触发验证，确保 isValid 更新
            trigger(['phoneNumber', 'password']);
        }
    }, [setValue]);

    return (
        <div className={style.loginWrapper}>
            <form onSubmit={handleLogin}>
                <div className={`${style.inputBar} ${style.registerPhone}`}>
                    <Select
                        className={style.select}
                        displayEmpty
                        value={curCountryCode}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        inputProps={{ 'aria-label': 'Without label' }}
                        disabled={currencys.length === 1}
                    >
                        {currencys?.map(item => (
                            <MenuItem key={item.iconIndex} value={item.areaCode}>
                                <span
                                    className={style.countryIcon}
                                    style={{
                                        background: `url(${flagUrl})`,
                                        width: 22,
                                        height: 22,
                                        backgroundPositionX: (20 - (item.iconIndex % 20) + 1) * 22,
                                        backgroundPositionY: -(~~(item.iconIndex / 20) * 22),
                                        backgroundSize: '440px 132px'
                                    }}
                                ></span>
                                <span className={`${style.countryName} countryName`}>
                                    {item.areaCode}
                                </span>
                            </MenuItem>
                        ))}
                    </Select>
                    <input
                        {...register('phoneNumber', {
                            required: t('login_mobileEmpty'),
                            pattern: {
                                value: /^(1[1-9]|2[1-9]|3[1-5]|4[1-9]|5[1-5]|6[1-9]|7[1-9]|8[1-9]|9[1-9])9\d{8}$/,
                                message: t('login_phoneRequired')
                            }
                        })}
                        maxLength={11}
                        autoComplete="phoneNumber"
                        className={`${style.input} ${errors.phoneNumber ? 'error' : ''} ${
                            style.countryInput
                        }`}
                        placeholder={t('login_inputMobile')}
                        onInput={event => {
                            const input = event.target as HTMLInputElement;
                            input.value = input.value.replace(/[^0-9]/g, '');
                        }}
                    />
                    {errors.phoneNumber && (
                        <p className={`${style.error} ${style.errorPhoneNumber}`}>
                            {errors.phoneNumber.message}
                        </p>
                    )}
                </div>

                <div className={style.inputBar}>
                    <img src={LockIcon} className={style.inputIcon} alt="LockIcon" />
                    <input
                        {...register('password', {
                            required: t('login_passwordBlank'),
                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]{6,16}$/,
                                message: t('login_passwordRequired')
                            }
                        })}
                        maxLength={16}
                        autoComplete="current-password"
                        className={`${style.input} ${errors.password ? 'error' : ''}`}
                        placeholder={t('login_enterPassword')}
                        type={!showPassword ? 'text' : 'password'}
                    />
                    <ShowPasswordButton isShow={showPassword} change={handleShowPassword} />
                    {errors.password && <p className={style.error}>{errors.password.message}</p>}
                </div>

                {loginLoginMemberVerifyCode === 1 && (
                    <div className={style.inputBar}>
                        <img src={Safety} className={style.inputIcon} alt="Safety" />
                        <input
                            {...register('imgValidateCode', { required: true })}
                            autoComplete="imgValidateCode"
                            className={`${style.input} ${errors.imgValidateCode ? 'error' : ''}`}
                            placeholder={t('login_inputGraphics')}
                        />

                        {codeLoading ? (
                            <CircularProgress
                                size={16}
                                style={{
                                    color: '#fff',
                                    position: 'absolute',
                                    top: '18px',
                                    right: '20px'
                                }}
                            />
                        ) : (
                            <img
                                src={verifyCode.img}
                                className={style.verifyCodeImg}
                                onClick={_getVerifyCode}
                            />
                        )}
                    </div>
                )}
                {regMemberVerifyCode == 3 && <Turnstile onVerify={handleVerify} />}
                <div className={style.loginBtnGroup}>
                    <Button
                        className={style.submit}
                        fullWidth
                        variant="contained"
                        type="submit"
                        style={{
                            opacity: isValid ? 1 : 0.5
                        }}
                    >
                        {t('header_logIn')}
                        {isLoading && (
                            <CircularProgress
                                size={16}
                                style={{
                                    color: '#fff',
                                    marginLeft: '10px'
                                }}
                            />
                        )}
                    </Button>
                </div>

                <div className={style.forgetPassword} onClick={_forgetPasswordJumpType}>
                    {t('login_forgetPassword')}
                </div>
            </form>

            {emailPop && <BindPopup onClose={() => setEmailPop(false)} type={'email'} />}
        </div>
    );
}

export default Login;
