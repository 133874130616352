import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import Button from '@mui/material/Button';
import style from './HeaderRightWeb.module.css';
import { useTranslation } from 'react-i18next';
import { currencySymbol } from '@/utils/tool';

interface HeaderProps {
    setAccount: (value: boolean) => void;
    account: boolean;
}

function HeaderRightWeb({ setAccount, account }: HeaderProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const userAvatar = useSelector((state: RootState) => state.user.userAvatar);
    const location = useLocation();
    const openDeposit = () => {
        navigate('/deposit');
    };

    const switchAccount = () => {
        setAccount(!account);
    };

    return (
        <>
            {location.pathname !== '/gameInfo' && (
                <div className={style.peopleWrap}>
                    <div className={style.balance}>
                        <span className={style.currency}>{currencySymbol[userInfo.currency]} </span>
                        <span>{userInfo.balance}</span>
                    </div>
                </div>
            )}
            <Button variant="contained" className={style.btn} onClick={openDeposit}>
                {t('personal_deposit')}
            </Button>

            <div className={style.people} onMouseEnter={switchAccount}>
                <img src={userAvatar} alt="avatarImg" onClick={switchAccount} />
            </div>
        </>
    );
}

export default HeaderRightWeb;
