import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// 把你的firebase app 設定檔 貼上來
const firebaseConfig = {
    apiKey: 'AIzaSyDJxFdTx5BUxQ43lfygd6qqUDhaucDZcMQ',
    authDomain: 'pg--login-97af5.firebaseapp.com',
    projectId: 'pg--login-97af5',
    storageBucket: 'pg--login-97af5.appspot.com',
    messagingSenderId: '21542432875',
    appId: '1:21542432875:web:4689d1ecda61031036012e',
    measurementId: 'G-W1YP3T1E1P'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const provide = new GoogleAuthProvider();
