import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { BaseState, Language, BasePopupType, LoginDialogType, Dict } from '@/types/base';
import { PromotionalBtn } from '@/pages/eventCenter/promotionalEvent/type';
import { ApiActiveBtnList } from '@/pages/eventCenter/exclusiveEvent/type';

// 初始化状态
const initialState: BaseState = {
    pageLoading: true,
    basePopup: '',
    loginDialogType: 'login',
    language: {
        current: {
            languageCode: localStorage.getItem('lang') || ''
        },
        list: []
    },
    message: {
        isOpen: false,
        message: '',
        type: 'info',
        trigger: Date.now()
    },
    siteInfo: {
        masterConfigs: {
            fileUploadImageUrlDomain: '',
            miPayAppDownUrl: '',
            phoneSmsRegister: ''
        },
        configs: {
            fileUploadImageUrlDomain: '',
            loginLoginMemberVerifyCode: 0,
            regMemberVerifyCode: 0,
            regInviteCode: 0,
            loginSupportCountryCode: [],
            adDomain: '',
            baseTitle: '',
            basePcLogo: '',
            baseWebLogo: '',
            serviceUrl: '',
            serviceClient: [],
            emailLoginAndRegistMode: 0,
            baseAppDownloadUrl: '',
            forgetPasswordJumpType: 2,
            baseFaviconico: '',
            wholeProxyAdImagePc: '',
            wholeProxyAdImageWap: ''
        },
        sitePageAgreementList: [],
        siteThird: []
    },
    isMobile: false,
    mobileTitle: '',
    globalDict: JSON.parse(localStorage.getItem('globalSiteDict') as string) || {},
    dict: {},
    homeGameMenu: JSON.parse(localStorage.getItem('homeGameMenu') as string) || [],
    bannerList: [],
    marqueeList: [],
    popList: [],
    sideList: [],
    promotionalEventList: [],
    exclusiveEventList: [],
    secondRouter: false,
    inviteCode: localStorage.getItem('inviteCode') || '',
    channelId: localStorage.getItem('channelId') || '',
    homeGameTab: localStorage.getItem('homeGameTab') || '',
    timeZone: localStorage.getItem('timeZone') || '-3',
    popShowInstallApp: false, // 引导安装app
    popShowRouletteBooster: false, // 轮盘助力
    popShowHomePop: false, // 弹窗公告
    globalActivityList: [], // 全局站点活动
    aboutUs: {
        homeInfo: [],
        helpCenter: []
    }
};

export const baseSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        setPageLoading: (state, action: PayloadAction<boolean>) => {
            state.pageLoading = action.payload;
        },
        setMessage: (state, action) => {
            state.message = { ...action.payload, isOpen: true, trigger: Date.now() };
        },
        closeMessage: state => {
            state.message.isOpen = false;
        },
        setCurrentLang: (state, action) => {
            localStorage.setItem('lastLang', state.language.current.languageCode);
            state.language.current = action.payload;
            localStorage.removeItem('globalSiteDict');
            localStorage.setItem('lang', action.payload.languageCode);
            window.location.reload();
        },
        setBasePopup: (state, action: PayloadAction<BasePopupType>) => {
            state.basePopup = action.payload;
        },
        setSiteInfo: (state, action) => {
            state.siteInfo = action.payload;
            state.language.list = action.payload.supportLanguages;
            const langName = state.language.current.languageCode;
            const { defaultTimeZone, defaultLanguage, supportLanguages } = action.payload;

            const lang = langName === '' ? defaultLanguage : langName;

            const targetIndex = supportLanguages.findIndex(
                (list: Language) => list.languageCode === lang
            );
            state.language.current = supportLanguages[targetIndex];
            state.timeZone = defaultTimeZone;
            localStorage.setItem('timeZone', defaultTimeZone);
        },
        setLoginDialogType: (state, action: PayloadAction<LoginDialogType>) => {
            state.loginDialogType = action.payload;
        },
        setDevice: (state, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload;
        },
        setMobileTitle: (state, action: PayloadAction<string>) => {
            state.mobileTitle = action.payload;
        },
        setGlobalDict: (state, action: PayloadAction<Dict>) => {
            state.globalDict = action.payload;
            localStorage.setItem('globalSiteDict', JSON.stringify(action.payload));
        },
        setDict: (state, action: PayloadAction<Dict>) => {
            state.dict = action.payload;
        },
        setSecondRouter: (state, action) => {
            state.secondRouter = action.payload;
        },
        setHomeGameMenu: (state, action) => {
            if (action.payload) {
                state.homeGameMenu = action.payload;
                const initGameType = action.payload?.[0]?.gameType;
                localStorage.setItem('homeGameMenu', JSON.stringify(action.payload));
                if (state.homeGameTab === '') {
                    state.homeGameTab = initGameType;
                    localStorage.setItem('homeGameTab', initGameType);
                }
            }
        },
        setBannerList: (state, action) => {
            state.bannerList = action.payload;
        },
        setMarqueeList: (state, action) => {
            state.marqueeList = action.payload;
        },
        setPopList: (state, action) => {
            state.popList = action.payload;
        },
        setSideList: (state, action) => {
            state.sideList = action.payload;
        },
        setPromotionalEventList: (state, action: PayloadAction<PromotionalBtn[]>) => {
            state.promotionalEventList = action.payload;
        },
        setExclusiveEventList: (state, action: PayloadAction<ApiActiveBtnList[]>) => {
            state.exclusiveEventList = action.payload;
        },
        setInviteCode: (state, action) => {
            state.inviteCode = action.payload;
            localStorage.setItem('inviteCode', action.payload);
        },
        setChannelId: (state, action) => {
            state.channelId = action.payload;
            localStorage.setItem('channelId', action.payload);
        },
        setHomeGameTab: (state, action) => {
            state.homeGameTab = action.payload;
            localStorage.setItem('homeGameTab', action.payload);
        },
        setPopShowHomePop: (state, action) => {
            const now = new Date().getTime();
            localStorage.setItem(
                'localPopShowHomePop',
                JSON.stringify({ value: 'true', timestamp: now })
            );
            state.popShowHomePop = action.payload;
        },
        setGlobalActivityList: (state, action) => {
            state.globalActivityList = action.payload;
        },
        setPopShowInstallApp: (state, action) => {
            localStorage.setItem('localPopShowInstallApp', 'true');
            state.popShowInstallApp = action.payload;
        },
        setPopShowRouletteBooster: (state, action) => {
            const now = new Date().getTime();
            localStorage.setItem(
                'localRouletteBooster',
                JSON.stringify({ value: 'true', timestamp: now })
            );
            state.popShowRouletteBooster = action.payload;
        },
        setAboutUs: (state, action) => {
            state.aboutUs = action.payload;
        }
    }
});

export const {
    setPageLoading,
    setMessage,
    closeMessage,
    setCurrentLang,
    setSiteInfo,
    setBasePopup,
    setLoginDialogType,
    setDevice,
    setMobileTitle,
    setGlobalDict,
    setDict,
    setHomeGameMenu,
    setBannerList,
    setMarqueeList,
    setPopList,
    setSideList,
    setPromotionalEventList,
    setExclusiveEventList,
    setSecondRouter,
    setInviteCode,
    setChannelId,
    setHomeGameTab,
    setPopShowHomePop,
    setGlobalActivityList,
    setPopShowInstallApp,
    setPopShowRouletteBooster,
    setAboutUs
} = baseSlice.actions;

export default baseSlice.reducer;
