import { useEffect, useState } from 'react';
import { useSendEmailMsgMutation } from '@/app/services/site';

export function useEmailCaptcha() {
    const [mutation, { isLoading }] = useSendEmailMsgMutation();
    const [seconds, setSeconds] = useState(0);

    const onStart = () => {
        setSeconds(60);
    };

    const fetchData = async (email: string) => {
        try {
            const result = await mutation({
                purpose: '2',
                email: email
            });
            if ('data' in result) {
                onStart();
            }
        } catch (error) {
            console.error('Failed to fetch funds type config: ', error);
        }
    };

    useEffect(() => {
        if (seconds > 0) {
            const timerId = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [seconds]);

    return { fetchData, seconds, isLoading };
}
