import { memo, useEffect, useState, useRef } from 'react';
import style from './baseMessage.module.css';
import { CircularProgress } from '@mui/material';
import SuccessIcon from '@/assets/img/check-circle-fill.svg';
import ErrorIcon from '@/assets/img/close-circle-fill.svg';
type Props = {
    message: string;
    type: string;
};
function BaseMessageItem(props: Props) {
    const [progress, setProgress] = useState(100);
    const domRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const interval = 100;
        const duration = 3000;
        const decrement = 110 / (duration / interval);
        if (domRef.current) {
            const parent = domRef.current?.parentElement;
            setTimeout(() => {
                parent && (parent.style.height = '2.9375rem');
            }, 50);
            setTimeout(() => {
                // parent && (parent.style.height = '0');
                domRef.current && (domRef.current.style.transform = 'translateY(-2.9375rem)');
            }, duration);
        }

        const timer = setInterval(() => {
            setProgress(prevCount => {
                if (prevCount <= 0) {
                    clearInterval(timer);
                    return 0;
                }
                return prevCount - decrement;
            });
        }, interval);
        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <div className={`${style.message}`} ref={domRef}>
            <span>
                {props.type == 'success' && (
                    <img src={SuccessIcon} className={style.statusIcon} alt="" />
                )}
                {props.type == 'error' && (
                    <img src={ErrorIcon} className={style.statusIcon} alt="" />
                )}
            </span>
            <span className={style.content}>{props.message}</span>
            <span className={style.progressbox}>
                <CircularProgress
                    size={16}
                    thickness={8}
                    color="inherit"
                    variant="determinate"
                    value={progress}
                />
            </span>
        </div>
    );
}

export default memo(BaseMessageItem);
