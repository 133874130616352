import { useEffect, lazy, Suspense } from 'react';
// import { AnimatePresence, motion } from 'framer-motion';
import { createBrowserRouter, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { ReactElement } from 'react';
import BaseLayout from '@/layout/BaseLayout';
import ErrorPage from '@/pages/errorPage/ErrorPage';
import Home from '@/pages/home/Home';

const HelpCenter = lazy(() => import('@/pages/help/Help'));
const VipRules = lazy(() => import('@/pages/vipRules/VipRules'));
const BetRecord = lazy(() => import('@/pages/betRecord/BetRecord'));
const SingleBetRecord = lazy(() => import('@/pages/betRecord/singleBetGame/SingleBetRecord'));
const InviteFriends = lazy(() => import('@/pages/inviteFriends/InviteFriends'));
const BindEmail = lazy(() => import('@/pages/bindEmail/BindEmail'));
const AgencyPromotion = lazy(() => import('@/pages/agencyPromotion/components/AgencyPromotionAll'));
const AgencyWholePromotion = lazy(() => import('@/pages/agencyWholePromotion'));
const AgencyForAllCommissionDetail = lazy(
    () => import('@/pages/agencyWholePromotion/components/AgencyCommissionDetail')
);
const AgencyWholeHomeHistory = lazy(
    () => import('@/pages/agencyWholePromotion/components/AgencyHomeHistory')
);
const AgentDetails = lazy(() => import('@/pages/agencyPromotion/components/AgentDetails'));
const MyCommission = lazy(() => import('@/pages/agencyPromotion/components/MyCommission'));
const AccountSecurity = lazy(() => import('@/pages/accountSecurity/AccountSecurity'));
const WinHistory = lazy(() => import('@/pages/eventCenter/dailyTask/winHistory/WinHistory'));
const TradeRecord = lazy(() => import('@/pages/tradeRecord'));
const PromotionalDetail = lazy(
    () => import('@/pages/eventCenter/promotionalEvent/PromotionalDetail')
);
const ActiveDetail = lazy(
    () => import('@/pages/eventCenter/exclusiveEvent/activeEvent/detail/ActiveDetail')
);
const EventCenter = lazy(() => import('@/pages/eventCenter/EventCenter'));
const Deposit = lazy(() => import('@/pages/deposit'));
const Withdraw = lazy(() => import('@/pages/withdraw'));
const SingleGame = lazy(() => import('@/pages/singleGame/SingleGame'));
const PersonalCenter = lazy(() => import('@/pages/personalCenter'));
const PersonalVip = lazy(() => import('@/pages/personalVip/PersonalVip'));
const Information = lazy(() => import('@/pages/personalCenter/information'));
const NomaleEventDetail = lazy(
    () => import('@/pages/eventCenter/exclusiveEvent/nomaleEvent/detail/NomaleEventDetail')
);
const RecentGames = lazy(() => import('@/pages/recentGames/RecentGames'));
const CollectGames = lazy(() => import('@/pages/collectGames/CollectGames'));
const Feedback = lazy(() => import('@/pages/help/Feedback'));
const FeedbackRecord = lazy(() => import('@/pages/help/FeedbackRecord'));
const FeedbackContent = lazy(() => import('@/pages/help/FeedbackContent'));
const AboutUs = lazy(() => import('@/pages/aboutUs/AboutUs'));
const Privacy = lazy(() => import('@/pages/aboutUs/Privacy'));
const DownloadApp = lazy(() => import('@/pages/downloadApp/downloadApp'));
const News = lazy(() => import('@/pages/news/News'));
const GameInfo = lazy(() => import('@/pages/gameInfo/GameInfo'));
const GameSearch = lazy(() => import('@/pages/gameSearch/GameSearch'));
const SiteNotice = lazy(() => import('@/pages/siteNotice/SiteNotice'));
const ServiceView = lazy(() => import('@/pages/serviceView'));
const InviteFriendsDetail = lazy(() => import('@/pages/inviteFriendsHistory'));
const TurnTablue = lazy(() => import('@/pages/turnTable'));
const Roulette = lazy(() => import('@/pages/roulette'));
const RegisterSuccessful = lazy(
    () => import('@/components/userDialog/components/RegisterSuccessful')
);

function WithAnimation({ children }: { children: ReactElement }) {
    const location = useLocation();
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [location]);
    return <Suspense fallback={<div></div>}>{children}</Suspense>;
}

function ProtectedRoute({ children }: { children: ReactElement }) {
    const isLoggedIn = useSelector((state: RootState) => state.user.isLogin);
    if (isLoggedIn) {
        return children;
    } else {
        return <Navigate to="/" />;
    }
}

function RedirectToHome() {
    return <Navigate to="/" />;
}

export const routes = [
    {
        path: '/',
        element: <BaseLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: (
                    <WithAnimation>
                        <Home />
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    noNeedFooter: false,
                    title: 'home_home'
                }
            },
            {
                path: '/recentGames',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <RecentGames />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'header_recent'
                }
            },
            {
                path: '/collectGames',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <CollectGames />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'global_favorite'
                }
            },
            {
                path: '/singleGame',
                element: (
                    <WithAnimation>
                        <SingleGame />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'router_game'
                }
            },
            {
                path: '/vipRules',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <VipRules />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    noNeedFooter: false,
                    title: 'router_vip'
                }
            },
            {
                path: '/personalCenter',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <PersonalCenter />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    noNeedFooter: false,
                    title: 'router_poepleCenter'
                }
            },
            {
                path: '/personal/vip',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <PersonalVip />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'router_vip'
                }
            },
            {
                path: '/personalCenter/information',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <Information />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'personal_info'
                }
            },
            {
                path: '/deposit',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <Deposit />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    noNeedFooter: false,
                    title: 'personal_deposit'
                }
            },
            {
                path: '/withdraw',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <Withdraw />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    noNeedFooter: false,
                    title: 'personal_withDraw'
                }
            },
            {
                path: '/eventCenter',
                element: (
                    <WithAnimation>
                        <EventCenter />
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    noNeedFooter: false,
                    title: 'router_atividade'
                }
            },
            {
                path: '/eventCenter/promotionalDetail',
                element: (
                    <WithAnimation>
                        <PromotionalDetail />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'router_detalhes'
                }
            },
            {
                path: `/eventCenter/detail`,
                element: (
                    <WithAnimation>
                        <NomaleEventDetail />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'router_detalhes'
                }
            },
            {
                path: '/winHistory',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <WinHistory />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'eventCenter_lotteryHistory'
                }
            },
            {
                path: '/betRecord',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <BetRecord />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'betRecord_info'
                }
            },
            {
                path: '/betRecord/singleBetRecord',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <SingleBetRecord />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'betRecord_info'
                }
            },
            {
                path: '/accountSecurity',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <AccountSecurity />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    headerChildren: true,
                    title: 'header_accountSecurity'
                }
            },
            {
                path: '/tradeRecord',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <TradeRecord />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'header_transactionHistory'
                }
            },
            {
                path: '/helpCenter',
                element: (
                    <WithAnimation>
                        <HelpCenter />
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    noNeedHeader: true,
                    noNeedFooter: true,
                    title: 'help_title'
                }
            },
            {
                path: '/feedback',
                element: (
                    <WithAnimation>
                        <Feedback />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    headerChildren: true,
                    title: 'help_feeBack'
                }
            },
            {
                path: '/feedback/record',
                element: (
                    <WithAnimation>
                        <FeedbackRecord />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'help_feedbackHistory'
                }
            },
            {
                path: '/feedback/content',
                element: (
                    <WithAnimation>
                        <FeedbackContent />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'help_feedback'
                }
            },
            {
                path: '/aboutUs',
                element: (
                    <WithAnimation>
                        <AboutUs />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'header_aboutUs'
                }
            },
            {
                path: '/news',
                element: (
                    <WithAnimation>
                        <News />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    headerChildren: true,
                    title: 'news_news'
                }
            },
            {
                path: '/aboutUs/privacy',
                element: (
                    <WithAnimation>
                        <Privacy />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'help_privacidade'
                }
            },
            {
                path: '/activeDetail',
                element: (
                    <WithAnimation>
                        <ActiveDetail />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'eventCenter_exclusive'
                }
            },
            {
                path: '/downloadApp',
                element: (
                    <WithAnimation>
                        <DownloadApp />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'header_download'
                }
            },
            {
                path: '/gameInfo',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <GameInfo />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    noNeedFooter: true,
                    title: 'router_newGame'
                }
            },
            {
                path: '/gameSearch',
                element: (
                    <WithAnimation>
                        <GameSearch />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    headerChildren: true,
                    title: ''
                }
            },
            {
                path: '/siteNotice',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <SiteNotice />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'siteNotice_announcement'
                }
            },
            {
                path: '/inviteFriends',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <InviteFriends />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: false,
                    headerChildren: true,
                    title: 'router_goodFriend'
                }
            },
            {
                path: '/inviteFriendsHistory',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <InviteFriendsDetail />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: false,
                    headerChildren: true,
                    title: 'router_goodFriend'
                }
            },
            {
                path: '/bindEmail',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <BindEmail />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    noNeedFooter: true,
                    title: 'accountSecurity_bindEmail'
                }
            },
            {
                path: '/agencyPromotion',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <AgencyPromotion />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    headerChildren: true,
                    title: 'agente_promocional'
                }
            },
            {
                path: '/agencyWholePromotion',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <AgencyWholePromotion />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    title: 'agency_whole_header_title'
                }
            },
            {
                path: '/agentCommDetails',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <AgencyForAllCommissionDetail />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    title: 'agency_whole_comm_details'
                }
            },
            {
                path: '/agencyForAllHomeHistory',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <AgencyWholeHomeHistory />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    title: 'agency_my_agent_details'
                }
            },
            {
                path: '/agentDetails',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <AgentDetails />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    title: 'agent_my_agent_details'
                }
            },
            {
                path: '/myCommission',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <MyCommission />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    title: 'agent_my_commission'
                }
            },
            {
                path: '/serviceView',
                element: (
                    <WithAnimation>
                        <ServiceView />
                    </WithAnimation>
                ),
                meta: {
                    isBack: true,
                    title: 'footer_onlineCustomerService'
                }
            },
            {
                path: '/turnTable',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <TurnTablue />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    headerChildren: true,
                    isBack: true,
                    noNeedFooter: true,
                    title: 'roulette_title'
                }
            },
            {
                path: '/roulette',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <Roulette />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    headerChildren: true,
                    isBack: true,
                    noNeedFooter: true,
                    title: 'roulette_title'
                }
            },
            {
                path: '/registerSuccessful',
                element: (
                    <WithAnimation>
                        <ProtectedRoute>
                            <RegisterSuccessful />
                        </ProtectedRoute>
                    </WithAnimation>
                ),
                meta: {
                    isBack: false,
                    noNeedHeader: true,
                    noNeedFooter: true,
                    title: ''
                }
            }
        ]
    },
    {
        path: '/*',
        element: <RedirectToHome />,
        children: [
            {
                path: '/*',
                element: <RedirectToHome />,
                meta: {
                    isBack: false,
                    noNeedFooter: false,
                    title: 'home_home'
                }
            }
        ]
    }
];

export const router = createBrowserRouter(routes);
