import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ShowPasswordButton from '../components/ShowPasswordButton';
import style from '../style/login.module.css';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import LockIcon from '@mui/icons-material/Lock';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch } from 'react-redux';
import { setLoginDialogType } from '@/app/slice/baseSlice';
import { useTranslation } from 'react-i18next';

type ForgetPasswordForm = {
    validationCode: number;
    phone: string;
    password: string;
    confirmPassword: string;
};

function ForgetPassword() {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
        setShowPassword(value => !value);
    };
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(value => !value);
    };

    const dispatch = useDispatch();

    const {
        register,
        formState: { errors }
    } = useForm<ForgetPasswordForm>({});

    const handleNagative = () => {
        dispatch(setLoginDialogType('login'));
    };

    return (
        <>
            <div className={style.dialogBody}>
                <div className={`${style.backGroup} ${style.mb16}`}>
                    <IconButton aria-label="delete">
                        <ArrowBackIosIcon fontSize="small" onClick={handleNagative} />
                    </IconButton>
                    <p>忘记密码</p>
                </div>
                <form>
                    <div className={`${style.inputBar} ${style.mb16}`}>
                        <PhoneIphoneIcon className={style.inputIcon} fontSize="small" />
                        <input
                            {...register('phone', {
                                required: t('global_required'),
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: t('global_mailError')
                                }
                            })}
                            className={`${style.input} ${errors.phone ? 'error' : ''}`}
                            placeholder={t('signin_ enterAndMailAccount')}
                        />
                    </div>
                    <div className={`${style.inputBar} ${style.mb16}`}>
                        <MarkAsUnreadIcon className={style.inputIcon} fontSize="small" />
                        <Button
                            className={style.getCodeBtn}
                            variant="outlined"
                            // onClick={handleVerificationCode}
                        >
                            获取验证码
                        </Button>
                        <input
                            {...register('validationCode', {
                                required: t('global_required')
                            })}
                            className={`${style.input} ${errors.validationCode ? 'error' : ''}`}
                            placeholder={t('signin_validationCode')}
                        />
                    </div>
                    <div className={`${style.inputBar} ${style.mb16}`}>
                        <LockIcon className={style.inputIcon} fontSize="small" />
                        <input
                            {...register('password', {
                                required: t('global_required')
                            })}
                            autoComplete="current-password"
                            className={`${style.input} ${errors.password ? 'error' : ''}`}
                            placeholder={t('signin_enterPassword')}
                            type={showPassword ? 'text' : 'password'}
                        />
                        <ShowPasswordButton isShow={showPassword} change={handleShowPassword} />
                    </div>
                    <div className={`${style.inputBar} ${style.mb16}`}>
                        <LockIcon className={style.inputIcon} fontSize="small" />
                        <input
                            {...register('confirmPassword', {
                                required: t('global_required'),
                                validate: (value, formValues) => {
                                    return value === formValues.password || t('signin_reEnter');
                                }
                            })}
                            autoComplete="current-password"
                            className={`${style.input} ${errors.confirmPassword ? 'error' : ''}`}
                            placeholder={t('signin_reEnter')}
                            type={showConfirmPassword ? 'text' : 'password'}
                        />
                        <p className={style.errorMessage}>
                            {errors.confirmPassword && (
                                <span role="alert">{errors.confirmPassword.message}</span>
                            )}
                        </p>
                        <ShowPasswordButton
                            isShow={showConfirmPassword}
                            change={handleShowConfirmPassword}
                        />
                    </div>
                    <Button className={style.submit} fullWidth variant="contained" type="submit">
                        确认绑定
                    </Button>
                </form>
            </div>
        </>
    );
}
export default ForgetPassword;
