import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import style from './HeaderRight.module.css';
import Search from '@/assets/img/search.svg';
import { currencySymbol } from '@/utils/tool';
import DepositIcon from './img/depositIcon.png';
import Deposit from './img/deposit.png';
import Withdraw from './img/withdraw.png';

interface HeaderProps {
    setAccount: (value: boolean) => void;
    account: boolean;
}

function HeaderRight({ setAccount, account }: HeaderProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const userAvatar = useSelector((state: RootState) => state.user.userAvatar);
    const switchAccount = () => {
        setAccount(!account);
    };

    const searchClick = () => {
        navigate('/gameSearch');
    };

    return (
        <div className={style.headerRightWrap}>
            {location.pathname !== '/gameInfo' && (
                <div className={style.btnSearchImgWrap}>
                    <img
                        src={Search}
                        alt="Search"
                        className={style.btnSearch}
                        onClick={searchClick}
                    />
                </div>
            )}
            {location.pathname !== '/gameInfo' && (
                <div
                    className={style.balance}
                    onClick={() => {
                        const url =
                            location.pathname === '/deposit'
                                ? '/tradeRecord?tab=0'
                                : location.pathname === '/withdraw'
                                ? '/tradeRecord?tab=1'
                                : '/deposit';
                        navigate(url);
                    }}
                >
                    <span className={style.currency}>{currencySymbol[userInfo.currency]} </span>
                    <span className={style.currencyBalance}>{userInfo.balance}</span>
                    <img
                        src={
                            location.pathname === '/deposit'
                                ? Deposit
                                : location.pathname === '/withdraw'
                                ? Withdraw
                                : DepositIcon
                        }
                        alt=""
                    />
                </div>
            )}
            {location.pathname !== '/gameInfo' && (
                <div className={style.peopleWrapBorder}>
                    <img
                        src={userAvatar}
                        alt="avatarImg"
                        className={style.peopleWrap}
                        onClick={switchAccount}
                    />
                </div>
            )}
        </div>
    );
}

export default HeaderRight;
