import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePostHomeMenuMutation } from '@/app/services/game-center';
import { IMAGE_TYPE, utils_getImage } from '@/utils/image';
import RecommendGame from './RecommendGame';
import GamesSubGame from './GamesSubGame';
import style from '../style/games.module.css';
import { setHomeGameMenu, setMessage, setHomeGameTab, setBasePopup } from '@/app/slice/baseSlice';
import { usePressState } from '@/hooks/usePressState';
import { RootState } from '@/app/store';
import { GameArray } from '../types';
import { IServiceClient } from '@/types/base';
import { useTranslation } from 'react-i18next';
import { useGlobalActiveList } from '@/hooks/useGlobalActiveList';
import { pictureImgDomain } from '@/utils/tool';
import DepositDiscount from './DepositDiscount';

const MemoizedRecommendGame = React.memo(RecommendGame);
const MemoizedGamesSubGame = React.memo(GamesSubGame);

// eslint-disable-next-line react/display-name
const ActivityCenter = React.memo(({ openActivity }: { openActivity: (type: string) => void }) => (
    <div className={style.activityCenter}>
        <img
            src={`${pictureImgDomain}web/orange/activity1.png`}
            onClick={() => openActivity('telegram')}
        />
        <img
            src={`${pictureImgDomain}web/orange/activity2.png`}
            onClick={() => openActivity('turntable')}
        />
        <img
            src={`${pictureImgDomain}web/orange/activity3.png`}
            onClick={() => openActivity('inviteFriends')}
        />
    </div>
));

function GameBar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { rechargeDiscountActivityTime, showDiscountTime } = useGlobalActiveList();
    const [isPressed, handleMouseDown, handleMouseUp] = usePressState();
    const [mutateAsync] = usePostHomeMenuMutation();
    const { t } = useTranslation();
    const serviceClient: IServiceClient[] = useSelector(
        (state: RootState) => state.base.siteInfo.configs.serviceClient
    );
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const homeGameMenu = useSelector((state: RootState) => state.base.homeGameMenu);
    const homeGameTab = useSelector((state: RootState) => state.base.homeGameTab);
    const rouletteBoosterActivity = useSelector((state: RootState) =>
        state.base.globalActivityList?.find(x => x.exclusiveActivityType === 7)
    );
    const treasureActivity = useSelector((state: RootState) =>
        state.base.globalActivityList?.find(x => x.exclusiveActivityType === 3)
    );

    const refList = useMemo(
        () => homeGameMenu.map(() => React.createRef<HTMLDivElement>()),
        [homeGameMenu]
    );

    const getHomeMenu = useCallback(async () => {
        try {
            const result = await mutateAsync({});
            if ('data' in result) {
                dispatch(setHomeGameMenu(result.data));
            } else {
                console.log(result.error);
            }
        } catch (error) {
            console.log(error);
        }
    }, [mutateAsync, dispatch]);

    const openActivity = useCallback(
        (type: string) => {
            const telegramUrl = serviceClient.find(o => o.value === 'telegram')?.content;

            switch (type) {
                case 'telegram':
                    telegramUrl
                        ? window.open(telegramUrl, '_self')
                        : dispatch(setMessage({ type: 'success', message: t('help_noTelegram') }));
                    break;
                case 'inviteFriends':
                    if (!isLogin) return dispatch(setBasePopup('login'));
                    if (treasureActivity) return navigate('/inviteFriends');
                    dispatch(
                        setMessage({
                            type: 'error',
                            message: t('eventCenter_yet')
                        })
                    );
                    break;
                case 'turntable':
                    if (!isLogin) return dispatch(setBasePopup('login'));
                    if (rouletteBoosterActivity) return navigate('/turnTable');
                    dispatch(
                        setMessage({
                            type: 'error',
                            message: t('eventCenter_yet')
                        })
                    );
                    break;
                default:
                    break;
            }
        },
        [serviceClient, isLogin, rouletteBoosterActivity, treasureActivity]
    );

    const changeHomeGameType = (item: GameArray, index: number) => {
        if (item.gameType === homeGameTab) return;
        dispatch(setHomeGameTab(item.gameType));
        localStorage.setItem('homeGameTab', item.gameType);
        refList[index].current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        });
    };

    useEffect(() => {
        getHomeMenu();
    }, [getHomeMenu]);

    return (
        <>
            <ActivityCenter openActivity={openActivity} />

            {rechargeDiscountActivityTime > 0 && isLogin && (
                <DepositDiscount showDiscountTime={showDiscountTime} />
            )}

            <div className={style.tabListWrap}>
                <div
                    className={`${style.tabList} ${
                        homeGameMenu.length === 3 ? style.threeTabList : ''
                    }`}
                >
                    {homeGameMenu.map((item, index) => (
                        <div
                            ref={refList[index]}
                            onMouseDown={() => handleMouseDown(index)}
                            onMouseUp={() => handleMouseUp(index)}
                            onTouchStart={() => handleMouseDown(index)}
                            onTouchEnd={() => handleMouseUp(index)}
                            key={index}
                            className={`${style.tab} ${
                                homeGameTab === item.gameType ? style.isActive : style.isNormal
                            }`}
                            onClick={() => changeHomeGameType(item, index)}
                        >
                            <div className={isPressed[index] ? 'scaleDown' : 'scaleUp'}>
                                <img
                                    src={
                                        item.labelCode
                                            ? utils_getImage({
                                                  imageType:
                                                      homeGameTab === item.gameType
                                                          ? IMAGE_TYPE.GAME_MENU_ACTIVE
                                                          : IMAGE_TYPE.GAME_MENU,
                                                  labelCode: item.labelCode
                                              })
                                            : utils_getImage({
                                                  imageType:
                                                      homeGameTab === item.gameType
                                                          ? IMAGE_TYPE.GAME_TYPE_ACTIVE
                                                          : IMAGE_TYPE.GAME_TYPE,
                                                  gameType: item.gameType
                                              })
                                    }
                                    className={style.tabImg}
                                />
                                <span>{item.gameTypeName || item.labelName}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {homeGameTab === 'recommend' ? (
                <MemoizedRecommendGame
                    recommend={homeGameMenu.find(o => o.gameType === homeGameTab)!}
                />
            ) : (
                <MemoizedGamesSubGame
                    key={homeGameTab}
                    gameType={homeGameTab}
                    gameList={homeGameMenu.find(o => o.gameType === homeGameTab)!}
                />
            )}
        </>
    );
}

export default GameBar;
