import BasePopup from '@/components/basePopup/BasePopup';
import style from './bindAccount.module.css';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { setMessage } from '@/app/slice/baseSlice';
import LockIcon from '@/components/userDialog/img/lock.svg';
import Email from '@/components/userDialog/img/email.svg';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuthBindMutation } from '@/app/services/user';
import ShowPasswordButton from '@/components/userDialog/components/ShowPasswordButton';
import { setUserInfo, setLoginStatus } from '@/app/slice/userSlice';
import { loginValues } from '@/components/userDialog/types';
import md5 from 'md5';

function BindAccount(props: { onClose: () => void; code: string }) {
    const { t } = useTranslation();
    const [mutation, { isLoading }] = useAuthBindMutation();
    const { onClose, code } = props;
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<loginValues>();

    const handleShowPassword = () => {
        setShowPassword(value => !value);
    };

    const doSubmit = handleSubmit(async param => {
        if (isLoading) return;
        try {
            const result = await mutation({
                code: code,
                email: param.email,
                password: md5(param.password)
            });
            if ('data' in result) {
                const { token } = result.data.responseVO;
                dispatch(setLoginStatus(token));
                dispatch(setUserInfo(result.data.responseVO));
                dispatch(setMessage({ type: 'success', message: t('global_signSuceesfully') }));
                dispatch(
                    setMessage({ type: 'success', message: t('account_bind_google_success') })
                );
                onClose();
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            dispatch(
                setMessage({
                    type: 'error',
                    message: e?.message
                })
            );
        }
    });

    return (
        <>
            <BasePopup close={onClose} title={t('accountSecurity_accountBind')} width="345px">
                <>
                    <form onSubmit={doSubmit}>
                        <div className={style.formGroup}>
                            <div className={style.inputBar}>
                                <img src={Email} className={style.inputIcon} alt="Email" />
                                <input
                                    {...register('email', {
                                        required: t('login_emailIncorrect'),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: t('login_emailIncorrect')
                                        }
                                    })}
                                    maxLength={30}
                                    autoComplete="current-password"
                                    className={`${style.input} ${errors.email ? 'error' : ''}`}
                                    placeholder={t('accountSecurity_email')}
                                />
                                {errors.email && (
                                    <p className={style.error}>{errors.email.message}</p>
                                )}
                            </div>
                            <div className={style.inputBar}>
                                <img src={LockIcon} className={style.inputIcon} alt="LockIcon" />
                                <input
                                    {...register('password', {
                                        required: t('login_passwordBlank'),
                                        pattern: {
                                            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]{6,16}$/,
                                            message: t('login_passwordRequired')
                                        }
                                    })}
                                    maxLength={16}
                                    autoComplete="current-password"
                                    className={`${style.input} ${errors.password ? 'error' : ''}`}
                                    placeholder={t('login_enterPassword')}
                                    type={!showPassword ? 'text' : 'password'}
                                />
                                <ShowPasswordButton
                                    isShow={showPassword}
                                    change={handleShowPassword}
                                />
                                {errors.password && (
                                    <p className={style.error}>{errors.password.message}</p>
                                )}
                            </div>
                            <div className={style.divider}></div>
                            <Button type="submit" fullWidth className={style.submit}>
                                {t('global_submit')}
                                {isLoading && (
                                    <CircularProgress
                                        size={16}
                                        style={{ color: '#fff', marginLeft: '5px' }}
                                    />
                                )}
                            </Button>
                        </div>
                    </form>
                </>
            </BasePopup>
        </>
    );
}

export default BindAccount;
