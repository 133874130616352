import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BasePopup from '@/components/basePopup/BasePopup';
import Login from './components/Login';
import Register from './components/Register';
import RegisterSuccessfulPc from './components/RegisterSuccessfulPc';
import ForgetPassword from './loginAndRegister/ForgetPassword';
import { RootState } from '@/app/store';

import style from './userDialog.module.css';
import Banner from './img/loginBanner.png';
import BaseTabs from '@/components/baseTabs/BaseTabs';
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setBasePopup, setLoginDialogType } from '@/app/slice/baseSlice';
import CloseIcon from '@/assets/img/close.svg';
import GoogleLogin from './components/GoogleLogin';
import BindPopup from '@/pages/accountSecurity/bindPopup/BindPopup';
import SetPassword from '@/pages/accountSecurity/bindPopup/SetPassword';

function UserDialog() {
    const { t } = useTranslation();
    const matches = useMediaQuery('(max-width:750px)');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const basePopup = useSelector((state: RootState) => state.base.basePopup);
    const dialogType = useSelector((state: RootState) => state.base.loginDialogType);
    const baseTitle = useSelector((state: RootState) => state.base.siteInfo.configs.baseTitle);
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const [phoneNumberPop, setPhoneNumberPop] = useState(false);
    const [passwordPop, setPasswordPop] = useState(false);
    const [memberId, setMemberId] = useState('');
    const [memberAccount, setMemberAccount] = useState('');
    const googleFlag = useSelector(
        (state: RootState) =>
            (state.base?.siteInfo?.siteThird.length &&
                state.base?.siteInfo?.siteThird.filter(e => e.code == 'google')[0]) ||
            null
    );

    const optionsList = [
        { value: 'login', name: t('login_accountLogin') },
        { value: 'register', name: t('login_register') }
    ];

    const _onChange = (value: 'login' | 'register') => {
        dispatch(setLoginDialogType(value));
    };

    const handleClose = () => {
        dispatch(setBasePopup(''));
        dispatch(setLoginDialogType('login'));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const phoneOpen = (data: any) => {
        console.log('data:', data);
        if (data.firstGoogleAuth) {
            setMemberId(data.responseVO.id);
            setMemberAccount(data.memberAccount);
            setPasswordPop(true);
        } else {
            setPhoneNumberPop(true);
        }
        dispatch(setBasePopup(''));
    };

    const passwordClose = () => {
        setPasswordPop(false);
        handleRegisterClose();
    };

    const phoneClose = () => {
        setPhoneNumberPop(false);
    };

    const handleRegisterClose = async () => {
        dispatch(setBasePopup(''));
        if (matches) {
            navigate('/registerSuccessful');
        } else {
            dispatch(setLoginDialogType('successful'));
        }
    };

    return (
        <>
            {matches && (
                <Drawer
                    anchor={'bottom'}
                    open={basePopup !== ''}
                    onClose={handleClose}
                    sx={{
                        [`& .MuiDrawer-paper`]: {
                            backgroundColor: '#252422;',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px'
                        }
                    }}
                >
                    <>
                        {dialogType === 'forgetPassword' && <ForgetPassword />}
                        {(dialogType === 'login' || dialogType === 'register') && (
                            <>
                                <div className={style.dialogBodyMobile}>
                                    <div className={style.titleGroupMobile}>
                                        <p className={style.mobileTitle}>
                                            {t('login_startExperience')}
                                        </p>
                                        <img
                                            src={CloseIcon}
                                            alt="CloseIcon"
                                            className={style.closeIcon}
                                            onClick={handleClose}
                                        />
                                    </div>
                                    <BaseTabs
                                        value={dialogType}
                                        options={optionsList}
                                        onChange={_onChange}
                                    ></BaseTabs>
                                    {dialogType === 'login' && <Login onClick={handleClose} />}
                                    {dialogType === 'register' && (
                                        <Register onClick={handleRegisterClose} />
                                    )}
                                    {googleFlag && (
                                        <GoogleLogin
                                            handleOpen={phoneOpen}
                                            onClick={
                                                dialogType === 'login'
                                                    ? handleClose
                                                    : handleRegisterClose
                                            }
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </>
                </Drawer>
            )}
            {basePopup !== '' && !matches && !isLogin && (
                <BasePopup
                    showClose={true}
                    showTitle={false}
                    close={handleClose}
                    noNeedBottom={true}
                    padding="0"
                >
                    <>
                        {dialogType === 'forgetPassword' && <ForgetPassword />}
                        {(dialogType === 'login' || dialogType === 'register') && (
                            <>
                                <div className={style.flex}>
                                    <img className={style.banner} src={Banner} alt="" />
                                    <div className={style.dialogBody}>
                                        <div className={style.titleGroup}>
                                            <p className={style.welcome}>
                                                {t('login_welcomeTo')} {baseTitle}
                                            </p>
                                            <p className={style.welcomeInfo}>
                                                {t('login_worldLeading')}
                                            </p>
                                        </div>
                                        <BaseTabs
                                            value={dialogType}
                                            options={optionsList}
                                            onChange={_onChange}
                                        ></BaseTabs>
                                        {dialogType === 'login' && <Login onClick={handleClose} />}
                                        {dialogType === 'register' && (
                                            <Register onClick={handleRegisterClose} />
                                        )}
                                        {googleFlag && (
                                            <GoogleLogin
                                                onClick={
                                                    dialogType === 'login'
                                                        ? handleClose
                                                        : handleRegisterClose
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                </BasePopup>
            )}
            {phoneNumberPop && <BindPopup onClose={() => phoneClose()} type={'phoneNumber'} />}
            {passwordPop && (
                <SetPassword
                    onClose={() => passwordClose()}
                    memberId={memberId}
                    memberAccount={memberAccount}
                />
            )}
            {dialogType === 'successful' && !matches && <RegisterSuccessfulPc />}
        </>
    );
}

export default UserDialog;
