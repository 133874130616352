import { RouletteInfo } from '@/pages/eventCenter/dailyTask/type';
import { createSlice } from '@reduxjs/toolkit';

interface BaseState {
    rouletteDetails: RouletteInfo | undefined;
}

const initialState: BaseState = {
    rouletteDetails: undefined
};

export const activitySlice = createSlice({
    name: 'activitySlice',
    initialState,
    reducers: {
        setRouletteDetails: (state, action) => {
            state.rouletteDetails = action.payload;
        }
    }
});

export const { setRouletteDetails } = activitySlice.actions;

export default activitySlice.reducer;
