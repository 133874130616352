import { createSlice } from '@reduxjs/toolkit';

interface footerLinkObj {
    content: string;
    icon: string;
    pageCode: string;
    title: string;
}

// faq
interface detailData {
    content: string;
    title: string;
    path: '';
}
interface innerDetail {
    detail: Array<detailData> | [];
    content: string;
    icon: string;
    sort: number;
    title: string;
}
interface helpList {
    detail: Array<innerDetail> | [];
    icon: string;
    pageCode: string;
    title: string;
}

interface HelpFaq {
    FooterLinkData: {
        list: Array<footerLinkObj> | [];
        name: string;
        pageType: number; // 配置大类型 1首页文案 2帮助中心文案 3帮助中心三层 4投注规则四层
    };
    faq: {
        list: Array<helpList> | [];
        name: string;
        pageType: number;
    };
}

const initialState: HelpFaq = {
    FooterLinkData: {
        list: [],
        name: '',
        pageType: 1
    },
    faq: {
        list: [],
        name: '',
        pageType: 2
    }
};

export const footerSlice = createSlice({
    name: 'footer',
    initialState,
    reducers: {
        setFooterLinkList: (state, action) => {
            state.FooterLinkData.list = action.payload.list;
            state.FooterLinkData.name = action.payload.name;
            state.FooterLinkData.pageType = action.payload.pageType;
        },
        setFaq: (state, action) => {
            state.faq.list = action.payload.list;
            state.faq.name = action.payload.name;
            state.faq.pageType = action.payload.pageType;
        }
    }
});

export const { setFooterLinkList, setFaq } = footerSlice.actions;

export default footerSlice.reducer;
