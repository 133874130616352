import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Virtual } from 'swiper';
import { RootState } from '@/app/store';
import style from '../style/recommendGame.module.css';
import { RecommendGameType, GameTypeList, GameSubList } from '../types'; // 假设这些类型已经定义
import { IMAGE_TYPE, utils_getImage } from '@/utils/image';
import PlaceholderImg from '@/assets/img/placeholderImg.svg';
import { useNavigate } from 'react-router-dom';
import TitleBgBlue from '../img/titleBgBlue.svg';
import TitleBgRed from '../img/titleBgRed.svg';
import TitleBgGreen from '../img/titleBgGreen.svg';
import TitleBgZi from '../img/titleBgZi.svg';
import TitleBgBluePc from '../img/titleBgBluePc.svg';
import TitleBgRedPc from '../img/titleBgRedPc.svg';
import TitleBgGreenPc from '../img/titleBgGreenPc.svg';
import TitleBgZiPc from '../img/titleBgZiPc.svg';
import GuessYouLike from '../img/guessYouLike.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeArrow from '@/assets/img/homeArrow.svg';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { setMessage, setBasePopup } from '@/app/slice/baseSlice';
import WeiHu from '@/assets/img/weiHu.png';
import XiaoRen from '@/assets/img/xiaoRen.png';
SwiperCore.use([Virtual]);
function RecommendGame({ recommend }: RecommendGameType) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:750px)');
    const bigMatches = useMediaQuery('(min-width:1281px)');
    const randomBgList = [TitleBgBlue, TitleBgRed, TitleBgGreen, TitleBgZi];
    const randomBgListPc = [TitleBgBluePc, TitleBgRedPc, TitleBgGreenPc, TitleBgZiPc];
    const isLogin = useSelector((state: RootState) => state.user.isLogin);

    const [backgroundList, setBackgroundList] = useState<string[]>([]);
    const swiperInstances = useRef<SwiperCore[]>([]);
    const [arrowState, setArrowState] = useState<{
        [key: number]: { isBeginning: boolean; isEnd: boolean };
    }>({});

    useEffect(() => {
        const bgList = recommend?.subList?.map(() => {
            const randomIndex = Math.floor(Math.random() * randomBgList.length);
            return matches ? randomBgList[randomIndex] : randomBgListPc[randomIndex];
        });
        setBackgroundList(bgList || []);

        const initialArrowState = recommend?.subList?.reduce((acc, _, index) => {
            if (bigMatches && recommend?.subList?.[index]?.subList?.length <= 12) {
                acc[index] = { isBeginning: true, isEnd: true };
            } else {
                acc[index] = { isBeginning: true, isEnd: false };
            }
            return acc;
        }, {} as { [key: number]: { isBeginning: boolean; isEnd: boolean } });
        setArrowState(initialArrowState || {});
    }, [recommend]);

    const _onClick = (item?: GameTypeList) => {
        if (item) {
            navigate(
                `/singleGame?gameType=${item.gameType}&channelCode=${
                    item.channelCode || ''
                }&dataType=${item.dataType}&labelCode=${item.labelCode}`
            );
        }
    };

    const handleScrollRight = (index: number) => {
        if (swiperInstances.current[index]) {
            swiperInstances.current[index].slideNext(); // 使用 slideNext 方法
        }
    };

    const handleScrollLeft = (index: number) => {
        if (swiperInstances.current[index]) {
            swiperInstances.current[index].slidePrev(); // 使用 slidePrev 方法
        }
    };

    const chunkArray = (array: GameSubList[], chunkSize: number) => {
        // 计算整除后的长度
        const totalChunks = Math.floor(array.length / chunkSize) * chunkSize;
        const chunks = [];

        if (matches) {
            // 按照整除的部分进行分组
            for (let i = 0; i < totalChunks; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        } else {
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        }
    };

    const onSlideChange = (index: number) => {
        if (swiperInstances.current[index]) {
            const { isBeginning, isEnd } = swiperInstances.current[index];
            setArrowState(prev => ({
                ...prev,
                [index]: { isBeginning, isEnd }
            }));
        }
    };

    const handleStartGame = async (gameInfo: GameSubList) => {
        if (!isLogin) return dispatch(setBasePopup('login'));
        if (gameInfo.status !== 0)
            return dispatch(
                setMessage({
                    type: 'error',
                    message: t('home_gamePreparing')
                })
            );
        navigate(
            `/gameInfo?gameType=${gameInfo.gameType}&channelCode=${gameInfo.channelCode}&gameCode=${gameInfo.gameCode}&name=${gameInfo.name}&gameLine=${gameInfo.gameLine}`
        );
    };

    const renderGameTitle = (item: GameTypeList, index: number) => (
        <div className={style.gameTitle}>
            <div className={style.gameTitleItem}>
                <div className={style.lazyLoadImage}>
                    <img
                        src={utils_getImage({
                            imageType: item.labelCode
                                ? IMAGE_TYPE.GAME_MENU
                                : IMAGE_TYPE.GAME_CHANNEL_SUB,
                            labelCode: item.labelCode,
                            gameType: item.gameType,
                            channelCode: item.channelCode
                        })}
                        onError={e => {
                            (e.target as HTMLImageElement).src = PlaceholderImg;
                        }}
                    />
                </div>
                <span className={style.gameTitleName}>{item.channelName || item.name}</span>
                <span className={style.gameTitleNameInfo}>Jogos</span>
            </div>

            <div className={style.gameTitleItemSecondRight}>
                <div
                    className={style.gameTitleItemSecond}
                    onClick={() => _onClick(recommend?.subList[index])}
                >
                    {t('gameInfo_mais')}
                    <span>{item.total}</span>
                </div>
                <div
                    className={style.gameArrowLeft}
                    onClick={() => handleScrollLeft(index)}
                    style={{ opacity: arrowState[index]?.isBeginning ? 0.5 : 1 }}
                >
                    <img src={HomeArrow} alt="" />
                </div>
                <div
                    className={style.gameArrowRight}
                    onClick={() => handleScrollRight(index)}
                    style={{ opacity: arrowState[index]?.isEnd ? 0.5 : 1 }}
                >
                    <img src={HomeArrow} alt="" />
                </div>
            </div>

            <img
                src={
                    item.dataType === 1 && item.labelCode === 'home-game-label-cnxh'
                        ? GuessYouLike
                        : backgroundList[index]
                }
                className={
                    item.dataType === 1 && item.labelCode === 'home-game-label-cnxh'
                        ? style.guessYouLike
                        : style.titleBgClass
                }
            />
        </div>
    );

    const renderSwiperSlides = (item: GameTypeList, chunkSize: number, index: number) => (
        <Swiper
            onSwiper={swiper => {
                swiperInstances.current[index] = swiper; // 存储对应索引的Swiper实例
            }}
            onSlideChange={() => onSlideChange(index)}
            virtual
        >
            {chunkArray(item.subList, chunkSize).map((group, slideIndex) => (
                <SwiperSlide key={slideIndex} className={style.swiperSlideWrap}>
                    {group.map((image, imgIndex) => (
                        <div
                            className={style.swiperSlideScale}
                            key={imgIndex}
                            onClick={() => handleStartGame(image)}
                        >
                            <div className={style.swiperSlideLazy}>
                                <LazyLoadImage
                                    src={utils_getImage({
                                        imageType: IMAGE_TYPE.GAME,
                                        gameType: image.gameType,
                                        channelCode: image.channelCode,
                                        gameCode: image.gameCode
                                    })}
                                    className={style.swiperSlideImage}
                                    placeholderSrc={PlaceholderImg}
                                    onError={e => {
                                        (e.target as HTMLImageElement).src = PlaceholderImg;
                                    }}
                                />
                            </div>

                            {image.status !== 0 && (
                                <div className={style.weiHuWrap}>
                                    <img src={WeiHu} className={style.weiHuImg} alt="" />
                                </div>
                            )}

                            {image.status === 0 && (
                                <div className={style.xiaoRenWrap}>
                                    <img src={XiaoRen} className={style.xiaoRenImg} alt="" />
                                    <span>{image.hot || 0}</span>{' '}
                                </div>
                            )}
                        </div>
                    ))}
                </SwiperSlide>
            ))}
        </Swiper>
    );

    const getChunkSize = (item: GameTypeList) => {
        if (matches) {
            return item.dataType === 1 && item.labelCode === 'home-game-label-cnxh' ? 3 : 6;
        } else if (bigMatches) {
            return item.dataType === 1 && item.labelCode === 'home-game-label-cnxh' ? 6 : 12;
        } else {
            return item.dataType === 1 && item.labelCode === 'home-game-label-cnxh' ? 4 : 8; // 默认情况
        }
    };

    return (
        <>
            {recommend?.subList?.map((item, index) => (
                <div
                    className={
                        item.dataType === 1 && item.labelCode === 'home-game-label-cnxh'
                            ? style.gameItemModalGuess
                            : style.gameItemModal
                    }
                    key={index}
                >
                    {renderGameTitle(item, index)}
                    {renderSwiperSlides(item, getChunkSize(item), index)}
                </div>
            ))}
        </>
    );
}

export default RecommendGame;
