import api from '../api';
import API_PATH from './apiPathConfig';

const gameCenterApi = api.injectEndpoints({
    endpoints: builder => ({
        postHomeMenu: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/menu/getHomeMenu`,
                method: 'POST',
                body: paymentData
            })
        }),
        getChannelBalance: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/gameAccount/getBalance`,
                method: 'POST',
                body: paymentData
            })
        }),
        getGameNameDict: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/game/gameNameDict`,
                method: 'POST',
                body: paymentData
            })
        }),
        getSecondMenu: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/menu/getSecondMenu`,
                method: 'POST',
                body: paymentData
            })
        }),
        getSecondGameListPage: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/game/getGameListPage`,
                method: 'POST',
                body: paymentData
            })
        }),
        getCollectGameList: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/memberFav/queryList`,
                method: 'POST',
                body: paymentData
            })
        }),
        getRecentGameList: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/menu/memberRecentGames`,
                method: 'POST',
                body: paymentData
            })
        }),
        getStartGame: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/game/start`,
                method: 'POST',
                body: paymentData
            })
        }),
        getGameBetRecord: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/game/realTimeBetting`,
                method: 'POST',
                body: paymentData
            })
        }),
        getRecommendGame: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/game/relevantRecommend`,
                method: 'POST',
                body: paymentData
            })
        }),
        getMyBetRecord: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/bet/betRecord`,
                method: 'POST',
                body: paymentData
            })
        }),
        addFav: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/memberFav/add`,
                method: 'POST',
                body: paymentData
            })
        }),
        getHotGameList: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/sport/getHotSearch`,
                method: 'POST',
                body: paymentData
            })
        }),
        removeFav: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/memberFav/cancel`,
                method: 'POST',
                body: paymentData
            })
        }),
        getSearchGameList: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/sport/queryGameByMsg`,
                method: 'POST',
                body: paymentData
            })
        }),
        getBetRecord: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/bet/betRecord`,
                method: 'POST',
                body: paymentData
            })
        }),
        getBetRecordDetail: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/bet/betRecordDetail`,
                method: 'POST',
                body: paymentData
            })
        }),
        getCollectionAmount: builder.mutation({
            query: paymentData => ({
                url: `${API_PATH.game}/f/game/collectionAmount`,
                method: 'POST',
                body: paymentData
            })
        })
    })
});

export const {
    usePostHomeMenuMutation,
    useGetChannelBalanceMutation,
    useGetGameNameDictMutation,
    useGetSecondMenuMutation,
    useGetSecondGameListPageMutation,
    useGetCollectGameListMutation,
    useGetRecentGameListMutation,
    useGetStartGameMutation,
    useGetGameBetRecordMutation,
    useGetRecommendGameMutation,
    useGetMyBetRecordMutation,
    useAddFavMutation,
    useRemoveFavMutation,
    useGetHotGameListMutation,
    useGetSearchGameListMutation,
    useGetBetRecordMutation,
    useGetBetRecordDetailMutation,
    useGetCollectionAmountMutation
} = gameCenterApi;

export default gameCenterApi;
