import { ReactNode, MouseEvent, AnchorHTMLAttributes } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/store';
import { setBasePopup } from '@/app/slice/baseSlice';
import { PROTECT_LINK } from './protectLink';

type LinkProps = {
    to: string | Function;
    children: ReactNode;
    method?: () => void;
    loginAfterMethod?: () => void; // 需要登录后做的函数
} & AnchorHTMLAttributes<HTMLAnchorElement>;

function CustomLink({ to, children, method, loginAfterMethod, ...rest }: LinkProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const isLogin = useSelector((state: RootState) => state.user.isLogin);

    const handleClick = (event: MouseEvent) => {
        event.preventDefault();
        if (typeof to === 'function') {
            to();
            return;
        }
        method && method();
        if (PROTECT_LINK.includes(to) && !isLogin) {
            return dispatch(setBasePopup('login'));
        }
        loginAfterMethod && loginAfterMethod();
        if (location.pathname !== to) {
            navigate(to);
        }
    };

    return (
        <a href={typeof to === 'function' ? undefined : to} onClick={handleClick} {...rest}>
            {children}
        </a>
    );
}

export default CustomLink;
