import api from '../api';
import API_PATH from './apiPathConfig';

type Data = {
    configs: {
        currency: string;
        currentLevel: number;
        upgradeRechargeMoney: number;
        upgradeBetAmount: number;
        exchangeRate: number;
        maxMoney: number;
        dailyWithdrawTimes: number;
        upgradeGold: number;
        dayGold: number;
        weekGold: number;
        monthGold: number;
        upgradeType: number;
        vipMaxRebateRateList: {
            gameType: string;
            gameTypeName: string;
            maxRate: number;
        }[];
    }[];
    currentLevel: number;
    currentRechargeMoney: number;
    currentValidBetAmount: number;
};

const personalApi = api.injectEndpoints({
    endpoints: builder => ({
        getVipInfo: builder.query<Data, void>({
            query: () => `${API_PATH.member}/f/siteVipConfig/queryHome`
        })
    })
});

export const { useGetVipInfoQuery } = personalApi;
export default personalApi;
