export default {
    master: import.meta.env.VITE_MASTER_API_PATH,
    game: import.meta.env.VITE_GAME_API_PATH,
    payment: import.meta.env.VITE_PAYMENT_API_PATH,
    member: import.meta.env.VITE_MEMBER_API_PATH,
    site: import.meta.env.VITE_SITE_API_PATH,
    lottery: import.meta.env.VITE_LOTTERY_API_PATH,
    activity: import.meta.env.VITE_ACTIVITY_API_PATH,
    electronic: import.meta.env.VITE_ELECTRONIC_API_PATH
};
