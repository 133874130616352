import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { GameListProps } from '../types';
import { IMAGE_TYPE, utils_getImage } from '@/utils/image';
import style from '../style/gamesSubGame.module.css';
import SubGameWrap from '@/components/subGameWrap/SubGameWrap';
import HomeArrow from '@/assets/img/homeArrow.svg';
import 'react-lazy-load-image-component/src/effects/blur.css';
import TitleBgBlue from '../img/titleBgBlue.svg';
import TitleBgRed from '../img/titleBgRed.svg';
import TitleBgGreen from '../img/titleBgGreen.svg';
import TitleBgZi from '../img/titleBgZi.svg';
import TitleBgBluePc from '../img/titleBgBluePc.svg';
import TitleBgRedPc from '../img/titleBgRedPc.svg';
import TitleBgGreenPc from '../img/titleBgGreenPc.svg';
import TitleBgZiPc from '../img/titleBgZiPc.svg';

function GamesSubGame({ gameType, gameList }: GameListProps) {
    const matches = useMediaQuery('(max-width:750px)');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const randomBgList = [TitleBgBlue, TitleBgRed, TitleBgGreen, TitleBgZi];
    const randomBgListPc = [TitleBgBluePc, TitleBgRedPc, TitleBgGreenPc, TitleBgZiPc];
    return (
        <>
            {gameList?.subList?.map((item, index) => {
                return (
                    <div className={style.gameWrap} key={index}>
                        <div className={style.gameTitle}>
                            <div className={style.gameTitleInfo}>
                                <img
                                    src={utils_getImage({
                                        imageType: IMAGE_TYPE.GAME_CHANNEL_SUB,
                                        gameType: gameType,
                                        channelCode: item.channelCode
                                    })}
                                    className={style.cardScaleImage}
                                />
                                <span>{item.name}</span>
                                <span className={style.titleJogos}>Jogos</span>
                            </div>
                            <div
                                className={style.gameTitleItemSecond}
                                onClick={() =>
                                    navigate(
                                        `/singleGame?gameType=${gameType}&channelCode=${item.channelCode}`
                                    )
                                }
                            >
                                <div>
                                    {t('gameInfo_mais')}
                                    <span>{item.total}</span>
                                </div>
                                <img src={HomeArrow} className={style.rightArrowImg} />
                            </div>
                            <img
                                src={
                                    matches
                                        ? randomBgList[
                                              Math.floor(Math.random() * randomBgList.length)
                                          ]
                                        : randomBgListPc[
                                              Math.floor(Math.random() * randomBgList.length)
                                          ]
                                }
                                className={style.titleBgClass}
                            />
                        </div>
                        <SubGameWrap
                            subGameList={matches ? item?.subList?.slice(0, 6) : item?.subList}
                            gameType={gameType}
                        />
                    </div>
                );
            })}
        </>
    );
}

export default GamesSubGame;
