import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import style from './sideMenu.module.css';
import KeFu from './img/keFu.png';
import LeftArrow from './img/leftArrow.svg';
import Yuan from './img/yuan.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FloatData } from '@/pages/home/types';
function SideMenu() {
    const { t } = useTranslation();
    const [switchOpen, setSwitchOpen] = useState(false);
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const sideList = useSelector((state: RootState) => state.base.sideList);
    const switchButton = () => {
        setSwitchOpen(!switchOpen);
    };
    const navigate = useNavigate();
    const _onClick = () => {
        navigate('/serviceView');
        setSwitchOpen(!switchOpen);
    };

    const jumpClick = (item: FloatData) => {
        if (item.urlType === '1') return window.open(item.jumpUrl, '_blank');
    };

    useEffect(() => {
        if (isLogin) {
            setSwitchOpen(true);
            const timeoutId = setTimeout(() => {
                setSwitchOpen(false);
            }, 2000);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [isLogin]);

    return (
        <div className={`${style.sideMenu} ${!switchOpen ? style.sideClose : style.sideOpen}`}>
            <div className={style.underBg}>
                <div className={style.underItemBg}></div>
                <div className={style.sideCircle} onClick={switchButton}>
                    <img src={Yuan} alt="" className={style.yuan} />
                    {switchOpen ? (
                        <div className={style.arrowWrapOpen}>
                            <img
                                className={`${style.normalArrow} ${style.arrowDelay}`}
                                src={LeftArrow}
                                alt=""
                            />
                            <img className={style.normalArrow2} src={LeftArrow} alt="" />
                        </div>
                    ) : (
                        <div className={style.arrowWrap}>
                            <img className={style.rotateArrow} src={LeftArrow} alt="" />
                            <img
                                className={`${style.rotateArrow2} ${style.arrowDelay}`}
                                src={LeftArrow}
                                alt=""
                            />
                        </div>
                    )}
                </div>
                <div className={style.sideItem} onClick={_onClick}>
                    <div className={style.sideIconWrap}>
                        <img
                            className={`${style.sideIcon} ${style.sideIconKeFu}`}
                            src={KeFu}
                            alt="wheel"
                        />
                    </div>
                    <div className={style.itemText}>{t('footer_onlineCustomerServiceMore')}</div>
                </div>
                {/* <div className={style.sideItem} onClick={_onClickDeposit}>
                    <div className={style.sideIconWrap}>
                        <img
                            className={`${style.sideIcon} ${style.sideIconRegisterBonus}`}
                            src={RegisterBonus}
                            alt="wheel"
                        />
                    </div>
                    <div className={style.itemText}>{t('recordModal_bonus')}</div>
                </div> */}
                {sideList.map(item => {
                    return (
                        <div
                            key={item.id}
                            className={style.sideItem}
                            onClick={() => jumpClick(item)}
                        >
                            <div className={style.sideIconWrap}>
                                <img
                                    className={style.sideIcon}
                                    src={item.wapImageUrl}
                                    alt="wheel"
                                />
                            </div>
                            <div className={style.itemText}>{item.title}</div>
                        </div>
                    );
                })}
                {/* <div className={style.sideItem}>
                    <img className={style.sideIcon} src={Wheel} alt="wheel" />
                    <div className={style.itemText}>{t('header_clickToDraw')}</div>
                </div>
                <div className={style.sideItem}>
                    <img className={style.sideIconOnly} src={NewPerson} alt="NewPerson" />
                </div> */}
                {/* <div className={style.sideItem}>
                    <img className={style.sideIconOnly} src={RedEnvelope} alt="redEnvelope" />
                </div>
                <div className={style.sideItem}>
                    <img className={style.sideIcon} src={Sign} alt="Sign" />
                    <div className={style.itemText}>{t('header_checkIn')}</div>
                </div>
                <div className={style.sideItem}>
                    <img className={style.sideIconOnly} src={Award} alt="award" />
                </div> */}
            </div>
        </div>
    );
}

export default SideMenu;
