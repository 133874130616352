import api from '../api';
import API_PATH from './apiPathConfig';

const inviteFriendsApi = api.injectEndpoints({
    endpoints: builder => ({
        SingleActivityDetail: builder.mutation({
            query: data => ({
                url: `${API_PATH.activity}/f/activity/querySingleActivityDetail`,
                method: 'POST',
                body: data
            })
        }),
        ApplyBecomeProxy: builder.mutation({
            query: data => ({
                url: `${API_PATH.member}/f/memberBase/applyBecomeProxy`,
                method: 'POST',
                body: data
            })
        }),
        History: builder.mutation({
            query: data => ({
                url: `${API_PATH.activity}/f/activity/pageInvitedRecord`,
                method: 'POST',
                body: data
            })
        }),
        ActivityTask: builder.mutation({
            query: data => ({
                url: `${API_PATH.activity}/f/activity/task`,
                method: 'POST',
                body: data
            })
        }),
        GlobalActivityList: builder.mutation({
            query: data => ({
                url: `${API_PATH.activity}/f/activity/queryList`,
                method: 'POST',
                body: data
            })
        }),
        exclusiveList: builder.mutation({
            //专属活动tabs
            query: data => ({
                url: `${API_PATH.activity}/f/activity/queryExclusivePage`,
                method: 'POST',
                body: data
            })
        }),
        exclusiveTask: builder.mutation({
            //专属活动提交
            query: data => ({
                url: `${API_PATH.site}/f/activityTask/onceReceiveTask`,
                method: 'POST',
                body: data
            })
        }),
        pageInviteFriend: builder.mutation({
            //专属活动提交
            query: data => ({
                url: `${API_PATH.activity}/f/activity/pageInviteFriend`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const {
    useSingleActivityDetailMutation,
    useApplyBecomeProxyMutation,
    useHistoryMutation,
    useActivityTaskMutation,
    useGlobalActivityListMutation,
    useExclusiveListMutation,
    useExclusiveTaskMutation,
    usePageInviteFriendMutation
} = inviteFriendsApi;
export default inviteFriendsApi;
