// import { Key, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import style from '../style/home.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { RootState } from '@/app/store';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Autoplay, Navigation, Pagination } from 'swiper';
import PlaceholderBigImg from '@/assets/img/placeholderBigImg.svg';
import PlaceholderPromotional from '@/pages/eventCenter/promotionalEvent/img/placePromotional.svg';
import useJump from '@/hooks/useJump';
import { BannerData } from '../types';

function Banner() {
    const matches = useMediaQuery('(max-width:750px)');
    const bannerList = useSelector((state: RootState) => state.base.bannerList);

    const { jumpTarget } = useJump();

    const jump = (x: BannerData) => {
        jumpTarget({ jumpUrl: x.jumpUrl, jumpUrlObj: x.jumpUrlObj, urlType: x.urlType });
    };
    return (
        <div className={style.bannerContainer}>
            <Swiper
                spaceBetween={10}
                centeredSlides={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                pagination={true}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                className="mySwiper"
            >
                {bannerList.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className={style.bannerBg} onClick={() => jump(item)}>
                            {matches ? (
                                <img
                                    src={item.wapImageUrl}
                                    className={style.bannerImageMobile}
                                    onError={e => {
                                        (e.target as HTMLImageElement).src = PlaceholderBigImg;
                                    }}
                                />
                            ) : (
                                <img
                                    src={item.pcImageUrl}
                                    className={style.bannerImageWeb}
                                    onError={e => {
                                        (e.target as HTMLImageElement).src = PlaceholderPromotional;
                                    }}
                                />
                            )}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default Banner;
