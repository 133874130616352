import { ChangeEvent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/app/store';
import style from './search.module.css';
import { setBasePopup } from '@/app/slice/baseSlice';
import Search from '@/assets/img/search.svg';
import { IMAGE_TYPE, utils_getImage } from '@/utils/image';
import PlaceholderImg from '@/assets/img/placeholderImg.svg';
import NoData from '@/components/noDataPage/NoDataImg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CircularProgress from '@mui/material/CircularProgress';
import { setMessage } from '@/app/slice/baseSlice';
interface GameListProps {
    channelCode: string;
    channelName: string;
    gameCode: string;
    gameType: string;
    name: string;
    status: number;
    isFav: number;
    gameLine: string;
}

interface ChildGameProps {
    childGame: {
        channelCode: string;
        channelName: string;
        gameCode: string;
        gameType: string;
        gameTypeName: string;
        isFav: number;
        name: string;
        status: number;
        gameLine: string;
    }[];
}

interface BigGameListProps {
    gameType: string;
    games: ChildGameProps;
    name: string;
}

interface SearchBarProps {
    isActive: boolean;
    searchText: string;
    gamesList: GameListProps[];
    isLoading: boolean;
    bigGamesList: BigGameListProps[];
    searchHot: () => void;
    onBlur: () => void;
    searchGames: (event: ChangeEvent<HTMLInputElement>) => void;
    setIsActive: (value: boolean) => void;
    setSearchText: (value: string) => void;
}

function SearchBar({
    isActive,
    searchText,
    gamesList,
    isLoading,
    bigGamesList,
    searchHot,
    onBlur,
    searchGames,
    setIsActive,
    setSearchText
}: SearchBarProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectIndex, setSelectIndex] = useState(0);
    const isLogin = useSelector((state: RootState) => state.user.isLogin);

    const _handleStartGame = (item: GameListProps) => {
        if (!isLogin) return dispatch(setBasePopup('login'));
        if (item.status !== 0)
            return dispatch(
                setMessage({
                    type: 'error',
                    message: t('home_gamePreparing')
                })
            );

        onBlur();
        navigate(
            `/gameInfo?gameType=${item.gameType}&channelCode=${item.channelCode}&gameCode=${item.gameCode}&isFav=${item.isFav}&name=${item.name}&gameLine=${item.gameLine}`
        );
    };

    return (
        <>
            <div className={style.searchInput}>
                <img className={style.searchIcon} src={Search} alt="search" />
                <input
                    className={style.search}
                    onFocus={searchHot}
                    value={searchText}
                    onChange={searchGames}
                    placeholder={t('global_search')}
                    type="text"
                />
            </div>
            <div className={`${style.menu} ${isActive ? style.isShow : style.isClose}`}>
                {isLoading ? (
                    <div className={style.searchGameLoading}>
                        <CircularProgress size={28} style={{ color: '#fff' }} />
                    </div>
                ) : searchText === '' ? (
                    <>
                        <div className={style.hotTitle}>{t('global_hotSearch')}</div>
                        <ul className={style.menuList}>
                            {gamesList.length > 0 &&
                                gamesList.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={style.menuListItem}
                                            onClick={() => _handleStartGame(item)}
                                        >
                                            <div>
                                                <LazyLoadImage
                                                    src={utils_getImage({
                                                        imageType: item.gameCode
                                                            ? IMAGE_TYPE.GAME
                                                            : IMAGE_TYPE.MENU_GAME,
                                                        gameType: item.gameType,
                                                        channelCode: item.channelCode,
                                                        gameCode: item.gameCode
                                                            ? item.gameCode
                                                            : undefined
                                                    })}
                                                    placeholderSrc={PlaceholderImg}
                                                    effect="blur"
                                                    className={style.menuListItemImage}
                                                    onError={e => {
                                                        (e.target as HTMLImageElement).src =
                                                            PlaceholderImg;
                                                    }}
                                                />
                                            </div>
                                            <div className={style.menuListItemName}>
                                                <span className={style.menuListItemNameItem}>
                                                    {item.name}
                                                </span>
                                                <span className={style.menuListItemNameCode}>
                                                    {item.channelName}
                                                </span>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                    </>
                ) : (
                    <>
                        {bigGamesList.length > 0 ? (
                            <div className={style.bigGameMenuWrap}>
                                <ul className={style.bigGameMenu}>
                                    {bigGamesList.map((o: BigGameListProps, index: number) => {
                                        return (
                                            <li
                                                key={o.gameType}
                                                className={`${style.bigGameMenuItem} ${
                                                    selectIndex === index ? style.selectActive : ''
                                                }`}
                                                onClick={() => setSelectIndex(index)}
                                            >
                                                {o.name}
                                            </li>
                                        );
                                    })}
                                </ul>

                                <ul className={style.menuList}>
                                    {bigGamesList[selectIndex].games.childGame.map(item => {
                                        return (
                                            <li
                                                key={item.name}
                                                className={style.menuListItem}
                                                onClick={() => _handleStartGame(item)}
                                            >
                                                <div>
                                                    <LazyLoadImage
                                                        src={utils_getImage({
                                                            imageType: item.gameCode
                                                                ? IMAGE_TYPE.GAME
                                                                : IMAGE_TYPE.MENU_GAME,
                                                            gameType: item.gameType,
                                                            channelCode: item.channelCode,
                                                            gameCode: item.gameCode
                                                                ? item.gameCode
                                                                : undefined
                                                        })}
                                                        placeholderSrc={PlaceholderImg}
                                                        effect="blur"
                                                        className={style.menuListItemImage}
                                                        onError={e => {
                                                            (e.target as HTMLImageElement).src =
                                                                PlaceholderImg;
                                                        }}
                                                    />
                                                </div>
                                                <div className={style.menuListItemName}>
                                                    <span className={style.menuListItemNameItem}>
                                                        {item.name}
                                                    </span>
                                                    <span className={style.menuListItemNameCode}>
                                                        {item.channelName}
                                                    </span>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ) : (
                            <NoData type={'noSearch'} />
                        )}
                    </>
                )}
            </div>
            {isActive && (
                <div
                    className={style.mask}
                    onClick={() => {
                        setIsActive(false);
                        setSearchText('');
                    }}
                ></div>
            )}
        </>
    );
}

export default SearchBar;
