import { useState } from 'react';
import CustomLink from '@/components/customLink/CustomLink';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Home from './img/home.png';
import HomeLight from './img/home_light.png';
import Activity from './img/activity.png';
import ActivityLight from './img/activity_light.png';
import My from './img/my.png';
import MyLight from './img/my_light.png';
import Deposit from './img/deposit.png';
import DepositLight from './img/deposit_light.png';
import style from './navFooter.module.css';
import { useLocation } from 'react-router-dom';
import YuanQuanOne from './img/yuanQuanOne.png';
import YuanQuanzhuanPan from './img/yuanQuanzhuanPan.png';
import CenterLight from './img/centerLight.png';
import { setBasePopup, setMessage } from '@/app/slice/baseSlice';
import { useNavigate } from 'react-router-dom';

import { isPWA, isAndroid } from '@/utils/tool';
import { RootState } from '@/app/store';
function NavFooter() {
    const dispatch = useDispatch();
    const setIsActive = useState('/')[1];
    const { t } = useTranslation();
    const location = useLocation();
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const activityInfo = useSelector((state: RootState) =>
        state.base.globalActivityList?.find(x => x.exclusiveActivityType == 7)
    );
    const navigate = useNavigate();

    const NavFooterListOne = [
        {
            title: t('home_home'),
            img: Home,
            imgActive: HomeLight,
            key: '/',
            path: '/'
        },
        {
            title: t('news_event'),
            img: Activity,
            imgActive: ActivityLight,
            key: '/eventCenter',
            path: '/eventCenter?type=promotional'
        }
    ];

    const NavFooterListTwo = [
        {
            title: t('global_depositWithdrawal'),
            img: Deposit,
            imgActive: DepositLight,
            key: '/deposit',
            path: '/deposit'
        },
        {
            title: t('global_mine'),
            img: My,
            imgActive: MyLight,
            key: '/personalCenter',
            path: '/personalCenter'
        }
    ];

    const _onClick = (key: string) => {
        setIsActive(key);
    };

    const openActivity = () => {
        if (!isLogin) {
            dispatch(setBasePopup('login'));
        } else if (activityInfo) {
            location.pathname != '/turnTable' && navigate('/turnTable');
        } else {
            dispatch(
                setMessage({
                    type: 'error',
                    message: t('eventCenter_yet')
                })
            );
        }
    };

    return (
        <>
            <div className={`${style.navFooter} ${isPWA && !isAndroid ? style.navFooterPwa : ''}`}>
                <ul className={style.navFooterWrapper}>
                    {NavFooterListOne.map(item => {
                        return (
                            <li key={item.title} className={style.navFooterItem}>
                                <CustomLink
                                    to={item.path}
                                    loginAfterMethod={() => {
                                        _onClick(item.key);
                                    }}
                                >
                                    <img
                                        src={
                                            item.key === location.pathname
                                                ? item.imgActive
                                                : item.img
                                        }
                                        alt="img"
                                        className={style.navFooterIcon}
                                    />
                                    <span
                                        className={`${style.navFooterTitle} ${
                                            item.key === location.pathname
                                                ? style.navFooterTitleActive
                                                : ''
                                        }`}
                                    >
                                        {item.title}
                                    </span>
                                </CustomLink>
                            </li>
                        );
                    })}
                </ul>

                <div className={style.footerTurntable} onClick={openActivity}>
                    {/* <img src={YuanQuanOne} className={style.yuanQuanOne} /> */}
                    <img src={YuanQuanOne} className={style.yuanQuanTwo} />
                    <img src={YuanQuanzhuanPan} className={style.yuanQuanzhuanPan} />
                    <img src={CenterLight} className={style.centerLight} />
                </div>

                <ul className={style.navFooterWrapper}>
                    {NavFooterListTwo.map(item => {
                        return (
                            <li key={item.title} className={style.navFooterItem}>
                                <CustomLink
                                    to={item.path}
                                    loginAfterMethod={() => {
                                        _onClick(item.key);
                                    }}
                                >
                                    <img
                                        src={
                                            item.key === location.pathname
                                                ? item.imgActive
                                                : item.img
                                        }
                                        alt="img"
                                        className={style.navFooterIcon}
                                    />
                                    <span
                                        className={`${style.navFooterTitle} ${
                                            item.key === location.pathname
                                                ? style.navFooterTitleActive
                                                : ''
                                        }`}
                                    >
                                        {item.title}
                                    </span>
                                </CustomLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {isPWA && !isAndroid && <div className={style.navFooterFull} />}
        </>
    );
}

export default NavFooter;
