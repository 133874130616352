import BasePopup from '@/components/basePopup/BasePopup';
import style from './bindAccount.module.css';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { setMessage } from '@/app/slice/baseSlice';
import LockIcon from '@/components/userDialog/img/lock.svg';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useSetPasswordMutation } from '@/app/services/user';
import ShowPasswordButton from '@/components/userDialog/components/ShowPasswordButton';
import { loginValues } from '@/components/userDialog/types';
import md5 from 'md5';

function BindAccount(props: { onClose: () => void; memberId: string; memberAccount: string }) {
    const { t } = useTranslation();
    const [mutation, { isLoading }] = useSetPasswordMutation();
    const { onClose, memberId, memberAccount } = props;
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<loginValues>();

    const handleShowPassword = () => {
        setShowPassword(value => !value);
    };

    const handleConfirmShowPassword = () => {
        setShowConfirmPassword(value => !value);
    };

    const doSubmit = handleSubmit(async param => {
        if (isLoading) return;
        if (param.password != param.confirmPassword) {
            dispatch(
                setMessage({
                    type: 'error',
                    message: t('accountSecurity_notSamePassword')
                })
            );
            return false;
        }
        try {
            const result = await mutation({
                id: memberId,
                memberAccount: memberAccount,
                password: md5(param.password)
            });
            if ('data' in result) {
                dispatch(setMessage({ type: 'success', message: t('accountSecurity_setSuccess') }));
                onClose();
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            dispatch(
                setMessage({
                    type: 'error',
                    message: e?.message
                })
            );
        }
    });

    return (
        <>
            <BasePopup title={t('accountSecurity_setPassWordValue')} width="345px">
                <>
                    <form onSubmit={doSubmit}>
                        <div className={style.formGroup}>
                            <div className={style.inputBar}>
                                <img src={LockIcon} className={style.inputIcon} alt="LockIcon" />
                                <input
                                    {...register('password', {
                                        required: t('login_passwordBlank'),
                                        pattern: {
                                            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]{6,16}$/,
                                            message: t('login_passwordRequired')
                                        }
                                    })}
                                    maxLength={16}
                                    autoComplete="current-password"
                                    className={`${style.input} ${errors.password ? 'error' : ''}`}
                                    placeholder={t('password_hint')}
                                    type={!showPassword ? 'text' : 'password'}
                                />
                                <ShowPasswordButton
                                    isShow={showPassword}
                                    change={handleShowPassword}
                                />
                                {errors.password && (
                                    <p className={style.error}>{errors.password.message}</p>
                                )}
                            </div>
                            <div className={style.inputBar}>
                                <img src={LockIcon} className={style.inputIcon} alt="LockIcon" />
                                <input
                                    {...register('confirmPassword', {
                                        required: t('login_passwordBlank'),
                                        pattern: {
                                            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]{6,16}$/,
                                            message: t('login_passwordRequired')
                                        }
                                    })}
                                    maxLength={16}
                                    autoComplete="current-password"
                                    className={`${style.input} ${
                                        errors.confirmPassword ? 'error' : ''
                                    }`}
                                    placeholder={t('password_confirm')}
                                    type={!showConfirmPassword ? 'text' : 'password'}
                                />
                                <ShowPasswordButton
                                    isShow={showConfirmPassword}
                                    change={handleConfirmShowPassword}
                                />
                                {errors.confirmPassword && (
                                    <p className={style.error}>{errors.confirmPassword.message}</p>
                                )}
                            </div>
                            <div className={style.divider}></div>
                            <Button type="submit" fullWidth className={style.submit}>
                                {t('global_submit')}
                                {isLoading && (
                                    <CircularProgress
                                        size={16}
                                        style={{ color: '#fff', marginLeft: '5px' }}
                                    />
                                )}
                            </Button>
                        </div>
                    </form>
                </>
            </BasePopup>
        </>
    );
}

export default BindAccount;
