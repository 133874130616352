import successImg from './img/Medallions.png';
// import NewReleasesIcon from '@mui/icons-material/NewReleases';
import style from './messageDialog.module.css';
import BasePopup from '@/components/basePopup/BasePopup';
import Button from '@mui/material/Button';

type MessageDialogType = 'successful' | 'confirm';

function MessageDialog({
    type,
    title,
    message,
    handleConfirm,
    handleCancle,
    confirmBtnText = '確認',
    cancleBtnText = '取消',
    showCancleBtn = true
}: {
    type: MessageDialogType;
    title: string;
    message: string;
    handleConfirm: () => void;
    handleCancle?: () => void;
    confirmBtnText?: string;
    cancleBtnText?: string;
    showCancleBtn?: boolean;
}) {
    return (
        <>
            <BasePopup showClose={false} showTitle={false} width="340px">
                <>
                    <div className={style.flex}>
                        <div className={`${style.content}`}>
                            {type === 'successful' ? <img src={successImg} alt="" /> : null}
                            <div className={style.title}>{title}</div>
                            <div className={style.msg}>{message}</div>
                            <div className={style.btnGroup}>
                                {showCancleBtn && handleCancle && (
                                    <div>
                                        <Button
                                            className={style.cancelBtn}
                                            variant="outlined"
                                            onClick={() => {
                                                handleCancle();
                                            }}
                                        >
                                            {cancleBtnText}
                                        </Button>
                                    </div>
                                )}
                                {handleConfirm && (
                                    <div>
                                        <Button
                                            className={style.confirmBtn}
                                            variant="contained"
                                            onClick={() => {
                                                handleConfirm();
                                            }}
                                        >
                                            {confirmBtnText}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            </BasePopup>
        </>
    );
}

export default MessageDialog;
