import { useSelector, useDispatch } from 'react-redux';
import { setLogout } from '@/app/slice/userSlice';
import { RootState } from '@/app/store';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomLink from '@/components/customLink/CustomLink';
import { useState, MouseEvent, ChangeEvent } from 'react';
import { useRoutesMeta } from '@/hooks/routesMeta';
import { useLogoutSiteMutation } from '@/app/services/user';
import { setBasePopup, setMessage } from '@/app/slice/baseSlice';
import { useGetSearchGameListMutation } from '@/app/services/game-center';
import style from './header.module.css';
import Button from '@mui/material/Button';
import More from './img/more.svg';
import Search from '@/assets/img/search.svg';
import IconButton from '@mui/material/IconButton';
import UserDialog from '@/components/userDialog/UserDialog';
import WebHamburger from './img/webHamburger.svg';
import HeaderRightWeb from './HeaderRightWeb';
import HeaderRight from './HeaderRight';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchBar from '@/components/searchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import MobileHeader from './mobileHeader';
import { useRefreshBalanceMutation } from '@/app/services/user';
import { useGetCollectionAmountMutation } from '@/app/services/game-center';
import { setUserInfo } from '@/app/slice/userSlice';
import MessageDialog from '@/components/messageDialog/MessageDialog';
import { pictureImgDomain } from '@/utils/tool';
import { GameListProps, BigGameListProps } from '@/types/user';
import { getAuth, signOut } from 'firebase/auth';
type Popup =
    | ''
    | 'recentLogin'
    | 'realNameVerification'
    | 'modifyPassword'
    | 'bindDialog'
    | 'logoutDialog'
    | 'msgDialog';

function Tool() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [account, setAccount] = useState(false);
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const [popup, setPopup] = useState<Popup>('');
    const matches = useMediaQuery('(max-width:750px)');

    const [logoutSite, { isLoading }] = useLogoutSiteMutation();

    const openLogin = () => {
        dispatch(setBasePopup('login'));
    };

    const onClose = () => {
        setPopup('');
    };

    const handleLogout = async () => {
        if (isLoading) return;
        const result = await logoutSite({});
        if ('data' in result) {
            onClose();
            dispatch(setLogout());
            dispatch(setMessage({ type: 'success', message: t('header_logOut') }));
            navigate('/');
            const auth = getAuth();
            signOut(auth)
                .then(() => {
                    //这里写退出登录后的逻辑处理
                    console.log('auth：', auth);
                })
                .catch(error => {
                    console.log('error:', error);
                });
        }
    };

    const searchClick = () => {
        navigate('/gameSearch');
    };

    const AccountMenu = () => (
        <div className={`${matches ? style.accountMask : ''}`} onClick={() => setAccount(false)}>
            <ul className={style.userAccount} style={{ right: matches ? '10px' : '' }}>
                <li>
                    <CustomLink to="/personalCenter">
                        <img src={`${pictureImgDomain}web/orange/leftIcon1.svg`} />
                        <span>{t('header_account')}</span>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to="/vipRules">
                        <img src={`${pictureImgDomain}web/orange/leftIcon2.svg`} />
                        <span>VIP</span>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to="/news">
                        <img src={`${pictureImgDomain}web/orange/leftIcon4.svg`} />
                        <span>{t('news_news')}</span>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to="/betRecord">
                        <img src={`${pictureImgDomain}web/orange/leftIcon5.svg`} />
                        <span>{t('header_bettingHistory')}</span>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to="/tradeRecord">
                        <img src={`${pictureImgDomain}web/orange/leftIcon6.svg`} />
                        <span>{t('header_transactionHistory')}</span>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to="/accountSecurity">
                        <img src={`${pictureImgDomain}web/orange/leftIcon7.svg`} />
                        <span>{t('header_accountSecurity')}</span>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to="/helpCenter">
                        <img src={`${pictureImgDomain}web/orange/leftIcon3.svg`} />
                        <span>{t('header_helpCenter')}</span>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to="/serviceView">
                        <img src={`${pictureImgDomain}web/orange/leftIcon10.svg`} />
                        <span>{t('service_title')}</span>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to="/aboutUs">
                        <img src={`${pictureImgDomain}web/orange/leftIcon8.svg`} />
                        <span>{t('header_aboutUs')}</span>
                    </CustomLink>
                </li>
                <li className={style.logout} onClick={() => setPopup('logoutDialog')}>
                    <img src={`${pictureImgDomain}web/orange/leftIcon9.svg`} />
                    <span>{t('header_logout')}</span>
                </li>
            </ul>
            <div
                className={style.peopleBgCover}
                onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                    return false;
                }}
            ></div>
        </div>
    );

    return (
        <div className={style.tool}>
            {isLogin ? (
                <>
                    {matches ? (
                        <HeaderRight setAccount={setAccount} account={account} />
                    ) : (
                        <HeaderRightWeb setAccount={setAccount} account={account} />
                    )}
                </>
            ) : (
                <>
                    {matches && (
                        <div className={style.btnSearch}>
                            <img className={style.searchIcon} src={Search} alt="search" />
                            <input
                                className={style.search}
                                onFocus={searchClick}
                                placeholder={t('global_search')}
                                type="text"
                            />
                        </div>
                    )}
                    <Button variant="contained" className={style.btn} onClick={openLogin}>
                        {t('header_logIn')}
                    </Button>
                </>
            )}
            {account && <AccountMenu />}

            {account && !matches && (
                <div className={style.bgCover} onClick={() => setAccount(false)}></div>
            )}

            {popup == 'logoutDialog' && (
                <MessageDialog
                    type={'confirm'}
                    handleCancle={onClose}
                    handleConfirm={handleLogout}
                    title={t('accountSecurity_logout')}
                    message={t('accountSecurity_logout_confirm_logout')}
                    showCancleBtn={true}
                    confirmBtnText={t('accountSecurity_logout_confirm')}
                    cancleBtnText={t('global_cancel')}
                />
            )}
        </div>
    );
}

interface sideMenuProp {
    setIsOpen: (value: boolean) => void;
    setIsWebOpen: (value: boolean) => void;
    isOpen: boolean;
    isWebOpen: boolean;
}

function Header({ setIsOpen, isOpen, setIsWebOpen, isWebOpen }: sideMenuProp) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    const meta = useRoutesMeta();
    const navigate = useNavigate();
    const [gamesList, setGamesList] = useState<GameListProps[]>([]);
    const [bigGamesList, setBigGamesList] = useState<BigGameListProps[]>([]);
    const [searchText, setSearchText] = useState('');
    const matches = useMediaQuery('(max-width:750px)');
    const [getSearchGameList, { isLoading }] = useGetSearchGameListMutation();
    const [searchTimeoutId, setSearchTimeoutId] = useState<number | null>(null);
    const basePcLogo = useSelector((state: RootState) => state.base.siteInfo.configs.basePcLogo);
    const baseWebLogo = useSelector((state: RootState) => state.base.siteInfo.configs.baseWebLogo);
    const [getCollectionAmount] = useGetCollectionAmountMutation();
    const [mutateAsync] = useRefreshBalanceMutation();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const handleGoBack = async () => {
        const GameHistoryLength = localStorage.getItem('GameHistoryLength');

        if (GameHistoryLength) {
            const curHistoryLength = window.history.length;
            const step = +GameHistoryLength - curHistoryLength;
            navigate(step - 1);
        } else {
            navigate(-1);
        }

        const result = await getCollectionAmount({});
        if ('data' in result) {
            const balanceResult = await mutateAsync({});
            if ('data' in balanceResult) {
                const params = {
                    ...userInfo,
                    balance: balanceResult.data.balance
                };
                dispatch(setUserInfo(params));
            }
        }
    };

    const switchWebOpen = () => {
        setIsOpen(isWebOpen);
        setIsWebOpen(!isWebOpen);
    };

    const searchHot = () => {
        setIsActive(true);
        fetchData();
    };

    const _onBlur = () => {
        const timeoutId = setTimeout(() => {
            setIsActive(false);
            setGamesList([]);
            setBigGamesList([]);
        }, 200);
        return () => {
            clearTimeout(timeoutId);
        };
    };

    const searchGames = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        if (searchTimeoutId) clearTimeout(searchTimeoutId);

        const newTimeoutId = setTimeout(() => {
            fetchData(event.target.value);
        }, 500) as unknown as number;

        setSearchTimeoutId(newTimeoutId);

        return () => {
            if (searchTimeoutId) clearTimeout(searchTimeoutId);
        };
    };

    const fetchData = async (value?: string) => {
        const params = {
            msg: value || '',
            isBol: true
        };
        const result = await getSearchGameList(params);
        if ('data' in result) {
            if (searchText === '' && !value) {
                setGamesList(result.data.hotGameList);
            } else {
                if ('gameList' in result.data) {
                    const array = [...result.data.gameList];

                    const allChildGames = array.map(item => item.games.childGame);

                    const combinedChildGames = [].concat(...allChildGames);

                    const newObject = {
                        gameType: 'all',
                        games: {
                            childGame: combinedChildGames
                        },
                        name: t('home_allGame')
                    };

                    array.unshift(newObject);
                    setBigGamesList(array);
                } else {
                    setBigGamesList([]);
                }
            }
        }
    };

    const h5OpenClick = () => {
        setIsOpen(!isOpen);
        // document.body.classList.add('body-no-scroll');
    };

    return (
        <>
            {!meta.headerChildren && meta.isBack && matches && (
                <div className={`${style.headerBar}`}>
                    <div
                        className={`${style.containerFull} ${
                            location.pathname === '/' ? style.containerFullHome : ''
                        }`}
                    />
                    {location.pathname === '/deposit' ? (
                        <MobileHeader>
                            <span
                                className={style.linkBtn}
                                onClick={() => navigate('/tradeRecord')}
                            >
                                <img src={`${pictureImgDomain}web/orange/leftIcon5.svg`} />
                                {t('header_transactionHistory')}
                            </span>
                        </MobileHeader>
                    ) : (
                        <MobileHeader />
                    )}
                </div>
            )}

            {(!meta.isBack || !matches) && !meta.noNeedHeader && (
                <>
                    <header className={`${style.container} ${style.mobileContainer}`}>
                        <div
                            className={`${style.containerFull} ${
                                location.pathname === '/' ? style.containerFullHome : ''
                            }`}
                        />
                        <div className={style.containerWrap}>
                            {location.pathname === '/gameInfo' ? (
                                <div className={style.mobileMenu}>
                                    <img
                                        className={style.backBtn}
                                        src={`${pictureImgDomain}web/orange/leftReturn.svg`}
                                        onClick={() => handleGoBack()}
                                    />
                                </div>
                            ) : (
                                <div className={style.mobileMenu}>
                                    <IconButton
                                        className={style.mobileMenuBtn}
                                        sx={{ color: 'white' }}
                                        onClick={h5OpenClick}
                                    >
                                        <img className={style.logoLeftMore} src={More} />
                                    </IconButton>
                                    <CustomLink to={'/'} className={style.logoBox}>
                                        <img className={style.logo} src={baseWebLogo} />
                                    </CustomLink>
                                </div>
                            )}
                            <div className={style.webMenu}>
                                <CustomLink to={'/'}>
                                    <img
                                        className={style.webLogo}
                                        src={basePcLogo}
                                        alt="basePcLogo"
                                    />
                                </CustomLink>
                                <img
                                    src={WebHamburger}
                                    onClick={switchWebOpen}
                                    alt="WebHamburger"
                                    className={style.WebHamburger}
                                />
                            </div>
                            <div className={style.headerMiddleWrapper}>
                                {!matches && (
                                    <SearchBar
                                        isActive={isActive}
                                        searchText={searchText}
                                        gamesList={gamesList}
                                        isLoading={isLoading}
                                        onBlur={_onBlur}
                                        bigGamesList={bigGamesList}
                                        searchGames={searchGames}
                                        setIsActive={setIsActive}
                                        searchHot={searchHot}
                                        setSearchText={setSearchText}
                                    />
                                )}

                                <Tool />
                            </div>
                        </div>
                    </header>
                </>
            )}

            <UserDialog />
        </>
    );
}

export default Header;
