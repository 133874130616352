import style from './SendPhoneMessage.module.css';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import Safety from './img/safety.svg';
import { setMessage } from '@/app/slice/baseSlice';
import { useTranslation } from 'react-i18next';
import { usePhoneCaptcha } from '@/hooks/usePhoneCaptcha';
import CircularProgress from '@mui/material/CircularProgress';

type FormType = {
    messageCode: string;
    bindTarget: string;
};

function SendPhoneMessage(props: {
    areaNumber: string;
    getPhoneNumber: () => string;
    sendType: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCode: any;
}) {
    const { t } = useTranslation();
    const { areaNumber, getPhoneNumber, sendType, setCode } = props;
    const dispatch = useDispatch();
    const defaultCurrency =
        useSelector((state: RootState) => state.base.siteInfo.defaultCurrency) || '';
    const { fetchData, seconds, isLoading } = usePhoneCaptcha();

    const {
        register,
        formState: { errors }
    } = useForm<FormType>();

    const handleMessageCode = async () => {
        const phoneNumber = getPhoneNumber();
        const phoneRegex =
            /^(1[1-9]|2[1-9]|3[1-5]|4[1-9]|5[1-5]|6[1-9]|7[1-9]|8[1-9]|9[1-9])9\d{8}$/; //手机号正则

        if (!phoneRegex.test(phoneNumber))
            return dispatch(
                setMessage({
                    type: 'error',
                    message: t('login_phoneRequired')
                })
            );
        fetchData({
            areaNumber: areaNumber,
            phoneNumber: phoneNumber,
            sendType: sendType, //0-手机注册 1-手机登录 2-忘记密码 3-绑定手机
            currency: defaultCurrency
        });
    };

    const emitValue = (event: { target: { value: string } }) => {
        const { value } = event.target;
        setCode(value);
    };

    return (
        <>
            <div className={style.inputBar}>
                <img src={Safety} className={style.inputIcon} alt="Safety" />
                <input
                    {...register('messageCode', {
                        required: 'required'
                    })}
                    className={`${style.inputCode} ${errors.messageCode ? 'error' : ''}`}
                    placeholder={t('global_enterCaptcha')}
                    onChange={emitValue}
                />
                <Button
                    className={style.getMsgCodeBtn}
                    variant="contained"
                    onClick={handleMessageCode}
                >
                    {seconds > 0
                        ? `${t('global_readquirir')} (${seconds}s)`
                        : t('global_getCaptcha')}
                    {isLoading && (
                        <CircularProgress
                            size={16}
                            style={{
                                color: '#fff',
                                marginLeft: '8px'
                            }}
                        />
                    )}
                </Button>
            </div>
        </>
    );
}

export default SendPhoneMessage;
