import { useState, useEffect } from 'react';
import { routes } from '@/router/router';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';

type MenuStyle = 'regular' | 'backCurrency' | 'hamburger' | 'backHelp' | 'redEnvelope' | 'back';
interface Meta {
    [propsName: string]: {
        noNeedHeader?: boolean;
        name?: string;
        menu?: MenuStyle;
        noNeedFooter?: boolean;
        title?: string;
        isBack?: boolean;
        headerChildren?: boolean;
    };
}

const initialMeta = () => {
    const metaTable: Meta = {};
    for (const route of routes) {
        const path = route.path === '/' ? '' : route.path;
        for (const meta of route.children) {
            if (!meta.meta) continue;
            metaTable[path + meta.path] = meta.meta as unknown as Meta;
        }
    }
    return metaTable;
};

export function useRoutesMeta() {
    const customTitle = useSelector((state: RootState) => state.base.mobileTitle);
    const [meta, setMeta] = useState(initialMeta);
    const { pathname } = useLocation();

    useEffect(() => {
        let defaultTitle = '';
        const routeMeta = meta[pathname];
        if (routeMeta && routeMeta.title) {
            defaultTitle = routeMeta.title;
        }

        if (customTitle) {
            defaultTitle = customTitle;
        }

        setMeta(prevMeta => ({
            ...prevMeta,
            [pathname]: { ...prevMeta[pathname], title: defaultTitle }
        }));
    }, [pathname, customTitle]);

    return meta[pathname];
}
