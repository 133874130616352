import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import { useRoutesMeta } from '@/hooks/routesMeta';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/store';
import Header from './header/Header';
import Footer from './footer/Footer';
import Message from '@/feature/message/BaseMessage';
import { useEffect, useLayoutEffect, useState } from 'react';
import SideMenu from './SideMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import style from './baseLayout.module.css';
import NavFooter from '@/components/navFooter/NavFooter';
import { usePwaBindMutation, useRefreshBalanceMutation } from '@/app/services/user';
import { setUserInfo } from '@/app/slice/userSlice';
import InstallClose from './img/installClose.svg';
import InstallShare from './img/installShare.svg';
import NotifyClose from './img/notifyClose.svg';
import GuideStepOne from './img/guide_step_one.svg';
import GuideStepTwo from './img/guide_step_two.svg';
import GuideStepOneWrap from './img/guide_step_one_wrap.png';
import GuideStepTwoWrap from './img/guide_step_two_wrap.png';
import Protect from './img/protect.svg';
import { isPWA, getMobileOperatingSystem, pictureImgDomain, getQueryVariable } from '@/utils/tool';
import {
    setInviteCode,
    setChannelId,
    setBasePopup,
    setLoginDialogType,
    setGlobalDict,
    setGlobalActivityList,
    setPopShowInstallApp,
    setMessage
} from '@/app/slice/baseSlice';
import { useGetDictMutation } from '@/app/services/base';
import emitter from '@/utils/events';
let userInfoRefreshTimer: number | null = null;
import { useGlobalActivityListMutation } from '@/app/services/activity';
import useWebSocket from '@/hooks/useWebSocket';

const NewHeaderMenu = lazy(() => import('../layout/header/NewHeaderMenu'));
const NewHeaderWebMenu = lazy(() => import('../layout/header/NewHeaderWebMenu'));
const HomePop = lazy(() => import('@/pages/home/components/HomePop'));
const MoneyRain = lazy(() => import('@/pages/RedBagRain/components/MoneyRain'));
const TurnTablePop = lazy(() => import('@/pages/turnTable/components/TurnTablePop'));
const RedBagRain = lazy(() => import('@/pages/RedBagRain/index'));
const RedFloat = lazy(() => import('@/pages/RedBagRain/components/RainFloat'));

function BaseLayout() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const meta = useRoutesMeta();
    const [isOpen, setIsOpen] = useState(false);
    const [isWebOpen, setIsWebOpen] = useState(false);
    const matches = useMediaQuery('(max-width:1280px)');
    const showSliderMatches = useMediaQuery('(min-width:1024px)');
    const matchesTab = useMediaQuery('(max-width:750px)');
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const token = useSelector((state: RootState) => state.user.token);
    const siteCode = useSelector((state: RootState) => state.base.siteInfo.siteCode);
    const currentLanguage = useSelector((state: RootState) => state.base.language.current);
    const defaultCurrency = useSelector((state: RootState) => state.base.siteInfo.defaultCurrency);
    const hostname = siteCode == 'jeroa' ? 'tg666.mltg888999.com' : window.location.hostname;
    const ws = useWebSocket(
        `wss://${hostname}/ws/member/websocket?token=${token}&currency=${defaultCurrency}&siteCode=${siteCode}&lang=${currentLanguage}`,
        isLogin
    ); // socket
    const [mutateAsync] = useRefreshBalanceMutation();
    const [pwaBindAsync] = usePwaBindMutation();
    const [activityListMutateAsync] = useGlobalActivityListMutation();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const baseTitle = useSelector((state: RootState) => state.base.siteInfo.configs.baseTitle);
    const [curRegistration, setCurRegistration] = useState<ServiceWorkerRegistration | null>(null);
    const baseFaviconico = useSelector(
        (state: RootState) => state.base.siteInfo.configs.baseFaviconico
    );
    const [getDict] = useGetDictMutation();
    const [iosGuideShow, setIosGuideShow] = useState(false);
    const [animateOut, setAnimateOut] = useState(true);
    const [messageNotificationShow, setMessageNotificationShow] = useState(false);
    const [versionUpdateShow, setVersionUpdateShow] = useState(false);
    const [orientationWarning, setOrientationWarning] = useState(false);
    const moneyRainShow = useSelector((state: RootState) => state.socketMessageSlice.moneyRainShow);
    const popShowInstallApp = useSelector((state: RootState) => state.base.popShowInstallApp);
    const popShowHomePop = useSelector((state: RootState) => state.base.popShowHomePop);
    const [redbagShow, setRedBagShow] = useState(false);
    const [rainfloatShow, setRainFloatShow] = useState(false);
    const [androidInstallProcess, setAndroidInstallProcess] = useState(0);
    const [processSuccess, setProcessSuccess] = useState(false);
    const popShowRouletteBooster = useSelector(
        (state: RootState) => state.base.popShowRouletteBooster
    );
    const activityInfo = useSelector((state: RootState) =>
        state.base.globalActivityList?.find(x => x.exclusiveActivityType == 7)
    );

    const rainActivityInfo = useSelector((state: RootState) =>
        state.base.globalActivityList?.find(x => x.exclusiveActivityType == 2)
    );

    const rechargeSuccessMsg = useSelector(
        (state: RootState) => state.socketMessageSlice.rechargeSuccessMsg
    );

    const handleGlobalActivityList = (event: unknown) => {
        if (typeof event === 'boolean') {
            getGlobalActivityList(event);
        }
    };

    const getGlobalActivityList = async (currentLogin: boolean) => {
        const params = currentLogin ? {} : { currency: defaultCurrency };
        try {
            const result = await activityListMutateAsync(params);
            if ('data' in result) {
                dispatch(setGlobalActivityList(result.data));
                return result.data;
            } else {
                console.log(result.error);
                return false;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getUserBalance = async () => {
        const result = await mutateAsync({});
        if ('data' in result) {
            const params = {
                ...userInfo,
                balance: result.data.balance
            };
            dispatch(setUserInfo(params));
        }
    };

    const _startRefreshUserBalance = () => {
        if (userInfoRefreshTimer) {
            clearInterval(userInfoRefreshTimer);
        }

        userInfoRefreshTimer = window.setInterval(async () => {
            getUserBalance();
        }, 15000);
    };

    const _stopRefreshUserBalance = () => {
        if (userInfoRefreshTimer) {
            clearInterval(userInfoRefreshTimer);
            userInfoRefreshTimer = null;
        }
    };

    const fetchFavicon = () => {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/svg+xml';
        link.href = baseFaviconico;
        document.head.appendChild(link);
    };

    const updateGlobalDictHandler = async () => {
        const result = await getDict({});
        if ('data' in result) {
            dispatch(setGlobalDict(result.data));
        } else {
            console.log(result.error);
        }
    };

    const IosOrAndroidInstall = () => {
        dispatch(setPopShowInstallApp(false));
        const os = getMobileOperatingSystem();
        if (os === 'iOS') {
            setAnimateOut(false);
            const timer = setTimeout(() => {
                clearTimeout(timer);
                setAnimateOut(true);
                setIosGuideShow(true);
            }, 100);
        } else {
            if (window.deferredPrompt) {
                window.deferredPrompt.prompt();
                window.deferredPrompt.userChoice.then(choiceResult => {
                    if (choiceResult.outcome === 'accepted') {
                        androidInstallProcessFn();
                        console.log('User accepted the install prompt');
                        localStorage.setItem('kayaPwaCloseNum', '10001');
                    } else {
                        console.log('User dismissed the install prompt');
                    }
                    window.deferredPrompt = null;
                });
            } else {
                console.log('The install prompt is not available');
            }
        }
    };

    const checkSubscription = async (registration: ServiceWorkerRegistration) => {
        if (registration) {
            try {
                const existingSubscription = await registration.pushManager.getSubscription();

                switch (Notification.permission) {
                    case 'denied':
                        throw new Error('推送消息被禁用.');
                    case 'granted':
                        console.log('granted');
                        if (!existingSubscription) setMessageNotificationShow(true);
                        break;
                    default:
                        console.log('default');
                        if (!existingSubscription) setMessageNotificationShow(true);
                        break;
                }
            } catch (error) {
                console.error('Error checking subscription:', error);
            }
        }
    };

    const checkHaveRegistration = async () => {
        const isRegistered = (await navigator.serviceWorker?.getRegistration()) !== null;
        if (isRegistered) getNotifyRegisteredInfo();
    };

    const urlBase64ToUint8Array = (base64String: string) => {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    const getNotifyRegisteredInfo = () => {
        navigator.serviceWorker?.ready.then(registration => {
            setCurRegistration(registration);
            checkSubscription(registration);
        });
    };

    const handleSubscription = async () => {
        if (curRegistration) {
            setMessageNotificationShow(false);
            try {
                const convertedVapidKey = urlBase64ToUint8Array(
                    'BAjy3t8vsObJR3z_EBhjEkRmNJj5qkOUvDIPpRvoX1-UNFehhbFw3U9m2OZNxX-XXos5Iy96eyhUyUnRwnVhLkw'
                );
                const subscribeOption = {
                    userVisibleOnly: true,
                    applicationServerKey: convertedVapidKey
                };

                const subscription = await curRegistration.pushManager.subscribe(subscribeOption);
                const endpointObject = {
                    endpoint: subscription.endpoint,
                    expirationTime: subscription.expirationTime,
                    keys: {
                        p256dh: subscription.toJSON()?.keys?.p256dh || '',
                        auth: subscription.toJSON()?.keys?.auth || ''
                    }
                };
                console.log(JSON.stringify(subscription));

                const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
                if (isIOS) {
                    const timer = setTimeout(() => {
                        clearTimeout(timer);
                        pwaBindAsync(endpointObject);
                    }, 5000);
                } else {
                    pwaBindAsync(endpointObject);
                }
            } catch (error) {
                console.log('Service Worker subscribe failed:', error);
            }
        }
    };

    const onPwaVersionUpdateFn = () => {
        setVersionUpdateShow(false);
        navigator.serviceWorker.ready.then(registration => {
            const newWorker = registration.waiting || registration.installing;
            if (newWorker) {
                newWorker.postMessage({ action: 'skipWaiting' });

                navigator.serviceWorker.addEventListener('controllerchange', () => {
                    console.log('New Service Worker activated, reloading page.');
                    window.location.reload();
                });
            }
        });
    };

    const h5OpenClose = () => {
        setIsOpen(false);
        // document.body.classList.remove('body-no-scroll');
    };

    // 安卓快速安装
    const androidInstallProcessFn = () => {
        const intervalTime = 8000 / 100; // 4s内从0到100，间隔40ms
        let progress = 0;

        const interval = setInterval(() => {
            progress += 1;
            setAndroidInstallProcess(progress);

            if (progress >= 100) {
                setProcessSuccess(true);
                clearInterval(interval); // 进度到100时停止
            }
        }, intervalTime);

        return () => {
            clearInterval(interval); // 组件卸载时清除interval
        };
    };

    // 关闭安卓快速安装
    const closeAndroidInstall = () => {
        if (processSuccess) setAndroidInstallProcess(0);
    };

    // 打开安卓app
    const handleAndroidOpen = () => {
        const intentUrl = `intent://${window.location.host}#Intent;scheme=https;package=com.android.chrome;end`;
        window.open(intentUrl, '_blank');
    };

    useEffect(() => {
        const handleOrientationChange = () => {
            if (window.orientation === 90 || window.orientation === -90) {
                setOrientationWarning(true);
            } else {
                setOrientationWarning(false);
            }
        };
        if (matches && matchesTab) {
            setIsOpen(false);
            setIsWebOpen(false);
        } else if (matches && !matchesTab) {
            setIsOpen(false);
            setIsWebOpen(true);
        } else {
            setIsOpen(true);
            setIsWebOpen(false);
        }

        window.addEventListener('orientationchange', handleOrientationChange);
        handleOrientationChange(); // Initial check

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, [matches, matchesTab]);

    useLayoutEffect(() => {
        emitter.on('_getGlobalActivityList', handleGlobalActivityList);
        emitter.on('_updateGlobalDict', updateGlobalDictHandler);
        emitter.on('serviceWorkerReady', getNotifyRegisteredInfo);
        emitter.on('pwaVersionShow', () => {
            setVersionUpdateShow(true);
        });

        return () => {
            emitter.off('_getGlobalActivityList', handleGlobalActivityList);
            emitter.off('_updateGlobalDict', updateGlobalDictHandler);
            emitter.off('serviceWorkerReady', getNotifyRegisteredInfo);
            emitter.off('pwaVersionShow', () => {
                setVersionUpdateShow(false);
            });
        };
    }, []);

    useEffect(() => {
        fetchFavicon();
        checkHaveRegistration();
        const Url = window.location.search;
        if (Url.indexOf('inviteCode=') > -1) {
            dispatch(setInviteCode(getQueryVariable('inviteCode')));
            dispatch(setBasePopup('login'));
            dispatch(setLoginDialogType('register'));
        }
        if (Url.indexOf('cid=') > -1) {
            dispatch(setChannelId(getQueryVariable('cid')));
        }
    }, []);

    useEffect(() => {
        useMediaQuery;
        if (isLogin) {
            getUserBalance();
            _startRefreshUserBalance();
            const lastLang = localStorage.getItem('lastLang') || '';
            const lang = localStorage.getItem('lang') || '';
            const currentDict = localStorage.getItem('globalSiteDict');
            if (currentDict === null || lastLang !== lang) {
                emitter.emit('_updateGlobalDict');
                emitter.emit('_getGlobalActivityList', isLogin);
                localStorage.setItem('lastLang', lang);
            }
            // 如果在其他页面 没有转盘这个活动信息 主动调用一次获取 因为底部转盘 有个按钮点击事件
            if (!activityInfo && location.pathname !== '/')
                emitter.emit('_getGlobalActivityList', isLogin);
        } else {
            _stopRefreshUserBalance();
            ws?.close(); //用户退出关闭socket
        }

        return () => {
            _stopRefreshUserBalance(); // 确保在卸载时清理定时器
        };
    }, [isLogin]);

    useEffect(() => {
        if (rainActivityInfo) {
            setRainFloatShow(true);
        }
    }, [rainActivityInfo]);

    useEffect(() => {
        if (rechargeSuccessMsg) {
            dispatch(setMessage({ type: 'success', message: rechargeSuccessMsg }));
        }
    }, [rechargeSuccessMsg]);

    return (
        <div className={location.pathname === '/' ? 'homeLayout' : ''}>
            <Helmet titleTemplate={baseTitle}>
                <meta charSet="utf-8" />
                <title>{baseTitle}</title>
            </Helmet>
            <Header
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                setIsWebOpen={setIsWebOpen}
                isWebOpen={isWebOpen}
            />
            <div
                className={`${style.layoutDistributed} ${
                    !meta.isBack ||
                    location.pathname === '/accountSecurity' ||
                    location.pathname === '/inviteFriends' ||
                    location.pathname === '/inviteFriendsHistory'
                        ? style.layoutDistributedFull
                        : ''
                } ${
                    !meta.noNeedFooter && matchesTab
                        ? style.isFooterPadding
                        : style.notFooterPadding
                } ${
                    location.pathname === '/helpCenter' ||
                    location.pathname === '/registerSuccessful'
                        ? style.layoutNoNeedHeader
                        : ''
                }`}
            >
                {isOpen && matches && <div className={'coverBg'} onClick={h5OpenClose}></div>}

                {matchesTab ? (
                    <Suspense fallback={null}>
                        <NewHeaderMenu isOpen={isOpen} setIsOpen={setIsOpen} />
                    </Suspense>
                ) : (
                    <div style={{ width: isOpen ? '260px' : '80px' }}>
                        <Suspense fallback={null}>
                            <NewHeaderMenu isOpen={isOpen} setIsOpen={setIsOpen} />
                        </Suspense>
                        <Suspense fallback={null}>
                            <NewHeaderWebMenu isOpen={isWebOpen} setIsOpen={setIsOpen} />
                        </Suspense>
                    </div>
                )}

                <div
                    className={`${'container'} ${!isOpen ? 'transLayout' : 'transWebLayout'}`}
                    style={
                        matches && showSliderMatches
                            ? { maxWidth: isOpen ? 'calc(100% - 260px)' : 'calc(100% - 80px)' }
                            : {}
                    }
                >
                    <main>
                        <Outlet />
                    </main>

                    {location.pathname !== '/downloadApp' &&
                        location.pathname !== '/registerSuccessful' && (
                            <div className={style.footerWrap}>
                                <Footer />
                            </div>
                        )}

                    {matchesTab && !meta.noNeedFooter && <NavFooter />}
                </div>
            </div>
            <Message />
            <SideMenu />

            {/* 横屏提示 */}
            {orientationWarning && (
                <div className={style.orientationWarning}>
                    <p>{t('global_continue_using')}</p>
                </div>
            )}

            {/* 引导安装pwa */}
            {!isPWA && matchesTab && popShowInstallApp && (
                <>
                    <div className={style.installAppMask}></div>
                    <div
                        className={`${style.installApp} ${
                            popShowInstallApp ? style.slideUp : style.slideDown
                        }`}
                    >
                        <div className={style.installAppItem}>
                            <img src={`${pictureImgDomain}web/orange/120.png`} alt="" />
                            <span>{t('global_install_info')}</span>
                        </div>
                        <div className={style.installAppBtn}>
                            <Button
                                variant="contained"
                                className={style.installCancelBtn}
                                onClick={() => {
                                    setAnimateOut(false);
                                    dispatch(setPopShowInstallApp(false));
                                }}
                            >
                                <span>{t('global_cancel')}</span>
                            </Button>
                            <Button
                                variant="contained"
                                className={style.installBtn}
                                onClick={IosOrAndroidInstall}
                            >
                                <span>{t('global_install')}</span>
                            </Button>
                        </div>
                    </div>
                </>
            )}
            {/* ios引导安装弹框 */}
            {!isPWA && matchesTab && iosGuideShow && (
                <>
                    <div className={style.installAppMask}></div>
                    <div
                        className={`${style.installApp} ${style.iosGuideStyle} ${
                            animateOut ? style.slideUp : style.slideDown
                        }`}
                    >
                        <div className={style.iosGuideStyleTitle}>
                            <span>{t('global_install_application')}</span>
                            <img
                                src={InstallClose}
                                alt=""
                                onClick={() => {
                                    setAnimateOut(false);
                                    setIosGuideShow(false);
                                }}
                            />
                        </div>
                        <div className={style.iosGuideIconWrap}>
                            <img src={GuideStepOne} className={style.guideStepImg} />
                            <p className={style.iosGuideIconInfo}>
                                <span>{t('global_tap')}</span>
                                <img src={InstallShare} className={style.iosGuideIconBig} />
                                <span>{t('global_browser_bar')}</span>
                            </p>
                            <img src={GuideStepOneWrap} className={style.guideStepWrapImg} />
                            <img src={GuideStepTwo} className={style.guideStepImg} />
                            <p className={style.iosGuideIconInfo}>
                                <span>{t('global_select_add_screen_one')}</span>
                                <span className={style.iosGuideInfoBorder}>
                                    {t('global_select_add_screen_two')}
                                </span>
                            </p>
                            <img src={GuideStepTwoWrap} className={style.guideStepWrapImg} />
                            <p className={style.iosGuideIconInfo}>
                                <span>{t('global_on_home_screen')}</span>
                                <img
                                    src={`${pictureImgDomain}web/orange/120.png`}
                                    className={style.iosGuideIconSmall}
                                />
                                <span>{t('global_scroll_select')}</span>
                            </p>
                        </div>
                    </div>
                </>
            )}

            {/* 安卓引导安装弹框 */}
            {matchesTab && androidInstallProcess > 0 && (
                <>
                    <div className={style.installAppMask} onClick={closeAndroidInstall}></div>
                    <div className={style.androidInstall}>
                        <div className={style.androidInstallWrap}>
                            <span className={style.androidInstallTitle}>
                                {t('global_quickly_install')}
                            </span>
                            <span className={style.androidInstallSpeed}>
                                {t('global_times_faster')}
                            </span>

                            <div className={style.progress}>
                                <span>{androidInstallProcess}%</span>
                                <span className={style.mask}></span>
                                <span
                                    className={style.progressBar}
                                    style={{
                                        width: `${androidInstallProcess}%`
                                    }}
                                ></span>
                            </div>

                            {processSuccess && (
                                <>
                                    <div className={style.installSuccess}>
                                        <img
                                            className={style.installSuccessImg}
                                            src={Protect}
                                            alt="Protect"
                                        />
                                        <span>Ativado</span>
                                    </div>

                                    <Button
                                        variant="contained"
                                        className={style.installSuccessInfo}
                                        onClick={handleAndroidOpen}
                                    >
                                        <span>{t('global_app_open')}</span>
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}

            {/* 引导消息通知弹框 */}
            {isPWA && matchesTab && messageNotificationShow && (
                <>
                    <div className={style.installAppMask}></div>
                    <div className={style.appMessageNotification}>
                        <div className={style.appMessageNotificationItem}>
                            <img src={`${pictureImgDomain}web/orange/120.png`} alt="" />
                            <span>{t('global_update_notifications')}</span>
                            <img
                                src={NotifyClose}
                                className={style.notifyCloseImg}
                                onClick={() => {
                                    setMessageNotificationShow(false);
                                }}
                            />
                        </div>
                        <Button
                            variant="contained"
                            className={style.notificationItemBtn}
                            onClick={handleSubscription}
                        >
                            <span>{t('global_receive_notifications')}</span>
                        </Button>
                    </div>
                </>
            )}
            {/* pwa版本有更新时提示弹框 */}
            {isPWA && matchesTab && versionUpdateShow && (
                <>
                    <div className={style.installAppMask}></div>
                    <div className={`${style.installApp} ${style.slideUp}`}>
                        <div className={style.installAppItem}>
                            <img src={`${pictureImgDomain}web/orange/120.png`} alt="" />
                            <span>{t('global_update_now')}</span>
                        </div>
                        <div className={style.installAppBtn}>
                            {/* <Button
                                variant="contained"
                                className={style.installCancelBtn}
                                onClick={onPwaVersionUpdateFn}
                            >
                                <span>{t('global_version_later')}</span>
                            </Button> */}
                            <Button
                                variant="contained"
                                className={style.installBtn}
                                onClick={onPwaVersionUpdateFn}
                            >
                                <span>{t('global_version_renew')}</span>
                            </Button>
                        </div>
                    </div>
                </>
            )}

            {/* 用户强制更新pwa版本 */}
            {/* {isPWA && (
                <>
                    <div className={style.installAppMask}></div>
                    <div className={`${style.installApp} ${style.forceUpdateWrap}`}>
                        <div className={style.forceUpdateTitle}>
                            <span className={style.forceUpdateTitleSpan}>
                                {t('global_discover_new_version')}
                            </span>
                        </div>
                        <div className={style.forceUpdateContent}>
                            <p>
                                Baixar o aplicativo para o seu desktop lhe dará um bônus extra de
                                <span className={style.updateBonusMoney}>R$2.00</span>
                            </p>
                            <img
                                src={`${pictureImgDomain}web/orange/install_app.png`}
                                className={style.updateInstallImg}
                            />
                            <div className={style.forceUpdateBtn}>
                                <div className={style.forceUpdateBtnOne}>
                                    <img
                                        src={`${pictureImgDomain}web/orange/force_update_upload.png`}
                                        className={style.forceUpdateUpload}
                                    />
                                    <span>Actualizar ahora</span>
                                </div>
                                <img
                                    src={`${pictureImgDomain}web/orange/force_update_service.png`}
                                    className={style.forceUpdateBtnTwo}
                                />
                            </div>
                            <div className={style.forceUpdateInfo}>
                                <p>Instalação de um aplicativo Android:</p>
                                <p>1. Clique no botão acima para baixar o arquivo de instalação.</p>
                                <p>2. Para Android.</p>
                                <p>
                                    3. Permita a instalação de aplicativos de fontes desconhecidas
                                    nas configurações do seu dispositivo.
                                </p>
                                <p>4. Conclua a instalação.</p>
                            </div>

                            <div className={style.forceUpdateLastInfo}>
                                <div className={style.forceUpdateLastInfoTitle}>
                                    <img
                                        src={`${pictureImgDomain}web/orange/force_update_gan.png`}
                                        className={style.LastInfoTitleImg}
                                    />
                                    <span>Aviso Amigável:</span>
                                </div>
                                <p>
                                    Este aplicativo passou na certificação de segurança do Google
                                    Play, portanto, fique à vontade para instalá-lo.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )} */}
            {/*  红包雨 */}
            {moneyRainShow && (
                <Suspense fallback={null}>
                    <MoneyRain />
                </Suspense>
            )}
            {/*  轮盘助力 */}
            {popShowRouletteBooster && (
                <Suspense fallback={null}>
                    <TurnTablePop />
                </Suspense>
            )}
            {/*  弹窗公告 */}
            {popShowHomePop && (
                <Suspense fallback={null}>
                    <HomePop />
                </Suspense>
            )}
            {/* 红包雨信息 */}
            {redbagShow && (
                <Suspense fallback={null}>
                    <RedBagRain isShow={redbagShow} close={() => setRedBagShow(false)} />
                </Suspense>
            )}
            {/* 红包雨侧边触发栏 */}
            {rainfloatShow && location.pathname === '/' && (
                <Suspense fallback={null}>
                    <RedFloat openRainActivity={() => setRedBagShow(true)} />
                </Suspense>
            )}
        </div>
    );
}

export default BaseLayout;
